import { Component, OnInit } from '@angular/core';

import { PreferencesModel } from 'src/app/models/preferences.model';
import { User } from '../interfaces/userNavbar.interface';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { MainMenuDataManager } from '../model/main-menu.mode';
import { PreferencesService } from '../../services/preferences.service';
import { UsersService } from '../../../services/users.service';
import { MaintenanceServicesService } from '../../../services/maintenance-services.service';
import { TranslateService } from '@ngx-translate/core';
import { AwsImage } from '../../../utils/awsImages.utils';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  loading: boolean = false;
  ls = LocalStorageManager;

  user!: User;
  type_subscription? : number;

  page: string |  null = null;
  userImage: any;
  menu = new MainMenuDataManager();

  maintenance: boolean = true;

  showMessage: boolean = true;

  image_request: boolean = false;

  urlImage : string | null = null;


  constructor(private userServies:UsersService,
    private preferencesServices:PreferencesService,
    private maintenanceServices: MaintenanceServicesService, 
    private translate: TranslateService) {
      let lang = LocalStorageManager.lang;
      this.translate.use(lang!);
    }

  ngOnInit(): void {
    this.loading = true;
    this.maintenance = false;
    this.maintenanceServices.getMaintenance().then(_ => {
      this.userServies.getCurrentUser().then((user:any) => {
        this.user = user;
        this.type_subscription = +sessionStorage.getItem('type_subscription')!;
        this.userImage =  new AwsImage().getImage(this.user!.photo_name, this.user!.email);
        this.preferencesServices.getPreferences(this.userServies.preferences[0], this.userServies.currencies);
        LocalStorageManager.setDarkmode(PreferencesModel.shared.theme == 1);
        this.getImage();
        this.loading = false;
      });
    }, reject => {
      console.log(reject);
      this.maintenance = true;
    });
  }

  ngOnchanges() {
  }

  getImage() {
    let imageUrl : string = this.userImage;
    if (imageUrl != null && imageUrl?.length > 0) {
      var request = new XMLHttpRequest();
      request.open("GET", imageUrl, true);
      request.send();
      request.onload = () => {
        this.image_request = true;
        if (request.status == 200) {
          this.urlImage = imageUrl;
        } else {
          this.urlImage = "assets/images/icon-user.png";
        }
      }  
    }
  }

  changePage(text:string) {
    this.page = text;
  }

  logout(){
    sessionStorage.setItem('logged', '0');
    sessionStorage.removeItem('user_data');
    LocalStorageManager.removeAll();
    window.location.href = '/Login';
  }

  setShow(change: boolean){
    this.showMessage = change;
  }

  hasPhotoNameUser () : Boolean {
    return this.user.photo_name != null && this.user.photo_name.length > 0
  }
}
