import { Component, OnInit } from '@angular/core';
import { MonthlyBalanceModel } from 'src/app/components/cards/card-balance/models/monthlyBalance.models';
import { FortnightModel } from '../../../../models/biweekly.model';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { PeriodData, PeriodType } from '../../../common-components/selector-period/interfaces/period.interface';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { ModelMovements } from '../../../../pages/movements/model/movements.model';
import * as moment from 'moment';
import { WeekModel } from '../../../../models/week.model';
import { TranslateService } from '@ngx-translate/core';
import { CalendarModel } from '../../../../models/calendar.model';
import { Router } from '@angular/router';
import { SyncAll } from '../../../../services/sync-all.service';

@Component({
  selector: 'app-period-balance',
  templateUrl: './period-balance.component.html',
  styleUrls: ['./period-balance.component.css']
})
export class PeriodBalanceComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;
  editingViews: boolean = false;

  preferences = PreferencesModel.shared;

  movements: ModelMovements[] = [];

  monthlyBalance!: MonthlyBalanceModel;
  date: moment.Moment;
  date2: moment.Moment;

  period: PeriodData;
  selectedWeek: WeekModel;
  fortnight: FortnightModel;

  selectedAccounts: ModelAccounts[] = [];

  selectedPeriodtext: string = "";

  colorScheme = {
    domain: ['#0EB59499', '#D1359499', '#42424299']
  };

  total: number = 0;

  constructor(private services: SyncAll, private translate: TranslateService, private route: Router) {
    let lang = LocalStorageManager.lang!;
    this.translate.use(lang);
    this.movements = this.services.movements;
    this.date = LocalStorageManager.home.getDate();
    this.period = this.services.currentPeriod;
    let calendar = new CalendarModel(this.date);
    this.selectedWeek = calendar.currentWeek;
    this.fortnight = calendar.currentFortnight;
    this.date2 = LocalStorageManager.home.getDate2();
    this.selectedAccounts = this.services.selectedAccounts;
  }

  ngOnInit(): void {
    this.setupData();
  }

  setupData() {
    this.monthlyBalance = new MonthlyBalanceModel(
      this.movements, 
      this.date, 
      this.period.period, 
      this.selectedWeek, 
      this.fortnight, 
      this.date2, 
      this.selectedAccounts
    );
    this.total = this.getTotal();
    this.getTitle();
  }

  private getTotal() {
    return (this.monthlyBalance.incomeSum - this.monthlyBalance.expenseSum);
  }

  getTitle(){
    this.selectedPeriodtext = this.services.currentPeriod.title;
    let key = "text_cardviews.";
    switch(this.services.currentPeriod.period){
      case PeriodType.daily:
        key += "card_balance_by_day";
        break;
      case PeriodType.weekly:
        key += "card_balance_weekly";
        break;
      case PeriodType.fortnightly:
        key += "card_balance_biweekly";
        break;
      case PeriodType.monthly:
        key += "card_balance_monthly";
        break;
      case PeriodType.annually:
        key += "card_balance_yearly";
        break;
      case PeriodType.byDates:
        key += "card_balance_date_range";
        break;
    }
    this.selectedPeriodtext = key;
  }
  
  changePage() {
    this.route.navigate(['Reports-by-date/reports']);
  }
}