<div
    (click)="changePage()"
    class="card shadow clear-card-outline" 
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5 class="card-title">{{ "title_cardviews.card_flow_recent_days" | translate }}</h5>
        <ng-container *ngIf="noData">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <img class="img-fluid" src="assets/images/empty_cards/card-flow-recent-days.png">
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!noData">
            <div class="container no-padding">
                <div class="row" style="height: 300px;">
                    <ngx-charts-bar-vertical
                    [xAxis]="true"
                    [yAxis]="true"
                    [roundDomains]="true"
                    [showGridLines]="true"
                    [results]="recentDays?.chartData"
                    [scheme]="colorScheme"
                    [ngClass]="{'white-chart': isDarkmode}"
                    ></ngx-charts-bar-vertical>
                </div>
            </div>
        </ng-container>
    </div>
</div>