<div class="pe-2">
    <div
        class="btn-group btn-block col-12"
        [ngClass]="{'segment': !localStorage.isDarkmode, 'segment-dark': localStorage.isDarkmode}"
        role="group"
        aria-label="Basic example"
        >
        <button
            *ngFor="let item of items"
            style="width: 100%;"
            class="btn item-selector"
            [ngClass]="{'btn-sm' : smallSize, 'checked' : item.isSelected}"
            [ngStyle]="{'background-color': (item.isSelected && item.color) ? '#'+item.color : ''}"
            (click)="setItem(item.value)"
            >{{ item.title | translate }}
        </button>
    </div>
</div>