<body class="bg-primary-light ">
    <div class="col-12 background-img-maintenance">
        <div class="row ml-4 ">
            <div class="col-4 mt-4">
                    <h1
                        class="col-12"
                        ><img
                            src="assets/images/GD4-256.png"
                            class="col-3"
                            >{{ "app_name" | translate }}
                    </h1>
                    <h1 class="col-12 title mt-4">{{ "web_messages.message_maintenance_01" | translate }}</h1>
                    <h6 class="col-12 mt-4">{{ "web_messages.message_maintenance_02" | translate }}</h6>
                    <h6 class="col-12">{{ "web_messages.message_maintenance_03" | translate }}</h6>
                </div>
        </div>
    </div>
</body>