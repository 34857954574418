import { ModelAccounts } from '../../accounts/models/accounts.model';
import { ModelCategories } from '../../categories/model/categories.model';
import { ModelMovements } from '../../movements/model/movements.model';
import { ModelSubCategories } from '../../categories/model/subcategories.model';

export class ModelFrequentOperations {

    pkFrequentOperation: number;
    name: string;
    period: number;
    every: number;
    repeated: number;
    countered: number;
    dateInitial: string;
    dateNext: string;
    amount: number;
    sign: string;
    transfer: number;
    detail: string;
    status: number;
    fkAccount: number;
    fkToAccount: number;
    fkCategory: number;
    fkSubcategory: number;
    migrationCode: string;
    account?: ModelAccounts | null;
    account2?: ModelAccounts | null;
    category?: ModelCategories | null;
    subcategory?: ModelSubCategories | null;
    movements: ModelMovements[] = [];
    reset: number = 0;

    click: boolean = false;

    constructor(pkFrequentOperation: number, name: string, period: number, every: number, repeated: number, countered: number,
                dateInitial: string, dateNext: string, amount: number, sign: string, transfer: number, detail: string, status: number,
                fkAccount: number, fkToAccount: number, fkCategory: number, fkSubcategory: number, migrationCode: string) {

        this.pkFrequentOperation = pkFrequentOperation;
        this.name = name;
        this.period = period;
        this.every = every;
        this.repeated = repeated;
        this.countered = countered;
        this.dateInitial = dateInitial;
        this.dateNext = dateNext;
        this.amount = amount;
        this.sign = sign;
        this.transfer = transfer;
        this.detail = detail;
        this.status = status;
        this.fkAccount = fkAccount;
        this.fkToAccount = fkToAccount;
        this.fkCategory = fkCategory;
        this.fkSubcategory = fkSubcategory;
        this.migrationCode = migrationCode;
    }
}