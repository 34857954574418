import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-check-all-categories',
  templateUrl: './select-check-all-categories.component.html',
  styleUrls: ['./select-check-all-categories.component.css']
})
export class SelectCheckAllCategoriesComponent implements OnInit {
  @Input() text = 'Select All';
  @Input() selectAll : boolean = false;

  @Output() emmitSelectAll = new EventEmitter<boolean>();
  ls = LocalStorageManager;

  constructor(private translate: TranslateService) {
    this.translate.use(this.ls.lang!);
  }

  ngOnInit(): void {}

  isChecked() {
    if (this.selectAll) {
        this.selectAll = true;
    }
    this.emmitSelectAll.emit(this.selectAll);
  }
}
