<div
    (click)="changePage()"
    class="card shadow clear-card-outline"
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5 class="card-title">
            {{ selectedPeriodtext | translate}}
        </h5>
        <div class="container">
            <div class="row">
                <div class="col-5 no-padding">
                    <p>{{("words.income" | translate) + ":"}}</p>
                </div>
                <div class="col-7 text-end text-right  no-padding">
                    <p>
                        <b
                            >{{monthlyBalance.incomeSum | currencyFormat}}
                        </b>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-5 no-padding">
                    <p>{{("words.expense" | translate) + ":"}}</p>
                </div>
                <div class="col-7 text-end text-right no-padding">
                    <p >
                        <b
                            >{{monthlyBalance.expenseSum | currencyFormat}}
                        </b>
                        <i class="fa fa-minus" aria-hidden="true"></i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 no-padding" style="height: 60px;">
                    <ngx-charts-bar-vertical
                        [results]="monthlyBalance.chartData"
                        [scheme]="colorScheme"
                        >
                    </ngx-charts-bar-vertical>
                </div>
                <div class="col-6  no-padding pt-2">
                    <p class="text-end">
                        <b>
                            {{total | currencyFormat}}
                        </b>
                        <i [ngClass]="{'fa-plus' : 0 < total, 'fa-minus' : 0 > total, 'margin-end-icon' : 0 == total}" class="fa" aria-hidden="true"></i>
                    </p>
                    <p class="gray tiny">{{ ("currency_section.currency_calculated" | translate) + preferences.currency?.isoCode }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
