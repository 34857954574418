import * as moment from 'moment';
import { LocalStorageManager } from '../utils/local-storage-manager/local-storage-manager.utils';

export class MonthsModel {

    static months: MonthsModel[] = [
        new MonthsModel(moment('2022-01-01').locale(LocalStorageManager.lang!).format('MMMM'), 1, ''),
        new MonthsModel(moment('2022-02-02').locale(LocalStorageManager.lang!).format('MMMM'), 2, ''),
        new MonthsModel(moment('2022-03-01').locale(LocalStorageManager.lang!).format('MMMM'), 3, ''),
        new MonthsModel(moment('2022-04-01').locale(LocalStorageManager.lang!).format('MMMM'), 4, ''),
        new MonthsModel(moment('2022-05-01').locale(LocalStorageManager.lang!).format('MMMM'), 5, ''),
        new MonthsModel(moment('2022-06-01').locale(LocalStorageManager.lang!).format('MMMM'), 6, ''),
        new MonthsModel(moment('2022-07-01').locale(LocalStorageManager.lang!).format('MMMM'), 7, ''),
        new MonthsModel(moment('2022-08-01').locale(LocalStorageManager.lang!).format('MMMM'), 8, ''),
        new MonthsModel(moment('2022-09-01').locale(LocalStorageManager.lang!).format('MMMM'), 9, ''),
        new MonthsModel(moment('2022-10-01').locale(LocalStorageManager.lang!).format('MMMM'), 10, ''),
        new MonthsModel(moment('2022-11-01').locale(LocalStorageManager.lang!).format('MMMM'), 11, ''),
        new MonthsModel(moment('2022-12-01').locale(LocalStorageManager.lang!).format('MMMM'), 12, '')
    ];

    name: string;
    number: number;
    year: string;
    monthyear: string;

    constructor(name: string, number: number, year: string) {
        this.name = name;
        this.number = number;
        this.year = year;
        this.monthyear = number + year;
    }
}