<div
    (click)="changePage()"
    class="card shadow clear-card-outline" 
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5 class="card-title">
            {{ (selectedPeriodtext | translate)}}
        </h5>
        <div class="container">
            <div class="row">
                <div class="col-5 no-padding ">
                    <p>{{ "words.income" | translate }}</p>
                </div>
                <div class="col-7 no-padding text-end text-right">
                    <p>
                        <b
                            >{{monthtlyBalancePrevious.incomeSum | currencyFormat }}
                        </b>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-5 no-padding">
                    <p>{{ "words.expense" | translate }}</p>
                </div>
                <div class="col-7 no-padding text-end text-right">
                    <p>
                        <b
                            >{{monthtlyBalancePrevious.expenseSum | currencyFormat }}
                        </b>
                        <i class="fa fa-minus" aria-hidden="true"></i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-5 no-padding">
                    <p>{{ "title_cardviews.balance_previous" | translate }}</p>
                </div>
                <div class="col-7 no-padding text-end text-right">
                    <p>
                        <b>
                            {{(monthtlyBalancePrevious.previousTotal > 0 ? monthtlyBalancePrevious.previousTotal : monthtlyBalancePrevious.previousTotal * -1) | currencyFormat}}
                        </b>
                        <i [ngClass]="{'fa-plus' : 0 < monthtlyBalancePrevious.previousTotal, 'fa-minus' : 0 > monthtlyBalancePrevious.previousTotal, 'margin-end-icon' : 0 == monthtlyBalancePrevious.previousTotal}" class="fa" aria-hidden="true"></i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 no-padding text-end text-right" style="height: 60px;">
                    <ngx-charts-bar-vertical
                        [results]="monthtlyBalancePrevious.chartData"
                        [scheme]="colorScheme"
                        >
                    </ngx-charts-bar-vertical>
                </div>
                <div class="col-6 no-padding text-end text-right pt-2">
                    <p>
                        <b>
                            {{(getTotal() > 0 ?  getTotal() : getTotal() * -1 ) | currencyFormat}}
                        </b>
                        <i class="fa fa-plus" aria-hidden="true" *ngIf=" 0 < getTotal()"></i>
                        <i class="fa fa-minus" aria-hidden="true" *ngIf=" 0 > getTotal()"></i>
                    </p>
                    <p class="gray tiny">{{ ("currency_section.currency_calculated" | translate) +  preferences.currency?.isoCode }}</p>
                </div>
            </div>
        </div>
    </div>
</div>