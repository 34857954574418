import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  constructor(private translate: TranslateService) {
    this.loadTranslate();
  }

  loadTranslate(){
    let lang = navigator.language;
    if (lang == "es-419") {
      lang = "es";
    }
    this.translate.use(lang);
  }
  ngOnInit(): void {
  }

}
