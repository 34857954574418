<div class="mb-1">
    <div class="row">
        <div class="col-6">
            <p 
                class="gray"
                >{{ debt?.name + "-" + (debt?.getPercentage() | number: '1.2-2') }}%
            </p>
        </div>
        <div class="col-6 text-end text-right ">
            <p>{{ debt?.getSum() | currencyFormat }}</p>
        </div>
    </div>
    <div 
        [ngClass]="{'bg-primary-dark' : isDarkmode}"
        class="progress"
        >
        <div 
            class="progress-bar"
            style="background-color: #9EB1BE;"
            role="progressbar"
            [ngStyle]="{'width' : (debt?.getPercentage() | number: '1.2-2')+'%'}" 
            aria-valuenow="25" 
            aria-valuemin="0" 
            aria-valuemax="100"
            >
        </div>
    </div>
</div>