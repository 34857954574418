export enum SelectorDateType {
    home = 1,
    movements = 2,
    reportsByDate = 3,
    reportsByCategory = 4,
    trendsCategory = 5,
    budgets = 6,
}

export enum Axis {
    horizontal = 0,
    vertical = 1
}