
<!-- Modal moneda -->
<div 
    class="modal fade" 
    id="app-modal-currencies" 
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="modelTitleId" 
    aria-hidden="true"
    >
    <div 
        class="modal-dialog modal-dialog-centered" 
        role="document"
        >
        <div 
            class="modal-content rounded" 
            [ngClass]="{'bg-dark white-text': (ls.isDarkmode && !isModalAddCurrencyCalled), 
                        'bg-primary-dark': (ls.isDarkmode && isModalAddCurrencyCalled),
                        'bg-primary-light': (!ls.isDarkmode && isModalAddCurrencyCalled)}"
            >
            <app-header-title
                title="currency_section.currency_choose"
                modalId="app-modal-currencies"
                >
            </app-header-title>
            <div class="modal-body">
                <div class="container">
                    <div class="row text-center">
                        <div class="offset-1 col-10 scrollable no-padding">
                            <table 
                                id="table-currencies"
                                class="table table-hover table-borderless rounded"
                                >
                                <thead class="">
                                    <th 
                                        class="sticky-header" 
                                        [ngClass]="{'bg-dark': ls.isDarkmode, 'bg-light': !ls.isDarkmode}"
                                        >{{ "currency_section.currency_iso_code" | translate }}
                                    </th>
                                    <th 
                                        class="sticky-header" 
                                        [ngClass]="{'bg-dark': ls.isDarkmode, 'bg-light': !ls.isDarkmode}"
                                        >{{ "currency_section.currency_symbol" | translate }}
                                    </th>
                                    <!-- <th 
                                        class="sticky-header" 
                                        [ngClass]="{'bg-dark': ls.isDarkmode, 'bg-light': !ls.isDarkmode}"
                                        >{{ "currency_section.currency_choose" | translate }}
                                    </th> -->
                                </thead>
                                <tbody>
                                    <tr 
                                        *ngFor="let currency of currencies" 
                                        class="pointer" 
                                        (click)="setCurrency(currency)"
                                        >
                                        <td class="col-5">{{currency.isoCode}}</td>
                                        <td class="col-5">{{currency.symbol}}</td>
                                        <td class="col-2 text-center">
                                            <i 
                                                class="fa fa-check" 
                                                aria-hidden="true" 
                                                *ngIf="currency == currentCurrency"
                                                >
                                            </i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-10 offset-1 text-end text-right mt-2" *ngIf="!isModalAddCurrencyCalled">
                            <button 
                                class="btn clear-outline bg-main-purple white-text" 
                                data-bs-toggle="modal" 
                                data-bs-target="#app-modal-add-currency"
                                (click)="showModalAddCurrency()"
                                >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="col-12 text-center" *ngIf="!isModalAddCurrencyCalled">
                            <button                                 
                                class="btn btn-lg clear-outline bg-main-purple white-text" 
                                data-bs-dismiss="modal"
                                (click)="confirmCurrency()"
                                >{{"buttons.button_save" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal-add-currency
    (onClose)="showModalAddCurrency()"
    >
</app-modal-add-currency>