export interface PeriodData {
    title: string;
    period: PeriodType;
}

export enum PeriodType {
    daily = 0,
    weekly = 1,
    fortnightly = 2,
    monthly = 3,
    annually = 4,
    byDates = 5
}