import { Component, OnInit } from '@angular/core';

import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { ModelDebts } from 'src/app/pages/debts/model/debts.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesModel } from '../../../../models/preferences.model';
import { Router } from '@angular/router';
import { SyncAll } from '../../../../services/sync-all.service';

@Component({
  selector: 'app-balance-debts',
  templateUrl: './balance-debts.component.html',
  styleUrls: ['./balance-debts.component.css']
})
export class BalanceDebtsComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;
  preferences = PreferencesModel.shared;
  noData: boolean = false;

  accounts: ModelAccounts[] = [];
  debts: ModelDebts[] = [];
  
  total = 0;
  loading: boolean = false;

  debtsFilter: ModelDebts[] = [];
  debtsFilterThey: ModelDebts[] = [];
  debtsFilterMe: ModelDebts[] = [];

  constructor(private homeService: SyncAll,
              private translate: TranslateService,
              private route: Router) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.loading = true;
    this.accounts = this.homeService.selectedAccounts;
  }
  
  ngOnChanges(): void {
    this.filterDebts();
  }

  filterDebts() {
    this.debtsFilter = [];
    this.debtsFilter = this.debts.filter(debt => debt.showHome == 1);
    this.debtsFilterThey = this.debtsFilter.filter(debt => debt.sign == "-");
    this.debtsFilterMe = this.debtsFilter.filter(debt => debt.sign == '+');
    this.noData = this.debtsFilter.length == 0;
  }

  setTotal() {
    const reducer = (previousValue : any, currentValue : any) => previousValue + currentValue;
    this.total = this.debts.map(d => d.getSum()).reduce(reducer, 0);
  }

  changePage() {
    this.route.navigate(['Debts/list']);
  }
}