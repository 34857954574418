import { PeriodType } from '../../components/common-components/selector-period/interfaces/period.interface';

export class LocalStorageBudgets {

    private keyPeriod: string = 'LSBudgetsPeriod';
    private keyCurrentDate: string = 'LSBudgetsCurrentDate';
    private keySecondCurrentDate: string = 'LSBudgetsCurrentDate';

    getPeriod(): PeriodType {
        return +localStorage.getItem(this.keyPeriod)!;
    }

    getCurrentDate(): string | null {
        return localStorage.getItem(this.keyCurrentDate);
    }

    getSecondCurrentDate(): string | null {
        return localStorage.getItem(this.keySecondCurrentDate);
    }

    setPeriod(period: PeriodType) {
        localStorage.setItem(this.keyPeriod, period.toString())
    }

    setCurrentDate(currentDate: string) {
        localStorage.setItem(this.keyCurrentDate, currentDate);
    }

    setSecondCurrentDate(secondCurrentDate: string) {
        localStorage.setItem(this.keySecondCurrentDate, secondCurrentDate);
    }

    removeAll() {
        localStorage.removeItem(this.keyPeriod);
        localStorage.removeItem(this.keyCurrentDate);
        localStorage.removeItem(this.keySecondCurrentDate);
    }
}