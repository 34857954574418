export interface MovementTypeData {
    title: string;
    type?: MovementTypes;
    sign?: MovementSign;
}

export enum MovementTypes {
    incomes = '+',
    expenses = '-',
    tranfers = '<>',
    all = '*'
}

export enum MovementSign {
    plus = '+',
    minus = '-'
}