import * as moment from 'moment';

import { ModelCategories } from '../../../../pages/categories/model/categories.model';
import { ModelMovements } from '../../../../pages/movements/model/movements.model';
import { FortnightModel } from '../../../../models/biweekly.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { PeriodType } from 'src/app/components/common-components/selector-period/interfaces/period.interface';
import { WeekModel } from '../../../../models/week.model';


export class SumaryCategoriesCardModel {
    
    categories: ModelCategories[] = [];

    selectedAccounts: ModelAccounts[] = [];
    total: number = 0;
    categoriesBalances: CategoryBalance[] = [];
    movements: ModelMovements[] = [];
    chartData : any = [];
    customColor : any = [];
    date: moment.Moment;
    date2: moment.Moment;
    period: PeriodType;
    week: WeekModel;
    fortnight: FortnightModel;

    constructor(categories: ModelCategories[], selectedAccounts: ModelAccounts[], date: moment.Moment, date2: moment.Moment, period: PeriodType,
                week: WeekModel, fortnight: FortnightModel, movements: ModelMovements[]) {
        this.categories = categories;
        this.selectedAccounts = selectedAccounts;
        this.date = date;
        this.date2 = date2;
        this.period = period;
        this.week = week;        
        this.fortnight = fortnight;
        this.movements = movements.filter(row => row.transfer == 0);
        this.getTotalOfCategories();
    }

    getTotalOfCategories() {
        this.categories.forEach(category => {
            category.movements = this.movements.filter(m => m.fkCategory == category.id);
            const categoryTotal = this.getCategoryTotal(category);
            if (categoryTotal != 0) {
                const categoryBalance: CategoryBalance = {
                    name: category.name,
                    total: categoryTotal,
                    color: category.color,
                    icon: category.icon,
                    sign: category.sign
                };
                this.categoriesBalances.push(categoryBalance);
            }
        });

        this.categoriesBalances.forEach(balance => {
            this.total = this.total + balance.total;
        });
        this.categoriesBalances.sort((a,b) => b.total - a.total);
        this.categoriesBalances.forEach(data => {
            this.chartData.push(
                {
                    name: data.name,
                    value: data.total
                }
            );
            this.customColor.push(
                {
                    name: data.name,
                    value: '#' + data.color
                }
            );
        });

    }
    getCategoryTotal(category: ModelCategories): number {
        let total: number = 0;
        category.movements.forEach((movement: ModelMovements) => {
            if( this.selectedAccounts.find(account => account.pkAccount == movement.fkAccount ) ){
                switch (this.period) {
                    case PeriodType.daily:
                        if ( moment(movement.date).format('YYYY-MM-DD') == this.date.format('YYYY-MM-DD')) {
                            total = +total + +movement.amount!;
                        }
                        break;
                    case PeriodType.weekly:
                        if ( (moment(movement.date).year() == this.date.year()) && ((moment(movement.date).week() -1) == this.week.weekNumber)) {
                            total = +total + +movement.amount!;
                        }
                        break;
                    case PeriodType.fortnightly:
                        let initialDate = this.fortnight.year+"-"+this.fortnight.doubleDigit(this.fortnight.month.number)+"-"+this.fortnight.initialDay;
                        let finishDate = this.fortnight.year+"-"+this.fortnight.doubleDigit(this.fortnight.month.number)+"-"+this.fortnight.finalDay;
                        if (moment(movement.date) >= moment(initialDate) && moment(movement.date) <= moment(finishDate)) {
                            total += movement.amount!;
                        }
                        break;
                    case PeriodType.monthly:
                        //correcto = 120
                        if ( moment(movement.date).format('YYYY-MM') == moment(this.date).format('YYYY-MM') ) {
                            if (movement.category?.name == "Movistar") {
                                console.log("amount: ", movement.amount, "date", movement.date, "sign", movement.sign, "categor", movement.category, "movement fk", movement.fkCategory);
                                
                            }
                            total += movement.amount!;
                        }
                        break;
                    case PeriodType.annually:
                        if ( moment(movement.date).format('YYYY') == moment(this.date).format('YYYY') ) {
                            total = +total + +movement.amount!;
                        }
                        break;

                    case PeriodType.byDates:
                        if ( moment(movement.date).format('YYYY-MM-DD') >= moment(this.date).format('YYYY-MM-DD') &&
                            moment(movement.date).format('YYYY-MM-DD') <= moment(this.date2).format('YYYY-MM-DD') ) {
                            total = +total + +movement.amount!;
                        }
                        break;
                }
            }
        });
        return total;
    }
}

interface CategoryBalance {
    name: string;
    total: number;
    color: string;
    icon: string;
    sign: string;
}
