import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthGoogleService } from '../services/auth-google.service';

import { LoginService } from '../services/login.service';
import { UserModel } from '../../../models/user.modal';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { MaintenanceServicesService } from '../../../services/maintenance-services.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;
  logged : any;

  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });
  preferencesLogin?: FormGroup;

  user?: UserModel;

  pkUserPassword: string = "";
  tokenPassword: string = "";
  codePassword: number = 0;
  emailPassword: string = "";
  titleMessage: string = "";
  bodyTextMessage = "";

  maintenance: boolean = true;

  showMessage: boolean = true;

  constructor(
      private fb: FormBuilder,
      private services: LoginService,
      private authGoogle2: AuthGoogleService,
      private maintenanceServices: MaintenanceServicesService,
      private translate: TranslateService) {
    this.loadTranslate();
  }

  loadTranslate(){
    this.logged = localStorage.getItem('logged');
    let lang = localStorage.getItem('lang');
    if (lang == null) {
      lang = this.getLanguage();
      localStorage.setItem('lang', lang);
    }
    this.translate.use(lang);
  }

  getLanguage() : string {
    const language = navigator.language
    if (language == 'es' || language == 'en') {
        return language;
    } else if (language == 'es-US') {
      return 'es';
    }
    return 'en';
  }

  GoogleAuth(){
    this.authGoogle2.GoogleAuth().then((result : any) => {
      let email = result['email'];
      let params = this.getParamsFormGoogle(email);
      this.loginService(params);
    }, reject=> {
      console.log("Error GoogleAuth()",reject);
    });
  }

  AppleAuth(){
    this.authGoogle2.AppleAuth().then((result: any) => {
      let params = this.getParamsFormApple(result);
      this.loginService(params);
    }, reject=> {
      console.log("Error AppleAuth()",reject);
    });
  }

  async loginService(params : any){
    this.services.getUser(params.value).then( isActive => {
      if (isActive) {
        window.location.href = '/Home';
      } else {
        this.titleMessage = "initial_login.button_login";
        this.bodyTextMessage = "web_messages.validation_subscription_finished";
        document.getElementById('open-modal-success')?.click();
        console.log('Subscription finished');
      }
    }, reject => {
      this.titleMessage = "initial_login.button_login";
      this.bodyTextMessage = "login_messages.message_wrong_email_and_password";
      document.getElementById('open-modal-success')?.click();
      console.log('User not found', reject);
    });
  }

  getParamsFormGoogle(email: string){
    return this.fb.group({
      table: [ 'user_accounts' ],
      email: [ email ],
      password: [ '' ],
      service: [ 1 ]
    });
  }

  getParamsFormApple(appleId: string){
    return this.fb.group({
      table: [ 'user_accounts' ],
      apple_id: [ appleId ],
      password: [ '' ],
      service: [ 2 ]
    });
  }

  ngOnInit(): void {
    this.maintenance = false;
    this.maintenanceServices.getMaintenance().then(_ => {
      sessionStorage.setItem('logged', '0');
      this.createForm();
    }, reject => {
      console.log(reject);
      this.maintenance = true;
    });
  }

  createForm() {
    this.loginForm = this.fb.group({
        email: [ '' ],
        password: [ '' ],
        service: [ 0 ]
    });
  }

  getUser() {
    this.services.getUser(this.loginForm.value).then( isActive => {
      if (isActive) {
        window.location.href = '/Home';
      } else {
        this.titleMessage = "initial_login.button_login";
        this.bodyTextMessage = "web_messages.validation_subscription_finished";
        document.getElementById('open-modal-success')?.click();
        console.log('Subscription finished');
      }
    }, reject => {
      this.titleMessage = "initial_login.button_login";
      this.bodyTextMessage = "login_messages.message_wrong_email";
      document.getElementById('open-modal-success')?.click();
      console.log('User not found', reject);
    });
  }

  setPkUserPassword(event: string) {
    this.pkUserPassword = event;
  }

  setTokenPassword(event: string) {
    this.tokenPassword = event;
  }

  setCode(event: number) {
    this.codePassword = event;
  }

  setEmailPassword(event: string) {
    this.emailPassword = event;
    document.getElementById('open-modal-change-password')?.click();
  }

  successChangePassword(){
    setTimeout(() => {
      this.titleMessage = "web_titles.title_changed_password";
      this.bodyTextMessage = "Exitoso";
      document.getElementById('open-modal-success')?.click();
    }, 50);
  }

  setShow(change: boolean){
    this.showMessage = change;
  }
}
