import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { ResponseMaintenance } from '../shared/maintenance/interfaces/maitenance.interfaces';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceServicesService {

  constructor(private services: CrudService<any>) { }

  getMaintenance(){
    return new Promise((resolve, reject) => {
      this.services.services().subscribe((response: ResponseMaintenance) => {
        if (response.status == 1) {
          reject(response.message);
        } else {
          resolve(true);
        }
      });
    }); 
  }
}
