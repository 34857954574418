import { PeriodType } from '../../components/common-components/selector-period/interfaces/period.interface';
import { Sign } from '../global.enums';
import * as moment from 'moment';
export class LocalStorageCategoryTrends {

    private keyCategories: string = 'LSTrendsCategories'
    private keyPeriod: string = 'LSTrendsPeriod'
    private keySign: string = 'LSTrendsSign'
    private keyDate: string = "LSTrendsCategoriesDate";
    private keyDate2: string = "LSTrendsCategories2";

    getDate(): moment.Moment {
        return moment((localStorage.getItem(this.keyDate) != undefined) ? localStorage.getItem(this.keyDate) : new Date());
    }

    setDate(date: moment.Moment) {
        localStorage.setItem(this.keyDate, date.toString());
    }

    setDate2(date: moment.Moment) {
        localStorage.setItem(this.keyDate2, date.toString());
    }

    getDate2(): moment.Moment {
        return moment((localStorage.getItem(this.keyDate2) != undefined) ? localStorage.getItem(this.keyDate2) : new Date());
    }

    getCategoriesIds(): number[] {
        return JSON.parse(localStorage.getItem(this.keyCategories)!);
    }
    
    setCategoriesIds(ids: number[]) {
        localStorage.setItem(this.keyCategories, JSON.stringify(ids))
    }

    getPeriod(): PeriodType {
        const period = +localStorage.getItem(this.keyPeriod)! as PeriodType;
        return period;
    }

    setPeriod(type: PeriodType) {
        localStorage.setItem(this.keyPeriod, type.toString());
    }

    getSign(): Sign {
        const sign = localStorage.getItem(this.keySign) as Sign;
        return (sign?.toString() == 'undefined' ? null : sign) ?? Sign.minus;
    }
    setSign(sign: Sign) {
        localStorage.setItem(this.keySign, sign.toString());
    }

    removeAll() {
        localStorage.removeItem(this.keyCategories);
        localStorage.removeItem(this.keyPeriod);
        localStorage.removeItem(this.keySign);
    }
}