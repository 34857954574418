<div>
    <div class="container">
        <div class="row">
            <div
                class="col text-center"
                [ngClass]="{'white-text': darkmode === 'true'}"
                >
                <img
                    height="300"
                    width="300"
                    src="assets/images/Load.gif">
            </div>
        </div>
    </div>
</div>