import { Component, Input, OnInit } from '@angular/core';
import { PreferencesModel } from '../../../models/preferences.model';
import { MovementSign } from '../selector-movement-type/interfaces/movement-type.interface';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-bottom-bar-balances',
  templateUrl: './bottom-bar-balances.component.html',
  styleUrls: ['./bottom-bar-balances.component.css']
})
export class BottomBarBalancesComponent implements OnInit {

  @Input() movementsType : MovementSign | null = null;
  @Input() incomes: number = 0;
  @Input() expenses: number = 0;
  @Input() total: number = 0;
  @Input() showIsoCode: boolean = false;
  
  isDarkmode = LocalStorageManager.isDarkmode;
  preference = PreferencesModel.shared;
  panel: boolean = true;

  preferences = PreferencesModel.shared;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void { }
}