export interface GoalResponseSimple {
    message: string;
    data:    Goal[];
    status:  string;
    found: number;
}

export interface ResponseGoals {
    data:   Data;
    message: string;
    status:  string;
    found: number;
}

export interface Data {
    table_goals:         Goal[];
    table_goals_records: GoalsRecord[];
}

export interface Goal {
    pk_goal:      string;
    name:         string;
    amount:       string;
    date_desired: string;
    color_hex:    string;
    status:       string;
    icon_name:    string;
    fk_user:      string;
    server_date:  string;
}

export interface GoalsRecord {
    pk_goal_record: string;
    date:           string;
    amount:         string;
    fk_goal:        string;
    server_date:    string;
}

export enum GoalStatus {
    active = 0,
    pause = 1,
    finished = 2
}

export interface GoalStatusData{
    status: GoalStatus,
    title: string,
    imageName: string
}