
export class ModelDebtRecords {

    amount: number;
    date: string;
    detail: string;
    fkDebt: number;
    sign: string;
    fkAccount: number;
    pkDebtRecord: number;
    clickRecord: boolean = false;

    constructor(amount: number, date: string, detail: string, fkDebt: number, sign: string, fkAccount: number, pkDebtRecord: number){
        this.amount = amount;
        this.date = date;
        this.detail = detail;
        this.fkDebt = fkDebt;
        this.sign = sign;
        this.fkAccount = fkAccount;
        this.pkDebtRecord = pkDebtRecord;
    }
}