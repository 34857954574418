import { S3 } from "aws-sdk";

export class AwsImage {

    public getImage(image: string, email: string) {
        if (image == null  || image.length == 0) {
          return null;
        }
        let bucket = "images.dailyexpenses4.com";
        let key = "images/" + email + "/" + image;
        const params = {
          Bucket : bucket,
          Key: key
        };
        const s3Client = this.getS3Bucket();
        return s3Client.getSignedUrl('getObject', params);
      }
    
    private getS3Bucket(): S3 {
      const bucket =  new S3(
          {
          apiVersion: 'latest',
          region: 'us-west-1',
          accessKeyId: 'AKIAZIRI6DRHEC6GEPMI',
          secretAccessKey: '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'
          }
      );
      return bucket;
    }
}