import { Component, OnInit } from '@angular/core';
import { RecentDaysFlowModel } from '../model/recentDaysFlow.model';
import * as moment from 'moment';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { ModelMovements } from '../../../../pages/movements/model/movements.model';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SyncAll } from '../../../../services/sync-all.service';

@Component({
  selector: 'app-recent-days-flow',
  templateUrl: './recent-days-flow.component.html',
  styleUrls: ['./recent-days-flow.component.css']
})
export class RecentDaysFlowComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;

  movements: ModelMovements[] = [];
  date: moment.Moment = moment();
  noData: boolean = false;

  recentDays: RecentDaysFlowModel | null = null;

  colorScheme = {
    domain: ['#3F647EB3', '#7499B4B3', '#AECAD8B3', '#EE7387B3', '#EF9AA9B3', '#F0C0C9B3', '#F0C892B3']
  };

  constructor(private services: SyncAll,
    private translate: TranslateService,
    private route: Router) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
    this.movements = this.services.movements;
    this.date = this.services.currentDate;
  }

  ngOnInit(): void {
    if (this.movements.length == 0) {
      this.noData = true;
    } else {
      this.movements = this.movements.filter(movement => moment(movement.date).format('YYYY') == moment().format('YYYY') );
      let accountsSelecteds = this.services.selectedAccounts;
      this.recentDays = new RecentDaysFlowModel(this.movements, accountsSelecteds);
      let haveMovements = true;
      this.recentDays.flowDays.forEach(day => {
        if (day.movements.length > 0) {
          haveMovements = false;
        }
      });
      this.noData = haveMovements;
    }
  }

  changePage() {
    this.route.navigate(['Reports-by-date/reports']);
  }
}