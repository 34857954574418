import { Component, Input, OnInit } from '@angular/core';
import { ModelDebts } from '../../../../../pages/debts/model/debts.model';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-row-debt',
  templateUrl: './row-debt.component.html',
  styleUrls: ['./row-debt.component.css']
})
export class RowDebtComponent implements OnInit {
  @Input() debt : ModelDebts | null = null;
  isDarkmode = LocalStorageManager.isDarkmode;

  constructor() { }

  ngOnInit(): void {
  }

}
