<div
    (click)="changePage()"
    class="animate__animated animate__fadeIn"
    *ngIf="!loading"
    >
    <div 
        class="card shadow clear-card-outline" 
        [ngClass]="{'bg-dark white-text': isDarkmode}"
        >

        <ng-container *ngIf="noData">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <img 
                            class="img-fluid" 
                            src="assets/images/empty_cards/card_debts.png"
                            >
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!noData">
            <div class="card-body">
                <h5 class="card-title">{{ "title_cardviews.card_balance_debts" | translate }}</h5>
                <ng-container *ngIf="debtsFilterThey.length != 0">
                    <div class="mb-3">
                        <h5>{{ "title_cardviews.card_i_owe" | translate }}</h5>
                        <div *ngFor="let debt of debtsFilterThey">
                            <app-row-debt
                                [debt]="debt"
                                >
                            </app-row-debt>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="debtsFilterMe.length != 0">
                    <div class="mb-3">
                        <h5>{{ "title_cardviews.card_they_owe" | translate }}</h5>
                        <div *ngFor="let debt of debtsFilterMe">
                            <app-row-debt
                                [debt]="debt"
                                >
                            </app-row-debt>
                        </div>
                    </div>
                </ng-container>
                <hr>
                <div class="row">
                    <div class="col text-end text-right">
                        <p>{{total | currencyFormat}}</p>
                        <p class="gray tiny">{{ ("currency_section.currency_calculated" | translate) + preferences.currency?.isoCode }}</p>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>