import * as moment from 'moment';
import { ModelMovements } from '../../../../pages/movements/model/movements.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';

export class RecentDaysFlowModel {

    accountsSelecteds: ModelAccounts[];
    movements: ModelMovements[] = [];
    totalPlus = 0;
    totalMinus = 0;
    total = 0;
    title: string = "";
    today = moment();
    flowDays: FlowDays[] = [];
    chartData : any;

    constructor(movements: ModelMovements[], accountsSelecteds: ModelAccounts[]) {
        this.movements = movements;
        this.accountsSelecteds = accountsSelecteds;
        this.setDays(movements);
    }

    setDays(movements : any) {
        const days = [];
        for (let index = 0; index < 7; index++) {
            days.push(moment(this.today).format('MM-DD-YYYY'));
            this.today = this.today.subtract(1, 'days');
        }
        days.reverse();
        days.forEach(day => {
            let filteredMovements: ModelMovements[] = [];
            filteredMovements = movements.filter(
                (movement : any) => this.accountsSelecteds.find(account =>  account.pkAccount == movement.fkAccount) && moment(movement.date).format('DD-MM-YYYY') == moment(day).format('DD-MM-YYYY')
            );
            const flowDay: FlowDays = {
                day,
                movements: filteredMovements
            };

            this.flowDays.push(flowDay);

        });
        this.getSums(this.flowDays);
    }

    getSums(flowDays: FlowDays[]){
        flowDays.forEach(flowDay => {
            let positive = 0;
            let negative = 0;
            flowDay.movements.forEach(movement => {
                if (movement.sign == '-') {
                    negative = +negative + +movement.amount!;
                } else {
                    positive = +positive + +movement.amount!;
                }
            });
            flowDay.minusSum = negative;
            flowDay.plusSum = positive;
        });
        this.fillChart(flowDays);

    }

    fillChart(flowDays: FlowDays[]){
        this.chartData = [];
        flowDays.forEach(flowDay => {
            this.chartData.push(
                {name: moment(flowDay.day).format('DD-MMM'), value: flowDay.plusSum},
                {name: moment(flowDay.day).format('DD-MMM'), value: (flowDay.minusSum * -1)},
            );
        });
    }
}

interface FlowDays {
    day : any;
    movements: ModelMovements[];
    plusSum? : any;
    minusSum? : any;
}
