export class ModelCurrency {

    pkCurrency: number;
    isoCode: string;
    symbol: string;
  
    constructor(pkCurrency: number, isoCode: string, symbol: string) {
        this.pkCurrency = pkCurrency;
        this.isoCode = isoCode;
        this.symbol = symbol;
    }
}