<!-- Bottom bar panel -->
<div class="row">
    <div class="col-12 offset-sm-3 col-sm-4">
        <footer 
            class="footer container text-center animate__animated animate__backInUp shadow pointer" 
            (click)="panel = !panel" 
            data-bs-toggle="collapse" 
            data-bs-target="#filterPanel"
            aria-expanded="false" 
            aria-controls="filterPanel" 
            [ngClass]="{'bg-dark white-text': isDarkmode}">
            <div>
                <i 
                    class="fa fa-chevron-up" 
                    aria-hidden="true"
                    data-bs-toggle="collapse" 
                    data-bs-target="#filterPanel"
                    *ngIf="panel" 
                    [ngClass]="{'gray': !isDarkmode}">
                </i>
                <i
                    class="fa fa-chevron-down"
                    data-bs-toggle="collapse" 
                    data-bs-target="#filterPanel"
                    aria-hidden="true" 
                    *ngIf="!panel" 
                    [ngClass]="{'gray': !isDarkmode}">
                </i>
                <div>
                <h6 class="no-space" [ngClass]="{'white-text': isDarkmode}">{{total | currencyFormat}}</h6>
                <p class="gray tiny" [ngClass]="{'font-secondary-dark': isDarkmode}">{{ ("currency_section.currency_calculated" | translate) + (showIsoCode? preference.currency?.isoCode : "")}}</p>
                </div>
            </div>

            <div class="collapse mb-1" id="filterPanel">
                <p class="gray" [ngClass]="{'font-secondary-dark': isDarkmode}">{{ "words.balance" | translate }}</p>
                <h6  
                    *ngIf="movementsType == '+' || (movementsType != '-' && movementsType != '+')" 
                    [ngClass]="{'white-text': isDarkmode}"
                    >
                    {{incomes | currencyFormat}}
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </h6>
                <h6 
                    *ngIf="movementsType == '-' || (movementsType != '+' && movementsType != '-')" 
                    [ngClass]="{'white-text': isDarkmode}"
                    >
                    {{expenses | currencyFormat}}
                    <i class="fa fa-minus" aria-hidden="true"></i>
                </h6>
            </div>
        </footer>
    </div>
</div>