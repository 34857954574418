import * as moment from 'moment';
import { PeriodType } from '../../components/common-components/selector-period/interfaces/period.interface';
import { MovementSign } from '../../components/common-components/selector-movement-type/interfaces/movement-type.interface';

export class LocalStorageReportByCategory {

    private keyPeriod: string = 'LSReportCategoryPeriod';
    private keySign: string = 'LSTReportCategorySign';
    private keyDate: string = "LSReportCategoryDate";
    private keyDate2: string = "LSReportCategoryDate2";

    getDate(): moment.Moment {
        return moment((localStorage.getItem(this.keyDate) != undefined) ? localStorage.getItem(this.keyDate) : moment(new Date()).format('yyyy-MM-DD'));
    }

    setDate(date: moment.Moment) {
        localStorage.setItem(this.keyDate, date.format('yyyy-MM-DD'));
    }

    setDate2(date: moment.Moment) {
        localStorage.setItem(this.keyDate2, date.format('yyyy-MM-DD'));
    }

    getDate2(): moment.Moment {
        return moment((localStorage.getItem(this.keyDate2) != undefined) ? localStorage.getItem(this.keyDate2) : moment(new Date()).format('yyyy-MM-DD'));
    }

    getPeriod(): PeriodType {
        return localStorage.getItem(this.keyPeriod) != null ? +localStorage.getItem(this.keyPeriod)! : PeriodType.monthly;
    }

    setPeriod(type: PeriodType) {
        localStorage.setItem(this.keyPeriod, type.toString());
    }

    getSign(): MovementSign {
        return localStorage.getItem(this.keySign) as MovementSign ?? null;;
    }
    setSign(sign: MovementSign) {
        localStorage.setItem(this.keySign, sign.toString());
    }

    removeAll() {
        localStorage.removeItem(this.keyDate);
        localStorage.removeItem(this.keyDate2);
        localStorage.removeItem(this.keyPeriod);
        localStorage.removeItem(this.keySign);
    }
}