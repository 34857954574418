import { LocalStorageHome } from "./loca-storage-home.Utils";
import { LocalStorageBudgets } from "./local-storage-budgets.Utils";
import { LocalStorageCategoryTrends } from "./local-storage-categoryTrends.utils";
import { LocalStorageMovements } from "./local-storage-movements.utils";
import { LocalStorageReportByDate } from "./local-storage-reportByDate.utils";
import { LocalStorageSchedule } from "./local-storage-schedule.utils";
import { LocalStorageReportByCategory } from './local-storage-reportByCategory.utils';

export class LocalStorageManager {

    public static isDarkmode = localStorage.getItem('darkmode') == 'true' ? true : false;
    public static lang = localStorage.getItem('lang') != null ? localStorage.getItem('lang') : 'en';

    public static movements = new LocalStorageMovements();

    public static schedule = new LocalStorageSchedule();

    public static categoryTrends = new LocalStorageCategoryTrends();
    public static reportByDate = new LocalStorageReportByDate();
    public static reportByCategory = new LocalStorageReportByCategory();

    public static budgets = new LocalStorageBudgets();

    public static home = new LocalStorageHome();
    static LocalStorageManager: any;

    public static removeAll() {
        LocalStorageManager.movements.removeAll();
        LocalStorageManager.schedule.removeAll();
        LocalStorageManager.categoryTrends.removeAll();
        LocalStorageManager.reportByDate.removeAll();
        LocalStorageManager.reportByCategory.removeAll();
        LocalStorageManager.budgets.removeAll();
        LocalStorageManager.home.removeAll();
    }

    public static setDarkmode(value: boolean) {
        localStorage.setItem('darkmode', value ? 'true' : 'false');
        LocalStorageManager.isDarkmode = localStorage.getItem('darkmode') == 'true' ? true : false;
    }

    public static setLanguage(value: string) {
        localStorage.setItem('lang', value != null ? value : this.getLanguage());
        LocalStorageManager.lang = localStorage.getItem('lang');
    }

    private static getLanguage() : string {
        const language = navigator.language
        if (language == 'es' || language == 'en') {
            return language;
        } else if (language == 'es-US') {
            return 'es';
        }
        return 'en';
      }
}