import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { MonthsModel } from './months.model';

export class FortnightModel {

    datePipe = new DatePipe('es');
    year: number;
    date: moment.Moment | any;
    month: MonthsModel;
    fortnightNumber: number;
    initialDay: number;
    finalDay: number;
    title: string;
    completeDate: string;

    constructor(year: number, month: MonthsModel, fortnightNumber: number, initialDay: number, finalDay: number) {
        this.year = year;
        this.month = month;
        this.fortnightNumber = fortnightNumber;
        this.initialDay = initialDay;
        this.finalDay = finalDay;
        this.completeDate =  month.number + '-' + initialDay + '-' + year;
        this.title = this.getTitles();
    }

    getTitles() {
        return this.month.name + ' ' + this.initialDay + ' al ' + this.finalDay;
    }

    doubleDigit(day : number): string{
        if (day <= 9) {
            return "0"+day
        }
        return day.toString();
    }
}

export class FortnightsByYear {

    datePipe = new DatePipe('es');
    year;
    fortnights: FortnightModel[] = [];

    constructor(year : any) {
        this.year = year;
        this.setUpFortnights();
    }

    setUpFortnights(){
        const months = MonthsModel.months;
        let i = 0;
        months.forEach(month => {
            i++;
            const fortnight1 = new FortnightModel(this.year, month, i, 1, 15);
            i++;
            let monthyearString = month.number + '-01-' + this.year + ' 00:00';
            let monthyear: any = new Date(monthyearString);
            const lastday = +this.datePipe.transform(moment(monthyear).endOf('month').toString(),'dd')!;

            const fortnight2 = new FortnightModel(this.year, month, i, 16, lastday);
            this.fortnights.push(fortnight1, fortnight2);
        });
    }
}