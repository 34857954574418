<div 
    class="card rounded card-shadow" 
    [ngClass]="{'bg-dark white-text': localStorage.isDarkmode, 'card-shadow': !localStorage.isDarkmode}"
    >
    <div class="card-body">
        <h4 
            class="card-title title-secondary"
            [ngClass]="{'white-text': localStorage.isDarkmode}"
            >{{ "settions_section.settings_format_date_time" | translate }}
        </h4>
        <div
            [ngClass]="{'font-secondary-dark': localStorage.isDarkmode}"
            class="container text-center font-secondary"
            >
            <div class="row">
                <div class="col-12 no-padding">
                    <p class="no-margin text-start">{{ "words.date" | translate }}</p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="dateFormatsItems"
                        [currentItem]="settings.dateFormat"
                        (emitter)="setDateFormat($event)"
                        >
                    </app-segmented-selector>
                </div>
            </div>
            <div class="row">
                <div class="col-12 no-padding mt-2">
                    <p class="no-margin text-start">{{ "words.hour" | translate }}</p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="timeFormatsItems"
                        [currentItem]="settings.timeFormat"
                        (emitter)="setTimeFormat($event)"
                        >
                    </app-segmented-selector>
                </div>
            </div>
            <div class="row">
                <div class="col-12 no-padding mt-2 mb-2">
                    <p class="no-margin text-start">{{ "others_dialogs.first_day_week" | translate }}</p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="firstWeekDaysItems"
                        [currentItem]="settings.firstWeekday"
                        (emitter)="setFirstWeekDay($event)"
                        >
                    </app-segmented-selector>
                </div>
            </div>
        </div>
    </div>
</div>