import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message-download',
  templateUrl: './message-download.component.html',
  styleUrls: ['./message-download.component.css']
})
export class MessageDownloadComponent implements OnInit {

  @Output() emmiter = new EventEmitter<boolean>();
  showMessage: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  setShow(change: boolean){
    this.showMessage = change;
    this.emmiter.emit(this.showMessage);
  }
}