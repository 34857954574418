<div
    (click)="changePage()"
    class="card shadow clear-card-outline" 
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5 class="card-title"> {{selectedPeriodtext | translate}} </h5>
        <ng-container *ngIf="noData">
            <img 
                class="img-fluid" 
                src="assets/images/empty_cards/card-summary-categories.png"
                >
        </ng-container>
        <ng-container *ngIf="!noData">
            <div class="row">
                <div class="col-12 no-padding text-end text-right " style="height: 220px; padding-left: 6px;">
                    <ngx-charts-pie-chart
                        [customColors]="categoriesBalances?.customColor"
                        [results]="categoriesBalances?.chartData"
                        [doughnut]="true"
                        [arcWidth]=".45"
                        >
                    </ngx-charts-pie-chart>
                </div>
                <div class="offset-4 col-4 mb-2">
                    <h6 class="text-center" style="margin-top: -120px;">
                        {{ categoriesBalances?.total | number: '1.2-2':'en-US' }}
                    </h6>
                    <p class="text-center" style="margin-top: -10px; font-size: 12px;">
                        {{ preferences.currency?.isoCode }}
                    </p>
                </div>
            </div>
            <div class="container mt-1">
                <ng-container *ngFor="let category of categoriesBalances?.categoriesBalances">
                    <ng-container *ngIf="category.total > 0">
                        <div class="row no-padding">
                            <div class="col-6 text-left no-padding">
                                <p class="no-margin no-padding" style="font-size: 18px;">
                                    <span 
                                        class="badge badge-pill white-text text-truncate" 
                                        style="max-width: 130%;"
                                        [ngStyle]="{'background-color': '#'+category.color}">
                                        <img
                                            class="filter-white pr-1" 
                                            src="assets/images/icon-cat/{{category.icon}}.svg"
                                            width="20"
                                            >{{ category.name }}
                                    </span>
                                </p>
                            </div>
                            <div class="col-6 text-end text-right  no-padding">
                                <p class="no-margin">
                                    {{category.total | currencyFormat}} 
                                    <i class="fa fa-minus" aria-hidden="true"></i>
                                </p>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>