import { Injectable } from '@angular/core';

import currencies from '../../json/GD4-currencies.json'

import { CrudService } from '../../services/crud.service';
import { ModelCurrency } from '../../models/currency.model';
import { CurrencyResponse } from '../../pages/options/interface/currency-format.interface';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {

  private _currencies:ModelCurrency[] = [];
  
  public get currencies():ModelCurrency[] {
    return [...this._currencies];
  }

  constructor(private services:CrudService<CurrencyResponse>) { }

  getCurrencies(currenciesUser : ModelCurrency[]) {
    this._currencies = [];
    currencies.forEach(currency => {
      this._currencies.push(new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol));
    });

    if (currenciesUser != undefined && currenciesUser != null) {
      currenciesUser.forEach(currency => {
        this._currencies.push(new ModelCurrency(+currency.pkCurrency, currency.isoCode, currency.symbol));
      })
    }

    /* 
    let params = {
      'get' : 'tableDataWithArgument',
      'table' : 'table_currencies',
      'column': 'fk_user',
      'arg' : this.services.userData?.user_id
    }
    return new Promise((resolve, reject) => {
      this.services.get(params).subscribe(response => {
        if (response.status == '1') {
          if (response.found == 1) {
            response.data.forEach(currency => {
              this._currencies.push(new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol))
            });
          }
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    }); */
  }

  addCurrency(values : any) {
    let params = values;
    params['get'] = 'currency';
    params['fk_user'] = this.services.userData?.user_id;
    return new Promise((resolve, reject) => {
      this.services.create(params).subscribe(response => {
        if (response.status == '1') {
          let currency = response.data[0];
          this._currencies.push(new ModelCurrency(+currency.pk_currency, currency.iso_code, currency.symbol));
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

}