import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModelCategories } from '../../../pages/categories/model/categories.model';
import { ModelSubCategories } from '../../../pages/categories/model/subcategories.model';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-selector-categories',
  templateUrl: './selector-categories.component.html',
  styleUrls: ['./selector-categories.component.css']
})
export class SelectorCategoriesComponent implements OnInit {
  @Input() isStyleMain : boolean = true;
  @Input() categories : ModelCategories[] = [];
  @Input() showAllCategories: boolean = false;
  @Input() categorySelected : ModelCategories | null = null;
  @Input() subcategorySelected : ModelSubCategories | null = null;
  @Input() showSubcategories: boolean = true;

  @Output() setCategorySelected = new EventEmitter<ModelCategories> ();
  @Output() setSubcategorySelected = new EventEmitter<ModelSubCategories>();

  hideSubcategoriesSelector: boolean = this.categorySelected != null ? this.categorySelected.subcategories.length == 0 : false;

  select_pk_category : number = 0;
  select_pk_subcategory : number = 0;

  ls = LocalStorageManager;


  constructor() { }

  ngOnInit(): void {
    this.categories?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  ngOnChanges(): void {
    this.select_pk_category = this.categorySelected ? this.categorySelected.id : 0;
    this.select_pk_subcategory = this.subcategorySelected ? this.subcategorySelected.id : 0;
    this.hideSubcategoriesSelector = this.categorySelected != null ? this.categorySelected.subcategories.length == 0 : false;
  }
  
  getColor() {
    let color = this.categorySelected? this.categorySelected.color : this.ls.isDarkmode ? "#575f68" : "#ffffff";
    return color.includes('#') ? color : '#'+color;
  }

  setCategory(pk_category : number) {
    this.select_pk_category = pk_category;
    if (this.showAllCategories && pk_category == 0) {
      this.categorySelected = null;
      this.subcategorySelected = null;
    } else {
      this.categorySelected = this.categories.find(category => category.id == pk_category)!;
      if (this.categorySelected && this.categorySelected.subcategories.length == 0) {
        this.subcategorySelected = null;
      }
      let haveSubcategories = this.haveSubcategories();
      this.hideSubcategoriesSelector = this.categorySelected ? !haveSubcategories : true;
    }
    this.setCategorySelected.emit(this.categorySelected!);
    this.setSubcategory(0);
  }

  setSubcategory(pk_subcategory: number) {
    if (pk_subcategory == 0) {
      this.subcategorySelected = null; 
    } else {
      let subcategory : any = this.categorySelected?.subcategories.find(subcategory => subcategory.id == pk_subcategory);
      this.subcategorySelected = subcategory;
    }
    this.setSubcategorySelected.emit(this.subcategorySelected!);
  }

  getSubcategories(){
    return this.categorySelected?.subcategories.filter(sub => sub.deleted == 0);
  }

  haveSubcategories() {
    return this.categorySelected!.subcategories.filter(sub =>  sub.deleted == 0).length > 0;
  }
}