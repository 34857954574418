import { ModelMovements } from '../../../../pages/movements/model/movements.model';
import * as moment from 'moment';
import { FortnightModel } from '../../../../models/biweekly.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { PeriodType } from '../../../common-components/selector-period/interfaces/period.interface';
import { WeekModel } from '../../../../models/week.model';


export class MonthlyBalanceModel {

    movements: ModelMovements [] = [];
    incomeSum: number = 0;
    expenseSum: number = 0;
    total: number = 0;
    date: moment.Moment;
    date2: moment.Moment;

    period: PeriodType;
    week: WeekModel;
    fortnight: FortnightModel;
    accounts: ModelAccounts[] = [];
    
    chartData : any = [];

    constructor(movements: ModelMovements[], date: moment.Moment, period: PeriodType, week:WeekModel, fortnight: FortnightModel,
                date2: moment.Moment, accounts: ModelAccounts[]) {
        this.movements = movements.filter(m => accounts.map(a => a.pkAccount).includes(m.fkAccount!));
        this.date = date;
        this.period = period;
        this.week = week;
        this.fortnight = fortnight;
        this.date2 = date2;
        this.accounts = accounts;
        this.setData();
    }

    setData() {
        switch (this.period) {
            case PeriodType.daily: this.setDailyBalances(); break;
            case PeriodType.weekly: this.setWeeklyBalances(); break;
            case PeriodType.fortnightly: this.setFortnightlyBalances(); break;
            case PeriodType.monthly: this.setMonthlyBalances(); break;
            case PeriodType.annually: this.setAnnuallyBalances(); break;
            case PeriodType.byDates: this.setBalancesByDates(); break;
            default: break;
        }

        this.chartData = [
            { name: 'Ingreso', value: this.incomeSum },
            { name: 'Gasto', value: Math.abs(this.expenseSum) },
            { name: 'Total', value: Math.abs(this.total) },
        ];
    }

    setDailyBalances() {
        this.movements.forEach(movement => {
            const date = moment(this.date).format('MM-DD-YYYY');
            this.expenseSum += (movement.sign == '-' && moment(movement.date).format('MM-DD-YYYY') == date) ? movement.amount! : 0;
            this.incomeSum += (movement.sign == '+' && moment(movement.date).format('MM-DD-YYYY') == date) ? movement.amount! : 0;
        });
        this.total = this.incomeSum - this.expenseSum;
    }
    
    setWeeklyBalances() {
        this.movements.forEach(movement => {
            const date = moment(movement.date);
            if ((date.year() == this.week.year) && ((date.week() -1) == this.week.weekNumber)) {
                this.expenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.incomeSum += (movement.sign == '+') ? movement.amount! : 0;
            }
        });
        this.total = this.incomeSum - this.expenseSum;
    }

    setFortnightlyBalances() {
        let initialDate = moment(this.fortnight.year+"-"+this.fortnight.doubleDigit(this.fortnight.month.number)+"-"+this.fortnight.doubleDigit(this.fortnight.initialDay));
        let finishDate = moment(this.fortnight.year+"-"+this.fortnight.doubleDigit(this.fortnight.month.number)+"-"+this.fortnight.doubleDigit(this.fortnight.finalDay));
        this.movements.forEach(movement => {
            let date = moment(movement.date);
            if (date >= initialDate && date <= finishDate) {
               this.expenseSum += (movement.sign == '-') ? movement.amount! : 0;
               this.incomeSum += (movement.sign == '+') ? movement.amount! : 0;
            }
        });
        this.total = this.incomeSum - this.expenseSum;
    }

    setMonthlyBalances() {
        const date = moment(this.date).format('MM-YYYY');
        this.movements.forEach(movement => {
            let dateMovement = moment(movement.date).format('MM-yyyy');
            if (date === dateMovement) {
                this.expenseSum += movement.sign == '-' ? movement.amount! : 0;
                this.incomeSum += movement.sign == '+' ? movement.amount! : 0;
            }
        });
        this.total = this.incomeSum - this.expenseSum;
    }

    setAnnuallyBalances() {
        const date = moment(this.date).format('yyyy');
        this.movements.forEach(movement => {
            let dateMovement = moment(movement.date).format('yyyy')
            if (date ===  dateMovement) {
                this.expenseSum += movement.sign == '-' ? movement.amount! : 0;
                this.incomeSum += movement.sign == '+' ? movement.amount! : 0;
            }
        });
        this.total = this.incomeSum - this.expenseSum;
    }

    setBalancesByDates() {
        this.movements.forEach(movement => {
            let date = moment(movement.date);
            if (date >= moment(this.date) && date <= moment(this.date2)) {
                this.expenseSum += movement.sign == '-' ? movement.amount! : 0;
                this.incomeSum += movement.sign == '+' ? movement.amount! : 0;
            }
        });
        this.total = this.incomeSum - this.expenseSum;
    }
}