import * as moment from 'moment';

import { ModelAccounts } from '../../accounts/models/accounts.model';
import { ModelCategories } from '../../categories/model/categories.model';
import { ModelMovements } from '../../movements/model/movements.model';
import { FortnightsByYear } from '../../../models/biweekly.model';
import { WeekModel } from '../../../models/week.model';
import { ModelSubCategories } from '../../categories/model/subcategories.model';
import { PeriodType } from 'src/app/components/common-components/selector-period/interfaces/period.interface';

export class ModelBudgets {

    pkBudget: number;
    amount: number;
    period: number;
    number: number;
    year: number;
    onlyOnce: number;
    shown: number;
    showHome: number;
    fkAccount?: number;
    fkCategory?: number;
    fkSubcategory?: number;
    fkSubscription: number;
    date: string;
    account?: ModelAccounts | null;
    category?: ModelCategories | null;
    subcategory?: ModelSubCategories | null;
    allMovements: ModelMovements[] = [];
    movements: ModelMovements[] = [];
    clickBudget: boolean = false;
  

  constructor(pkBudget: number, amount: number, period: number, number: number, year: number, onlyOnce: number, shown: number,
              showHome: number, fkAccount: number, fkCategory: number, fkSubcategory: number, fkSubscription: number,
              movements: ModelMovements[] = []) {

      this.pkBudget = pkBudget;
      this.amount = amount;
      this.period = period;
      this.number = number;
      this.year = year;
      this.onlyOnce = onlyOnce;
      this.shown = shown;
      this.showHome = showHome;
      this.fkAccount = fkAccount;
      this.fkCategory = fkCategory;
      this.fkSubcategory = fkSubcategory;
      this.fkSubscription = fkSubscription;
      this.allMovements = movements;
      this.date = this.getDate();

  }

  getMovementsSum(){
    let total = 0;
    this.movements.forEach(movement => {
      if (movement.sign === '-') {
        total = +total + +movement.amount!;
      }
    });
    return total;
  }

  getPercentage(): number{
    return +((this.getMovementsSum() * 100) / +this.amount).toFixed(2);
  }

  private getDate(): any {
    switch ((this.period * 1)) {
      case PeriodType.daily:
        return this.number + '-' + this.year;
      case PeriodType.weekly:
        const weeks = new WeekModel(this.number, this.year);
        return weeks.completeDate;
      case PeriodType.fortnightly:
        const fortnights = new FortnightsByYear(this.year);
        const fortnight = fortnights.fortnights.find(fort => fort.fortnightNumber == this.number)!;
        return fortnight.completeDate;
    case PeriodType.monthly:
        return moment(this.number + '-01-' + this.year).format('YYYY-MM-DD');
    case PeriodType.annually:
        return this.year.toString();
      default:
        break;
    }
  }

}
