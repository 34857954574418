
<!--<div *ngIf="loading" class="center-screen">
    <app-loading-view></app-loading-view>
</div>-->

<div 
    *ngIf="!loading"
    class="animate__animated animate__fadeIn" 
    >
    <div class="container mt-2">
        <div class="row mt-4">
            <!-- COMPONENT ACCOUNTS -->
            <div class="col-5 col-lg-6 col-xl-2">
                <app-selector-multi-account
                    *ngIf="!isDragEnabled"
                    showSelectAll="true"
                    [accounts]="accounts"
                    (emmitAccountsSelected)="setSelectedAccounts($event)"
                    >
                </app-selector-multi-account>
                <h6
                    *ngIf="isDragEnabled"
                    class="pointer"
                    [ngClass]="{'white-text': isDarkmode}"
                    (click)="getData()"
                    >{{ "buttons.button_cancel" | translate}}
                </h6>
            </div>
            <!-- COMPONENT PERIOD -->
            <div class="offset-1 offset-lg-0 offset-xl-0 col-5 col-lg-6 col-xl-2">
                <app-selector-period
                    *ngIf="!isDragEnabled"
                    [selectedPeriod]="selectedPeriod.period"
                    (emitter)="changePeriod($event)"
                    >
                </app-selector-period>
            </div>
            <!-- COMPONENT DATE -->
            <div
                class="col-12 col-xl-4"
                >
                <app-selector-date-by-periods
                    [selectorComponet]="selectorDateType.home"
                    [currentDate]="date"
                    [secondCurrentDate]="secondDate"
                    [selectedPeriod]="selectedPeriod.period"
                    [options]="calendarOptions"
                    [options2]="options2"
                    (eventEmitter)="setDate($event)"
                    (eventEmitter2)="setDate2($event)"
                    [orientation] = "axis.vertical"
                    [typePicker]="typeDate"
                    >
                </app-selector-date-by-periods>
            </div>
            <!-- COMPONENT EDIT VIEWS -->
            <div
                class="offset-6 offset-md-8 offset-lg-9 offset-xl-1 col-12 col-lg-6 col-xl-2 pointer"
                >
                <h6
                    (click)="isDragEnabled ? updateCards() : toggleDragDropMode()"
                    style="font-size: 18px; border-radius: 10px;"
                    class="no-margin p-2 text-center"
                    [ngClass]="{'white-text': isDarkmode, 'bg-dark-input' : isDarkmode, 'bg-white' : !isDarkmode}"
                    >
                    {{ (isDragEnabled ? 'buttons.button_save' : 'title_cardviews.card_edit_views') | translate}}
                </h6>
            </div>
        </div>
    </div>
    <div class="container scrollable mt-1 mb-4">
        <ul
            id="list"
            class="uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s"
            uk-sortable="handle: .uk-sortable-handle"
            uk-grid="masonry: true"
            >
            <ng-container
                *ngFor="let card of cards"
                >
                <li
                    class="col-12 col-sm-6 col-lg-4"
                    *ngIf="card.type != cardsTypes.buttons && (!card.hidden || isDragEnabled)"
                    [value]="card.id"
                    >
                    <div class="uk-card">
                        <div
                            *ngIf="isDragEnabled"
                            class="uk-sortable-handle over-right animate__animated animate__fadeIn col-12"
                            >
                            <div class="row mr-2">
                                <label class="switch mt-2 mr-4">
                                    <input
                                        type="checkbox"
                                        [checked]="!card.hidden"
                                        (change)="toggleCardHidden(card)"
                                        >
                                    <span class="slider round"></span>
                                </label>
                                <i
                                    class="fas fa-align-justify fa-2x"
                                    [ngClass]="{'filter-white': isDarkmode}"
                                    >
                                </i>
                            </div>
                        </div>
                        <div
                            [ngClass]="{'card-disabled': isDragEnabled}"
                            >
                            <ng-container
                                *ngComponentOutlet="card.component"
                                >
                            </ng-container>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
   <app-not-have-data
        *ngIf="notHaveData"
        [message]="message"
        >
   </app-not-have-data>
</div>