import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { FormBuilder } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recover-password',
  templateUrl: './modal-recover-password.component.html',
  styleUrls: ['./modal-recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {
  @Input() token!: string;
  @Input() pkUserPassword!: string;
  @Input() code!: number;
  @Input() emailPassword!: string;
  @Output() onEventEmitted = new EventEmitter<boolean>();

  isDarkmode = LocalStorageManager.isDarkmode;

  hasError: boolean = false;
  error: string = "";
  

  form = this.fb.group({
    passwordCode: [''],
    password: [''],
    confirmPassword: [''],
    email : ['']
  });

  constructor(private fb: FormBuilder,  private services: LoginService, private translate: TranslateService) { 
    this.loadTranslate();
  }

  loadTranslate(){
    let lang = navigator.language
    if (lang == "es-419") {
      lang = "es";
    }
    this.translate.use(lang);
  }

  ngOnInit(): void {}

  changePassword() {
    this.hasError = false;
    this.form.value['email'] = this.emailPassword;
    let password : any = this.form.value['password'];
    let confirmPassword : any = this.form.value['confirmPassword'];
    let passwordCode : any = this.form.value['passwordCode'];
    this.validations(password, confirmPassword, passwordCode);
    if (this.hasError) {
      return;
    }
    this.services.changePassword(this.form.value, this.token, this.pkUserPassword, this.emailPassword).then(_ => {
      this.hasError = false;
      this.error = "";
      document.getElementById('button-close-dialog2')?.click();
      this.onEventEmitted.emit(true)
    }, reject => {
      console.log(reject);
      this.hasError = true;
      this.error = reject;
    });
  }

  validations(password: string, confirmPassword: string, passwordCode: number){
    if (password.length < 8) {
      this.hasError = true;
      this.error = "login_messages.message_error_password";
    }else if (passwordCode != this.code || this.code == 0) {
      this.hasError = true;
      this.error = "password_section.message_wrong_code";
    }else if (password != confirmPassword) {
      this.hasError = true;
      this.error = "password_section.password_not_equals";
    }
  }
}