

export class CardsModel {

    pkUserCard: number;
    shown: number;
    orderCard: number;
    serverDate: string;
    fkPreference: number;
    fkCard: number;

    constructor(pkUserCard: number, shown: number, orderCard: number, serverDate: string, fkPreference: number, fkCard: number) {

        this.pkUserCard = pkUserCard;
        this.shown = shown;
        this.orderCard = orderCard;
        this.serverDate = serverDate;
        this.fkPreference = fkPreference;
        this.fkCard = fkCard;

    }

}
