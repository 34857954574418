<div class="modal-header">
    <h4
        ngClass="{{ls.isDarkmode ? 'text-body-color-discreet' : 'text-title-color-discreet'}}"
        class="modal-title offset-1 col-10 text-center"
        >{{ translateBoolean ? (title | translate) : title }}
    </h4>
    <img
        [id]="closeButton"
        [ngClass]="{'filter-white' : ls.isDarkmode }" 
        class="mt-2 img-close"
        src="assets/images/icon-close.svg"
        height="20px"
        width="20px"
        aria-label="Close" 
        (click)="clickToClose()"
        >
        <!-- data-bs-dismiss="modal" -->
</div>