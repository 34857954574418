<div class="row">
    <div class="col-6 offset-3">
        <footer
            class="footer container text-center animate__animated animate__backInUp shadow"
            [ngClass]="{'bg-dark white-text': isDarkmode}"
            >
            <button
                class="btn btn-primary btn-lg btn-circle mb-1 center-icon center"
                [ngStyle]="{'background-color' : color}"
                data-bs-toggle="modal"
                [attr.data-bs-target]="'#'+modalId"
                >
                <i
                    class="fa fa-plus fa-2x"
                    aria-hidden="true"
                    (click)="buttonPressed()"
                >
                </i>
            </button>
            <div>
                <h5
                    *ngIf="title"
                    [ngClass]="{'white-text': isDarkmode}"
                    >{{(title | translate) | currencyFormat }}
                </h5>
                <p
                    *ngIf="subtitle"
                    class="gray"
                    [ngClass]="{'white-text': isDarkmode}"
                    >{{ (subtitle | translate) + (showIsoCode ? preference.currency?.isoCode : "") }}
                </p>
            </div>
        </footer>
    </div>
</div>
<!-- (click)="editing = false; categorySelected = null; selectedAccount1 = null; selectedAccount2 = null; resetMap()" -->
