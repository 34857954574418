import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { PreferencesModel } from '../../../../models/preferences.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-add-currency',
  templateUrl: './modal-add-currency.component.html',
  styleUrls: ['./modal-add-currency.component.css']
})
export class ModalAddCurrencyComponent implements OnInit {

  @Output() onClose = new EventEmitter<boolean>();

  ls = LocalStorageManager;

  isoCode : string = "";
  symbol : string = "";

  constructor(private servies: CurrenciesService, private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
  }

  saveCurrency() {
    let values = {'iso_code' : this.isoCode, 'symbol' : this.symbol}
    this.servies.addCurrency(values).then(_ => {
      PreferencesModel.shared.currencies = this.servies.currencies;
      document.getElementById('closeButtonAddCurrency')?.click();
    }, reject => {
      console.log(reject);
    });
  }

  closeModal() {
    this.onClose.emit(true);
  }
}