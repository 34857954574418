<div
    (click)="changePage()"
    class="animate__animated animate__fadeIn" 
    *ngIf="!loading"
    >
    <div 
        class="card shadow semi-rounded"  
        [ngClass]="{'bg-dark white-text': isDarkmode}"
        >
        <div class="card-body">
            <h5 class="card-title">{{ "title_cardviews.card_last_ten_movements" | translate }}</h5>
            <div class="container">
                <ng-container *ngIf="noData">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <img 
                                    class="img-fluid" 
                                    src="assets/images/empty_cards/card-last-ten-days.png"
                                    >
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let movement of movements">
                    <div class="row mb-2" *ngIf="movement.category">
                        <div class="col-2 clear-padding">
                            <img 
                                [class]="movement.category.webFilter" 
                                src="assets/images/icon-cat/{{movement.category.icon}}.svg" 
                                width="30"
                                style="padding: 4px;"
                                >
                        </div>
                        <div class="col-5 clear-padding">
                            <div class="row">
                                <div class="col-12 clear-padding" style="margin-bottom: -4px;">
                                    {{movement.category.name}}
                                    <ng-container *ngIf="movement.subcategory">
                                        - {{movement.subcategory?.name}}
                                    </ng-container>
                                </div>
                                <div
                                    class="col-12 clear-padding font-secondary"
                                    style="font-size: 12px;"
                                    >{{ movement.detail }}
                                </div>
                            </div>
                        </div>
                        <div class="col-5 clear-padding">
                            <div class="text-end text-right ">
                                <p *ngIf="movement.sign == '-'">
                                    {{ movement.amount | currencyFormat }}
                                    <i class="fa fa-minus text-end text-right " aria-hidden="true"></i>
                                </p>
                                <p *ngIf="movement.sign == '+'">
                                    {{ movement.amount | currencyFormat }}
                                    <i class="fa fa-plus text-end text-right " aria-hidden="true"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>