import { ModelBudgets } from './budgets.model';
import * as moment from 'moment';

import { ModelAccounts } from '../../accounts/models/accounts.model';
import { ModelCategories } from '../../categories/model/categories.model';
import { FortnightModel } from '../../../models/biweekly.model';
import { WeekModel } from '../../../models/week.model';
import { ModelSubCategories } from '../../categories/model/subcategories.model';
import { PeriodType } from 'src/app/components/common-components/selector-period/interfaces/period.interface';


export class BudgetControlModel {

    budgets: ModelBudgets[] | any;
    date: moment.Moment;
    budgetsControlled: ModelBudgets[] = [];
    currentBudget: ModelBudgets | any;
    account: ModelAccounts  | null;
    category: ModelCategories  | null;
    subcategory: ModelSubCategories  | null;
    selectedPeriod: PeriodType;
    month: string;
    year: string;
    week: number;
    fortnight: FortnightModel;
    previousBudgets: ModelBudgets[] = [];
    followingBudgets: ModelBudgets[] = [];

    constructor(account: ModelAccounts | null, category: ModelCategories | null, subcategory: ModelSubCategories | null,
                budgets: ModelBudgets[] | null, date: moment.Moment, selectedPeriod: PeriodType,
                week: number, fortnight: FortnightModel) {

        this.budgets = budgets;
        this.date = date;
        this.selectedPeriod = selectedPeriod;
        this.account = account;
        this.category = category;
        this.subcategory = subcategory;
        this.week = week;
        this.fortnight = fortnight;
        this.month = moment(this.date).format('M');
        this.year = moment(this.date).format('YYYY');
        this.setupData();

    }

    setupData() {
        switch (this.selectedPeriod) {
            case PeriodType.daily: this.setupDailyBudgets(); break;
            case PeriodType.weekly: this.setupWeeklyBudgets(); break;
            case PeriodType.fortnightly: this.setupFortnightlyBudgets(); break;
            case PeriodType.monthly: this.setupMonthlyBudgets(); break;
            case PeriodType.annually: this.setupAnnuallyBudgets(); break;
            default:
                break;
        }
    }

    setupDailyBudgets() {
        const currentDate = moment(this.date).format('MM-DD-YYYY');
        this.budgets.forEach((budget : any) => {
            budget.movements = budget.allMovements.filter( (movement : any) => {
                return moment(movement.date).format('MM-DD-YYYY') == currentDate;
            });
            this.budgetsControlled.push( budget );
        });
        const resultDay = this.budgetsControlled.find(budget =>
            budget.date == moment(this.date).format('DDDD-YYYY') && budget.onlyOnce == 1);
        if (resultDay != null) {
            this.currentBudget = resultDay;
        } else {
            const filteredBudgetsControlled = this.budgetsControlled.filter(budget => budget.onlyOnce != 1);
            for (let index = filteredBudgetsControlled.length - 1; index >= 0; index--) {
                if (filteredBudgetsControlled[index].date > moment(this.date).format('DDDD-YYYY')
                && filteredBudgetsControlled[index].onlyOnce != 1) {
                    filteredBudgetsControlled.splice(index, 1);
                }
            }
            if (filteredBudgetsControlled.length == 0) {
                this.budgetsControlled.sort((a, b) => ( a.date  > b.date ? 1
                : ( ( b.date > a.date ) ) ? -1 : 0 ));
                this.currentBudget = this.budgetsControlled[0];
            } else {
                filteredBudgetsControlled.sort((a, b) => ( a.date  > b.date ? -1
                : ( ( b.date > a.date ) ) ? 1 : 0 ));
                this.currentBudget = filteredBudgetsControlled[0];
            }
        }
    }

    setupWeeklyBudgets() {
        const week = new WeekModel(this.week, +moment(this.date).format('YYYY'));
        this.budgets.forEach((budget : any) => {
            budget.movements = budget.allMovements.filter( (movement : any) =>
                moment(movement.date).format('YYYY-MM-DD') >= week.weekStart &&
                moment(movement.date).format('YYYY-MM-DD') <= week.weekEnd);
            this.budgetsControlled.push(budget);
        });

        const resultWeek = this.budgetsControlled.find(budget =>
            budget.date == this.week + '-' + +moment(this.date).format('W-YYYY') && budget.onlyOnce == 1);
        if (resultWeek != null) {
            this.currentBudget = resultWeek;
        } else {
            const filteredBudgetsControlled = this.budgetsControlled.filter(budget => budget.onlyOnce != 1);
            for (let index = filteredBudgetsControlled.length - 1; index >= 0; index--) {
                if (moment(filteredBudgetsControlled[index].date) > moment(week.completeDate)
                && filteredBudgetsControlled[index].onlyOnce != 1) {
                    filteredBudgetsControlled.splice(index, 1);
                }
            }
            if (filteredBudgetsControlled.length == 0) {
                this.budgetsControlled.sort((a, b) => ( a.date > b.date ? 1
                : ( ( b.date > a.date ) ) ? -1 : 0 ));
                this.currentBudget = this.budgetsControlled[0];
            } else {
                filteredBudgetsControlled.sort((a, b) => ( a.date > b.date ? -1
                : ( ( b.date > a.date ) ) ? 1 : 0 ));
                this.currentBudget = filteredBudgetsControlled[0];
            }
        }
    }

    setupFortnightlyBudgets() {
        this.budgets.forEach((budget : any) => {
            budget.movements = budget.allMovements.filter( (movement : any) => {
                let date = moment(movement.date);
                return (date.date() >= this.fortnight.initialDay && 
                date.date() <= this.fortnight.finalDay && 
                date.year() == this.fortnight.year && 
                (date.month() + 1) == this.fortnight.month.number)
            });
            this.budgetsControlled.push(budget);
        });
        const resultFort = this.budgetsControlled.find(budget =>
            budget.number == this.fortnight.fortnightNumber && budget.year == this.fortnight.year  && budget.onlyOnce == 1);
        if (resultFort != null) {
            this.currentBudget = resultFort;
        } else {
            const filteredBudgetsControlled = this.budgetsControlled.filter(budget => budget.onlyOnce != 1);
            for (let index = filteredBudgetsControlled.length - 1; index >= 0; index--) {
                if (moment(filteredBudgetsControlled[index].date).format('YYYY-MM-DD') >= this.fortnight.completeDate
                && filteredBudgetsControlled[index].onlyOnce != 1) {
                    filteredBudgetsControlled.splice(index, 1);
                }
            }
            if (filteredBudgetsControlled.length == 0) {
                this.budgetsControlled = this.budgetsControlled.filter(budget => budget.onlyOnce != 1);
                this.budgetsControlled.sort((a, b) => ( a.date > b.date ? 1
                : ( ( b.date > a.date ) ) ? -1 : 0 ));
                this.currentBudget = this.budgetsControlled[0];
            } else {
                filteredBudgetsControlled.sort((a, b) => ( a.date > b.date ? -1
                : ( ( b.date > a.date ) ) ? 1 : 0 ));
                this.currentBudget = filteredBudgetsControlled[0];
            }
        }
    }

    setupMonthlyBudgets() {
        const firstDay = moment(this.date).startOf('month');
        const lastDay = moment(this.date).endOf('month');
        this.budgets.forEach((budget : any) => {
            budget.movements = budget.allMovements.filter( (movement : any) => moment(movement.date) >= firstDay
                                                    && moment(movement.date) <= lastDay);
                                                    
            this.budgetsControlled.push(budget);
        });
        this.previousBudgets = this.budgetsControlled.filter(budget =>
            moment(budget.date).format('YYYY-MM') < moment(this.date).format('YYYY-MM')
        );
        this.followingBudgets = this.budgetsControlled.filter(budget =>
            moment(budget.date).format('YYYY-MM') > moment(this.date).format('YYYY-MM')
        );
        const result = this.budgetsControlled.find(budget =>
            moment(budget.date).format('MM-YYYY') == moment(this.date).format('MM-YYYY') && budget.onlyOnce == 1);
        if (result != null) {
            this.currentBudget = result;
        } else {
            const filteredBudgetsControlled = this.budgetsControlled.filter(budget => budget.onlyOnce != 1);
            for (let index = filteredBudgetsControlled.length - 1; index >= 0; index--) {
                if (filteredBudgetsControlled[index].date > this.date.format('YYYY-MM-DD') && filteredBudgetsControlled[index].onlyOnce != 1) {
                    filteredBudgetsControlled.splice(index, 1);
                }
            }
            if (filteredBudgetsControlled.length == 0) {
                this.budgetsControlled.sort((a, b) => ( moment(a.date).toDate() > moment(b.date).toDate() ? 1
                : ( ( moment(b.date).toDate() > moment(a.date).toDate() ) ) ? -1 : 0 ));
                this.currentBudget = this.budgetsControlled[0];
            } else {
                filteredBudgetsControlled.sort((a, b) => ( moment(a.date).toDate() > moment(b.date).toDate() ? -1
                : ( ( moment(b.date).toDate() > moment(a.date).toDate() ) ) ? 1 : 0 ));
                this.currentBudget = filteredBudgetsControlled[0];
            }
        }
    }

    setupAnnuallyBudgets() {
        const currentDate = moment(this.date).format('YYYY');
        this.budgets.forEach((budget : any) => {
            budget.movements = budget.allMovements.filter((m : any) => moment(m.date).format('YYYY') == currentDate);
            this.budgetsControlled.push( budget );
        });
        const resultYear = this.budgetsControlled.find(budget =>
            moment(budget.date).format('YYYY') == currentDate && budget.onlyOnce == 1);
        if (resultYear != null) {
            this.currentBudget = resultYear;
        } else {
            const filteredBudgetsControlled = this.budgetsControlled.filter(budget => budget.onlyOnce != 1);
            for (let index = filteredBudgetsControlled.length - 1; index >= 0; index--) {
                if (moment(filteredBudgetsControlled[index].date).format('YYYY') > currentDate
                && filteredBudgetsControlled[index].onlyOnce != 1) {
                    filteredBudgetsControlled.splice(index, 1);
                }
            }
            if (filteredBudgetsControlled.length == 0) {
                this.budgetsControlled.sort((a, b) => ( moment(a.date).format('YYYY') > moment(b.date).format('YYYY') ? 1
                : ( ( moment(b.date).format('YYYY') > moment(a.date).format('YYYY') ) ) ? -1 : 0 ));
                this.currentBudget = this.budgetsControlled[0];
            } else {
                filteredBudgetsControlled.sort((a, b) => ( moment(a.date).format('YYYY') > moment(b.date).format('YYYY') ? -1
                : ( ( moment(b.date).format('YYYY') > moment(a.date).format('YYYY') ) ) ? 1 : 0 ));
                this.currentBudget = filteredBudgetsControlled[0];
            }
        }
    }

}
