<div class="better-download-application" *ngIf="showMessage">
    <h4
        class="no-padding no-margin"
        (click)="setShow(false)"
        ><i
            class="col-12 fa fa-close text-end text-right"
            aria-hidden="true"
            >
        </i>
    </h4>
    <h4 class="col-11"
        >{{ ("web_messages.message_warning_download_app" | translate) }}
        <a href="#">{{ ("web_messages.play_store" | translate) }}</a>
            {{"web_messages.or" | translate}}
        <a href="#">{{ ("web_messages.app_store" | translate) }}</a>
    </h4>
<br/>
</div>