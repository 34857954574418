import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';
import { ShowHide } from '../card-currency-format/model/card-currency-format.model';
import { CardTransfersModel } from './model/card-transfer.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-transfers',
  templateUrl: './card-transfers.component.html',
  styleUrls: ['./card-transfers.component.css']
})
export class CardTransfersComponent implements OnInit {

  @Output() emitter = new EventEmitter<ShowHide>();

  localStorage = LocalStorageManager;
  preferences = PreferencesModel.shared;

  showHideItems = CardTransfersModel.showHideTransfersItems;
  showTransfers = ShowHide.show;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.showTransfers = this.preferences.transfers;
  }

  setShowTransfers(value: ShowHide) {
    this.showTransfers = value;
    this.emitter.emit(value);
  }
}
