import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { ModelAccounts } from 'src/app/pages/accounts/models/accounts.model';
import { ModelMovements } from 'src/app/pages/movements/model/movements.model';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SyncAll } from '../../../../services/sync-all.service';

@Component({
  selector: 'app-last-ten-movements',
  templateUrl: './last-ten-movements.component.html',
  styleUrls: ['./last-ten-movements.component.css'],
  providers: [ DatePipe ]

})
export class LastTenMovementsComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;
  noData = false;
  loading: boolean = true;
  
  allMovements: ModelMovements[] = [];
  preferences = PreferencesModel.shared;
  
  accounts: ModelAccounts[] = [];
  selectedAccounts: ModelAccounts[] = [];
  
  movements: ModelMovements[] = [];

  constructor(private services: SyncAll, private translate: TranslateService, private route: Router) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
    this.selectedAccounts = this.services.selectedAccounts;
    this.allMovements = this.services.movements;
  }

  ngOnInit(): void {
    this.preferences = PreferencesModel.shared;
    let filteredMovements: ModelMovements[] = [];
    if (this.allMovements.length == 0) {
      this.noData = true;
    } else {
      filteredMovements = this.allMovements.filter(movement =>
        this.selectedAccounts.find(account => account.pkAccount == movement.fkAccount) != null
      );
      filteredMovements.sort((a,b) => ( moment(a.date).toDate() > moment(b.date).toDate() ? -1
      : ((moment(b.date).toDate() > moment(a.date).toDate())) ? 1 : 0 ));
      filteredMovements.splice(10, filteredMovements.length - 10);
      this.movements = filteredMovements;
    }
    this.loading = false;
  }

  changePage() {
    this.route.navigate(['Movements']);
  }
}