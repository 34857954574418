import { Component, OnInit } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-modal-about',
  templateUrl: './modal-about.component.html',
  styleUrls: ['./modal-about.component.css']
})
export class ModalAboutComponent implements OnInit {
  
  ls = LocalStorageManager;
  type_subscription: number = 0;

  constructor() {
    this.type_subscription = +sessionStorage.getItem('type_subscription')!;
  }

  ngOnInit(): void {    
  }
  ngOnChanges(): void { }

}
