import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { ModelAccounts } from '../../../pages/accounts/models/accounts.model';
import { TranslateService } from '@ngx-translate/core';
import { SelectorAccountsService } from './services/selector-accounts.service';

@Component({
  selector: 'app-selector-multi-account',
  templateUrl: './selector-multi-account.component.html',
  styleUrls: ['./selector-multi-account.component.css']
})
export class SelectorMultiAccountComponent implements OnInit {

  @Input() accounts : ModelAccounts[] = [];
  @Input() showSelectAll : boolean = false;
  ls = LocalStorageManager;
  selectedAccountsPks : number[] = [];
  selectAll : boolean = false;
  @Output() emmitAccountsSelected = new EventEmitter<ModelAccounts[]>();

  constructor(
    private services: SelectorAccountsService,
    private translate: TranslateService
    ) {
    this.translate.use(this.ls.lang!);
  }

  ngOnChanges(): void {
    this.accounts.sort((a, b) => {
      return a.accountName.localeCompare(b.accountName);
    });
    this.getListSelectedPks();
    let accountSelecteds = this.accounts.filter(account => account.selected == 1);
    this.selectAll = this.accounts.length == accountSelecteds.length;
  }

  ngOnInit(): void {
    this.accounts.sort((a, b) => {
      return a.accountName.localeCompare(b.accountName);
    });
    this.getListSelectedPks();
    let accountSelecteds = this.accounts.filter(account => account.selected == 1);
    this.selectAll = this.accounts.length == accountSelecteds.length;
  }

  changeSelection(accountsSelections: number[]) {
    this.accounts.forEach(account => {
      account.selected = accountsSelections.includes(account.pkAccount) ? 1 : 0;
      if (account.sharedAccount) {
        account.sharedAccount.selected = accountsSelections.includes(account.pkAccount) ? true : false;
      }
    });
    let accountSelecteds = this.accounts.filter(account => account.selected == 1);
    this.selectAll = this.accounts.length == accountSelecteds.length;
    if (accountSelecteds.length == 0) {
      this.setSelectAll();
    }
    this.setServerUpdateSelectionAccounts(accountSelecteds);  
  }

  emitSelectAll(selectAll: boolean) {
    this.selectAll = selectAll;
    this.setSelectAll();
  }

  setSelectAll() {
    this.accounts.forEach(account => {
      account.selected = 1;
    });
    this.getListSelectedPks();
    const accountsIds = this.getAccountsId();
    const accountsShared = this.accounts.filter(account => (account.sharedAccount != null));
    const accountsSharedsIds = accountsShared.filter(account => this.selectedAccountsPks.includes(account.pkAccount)).map(a => a.pkAccount);
    const sharedsIds : any = [...new Set(accountsShared.map(a => a.sharedAccount).map(a => a?.fkShared))];
    this.updateServerAccounts(accountsIds, accountsSharedsIds, sharedsIds, this.accounts);
  }

  getAccountsId() {
    let id_accounts : number [] = [];
    this.accounts.forEach(account =>{
      if (this.selectedAccountsPks.includes(account.pkAccount) && !account.sharedAccount) {
        id_accounts.push(account.pkAccount);
      }
    })
    return id_accounts;
  }

  setServerUpdateSelectionAccounts(accountSelecteds: ModelAccounts[]) {
    this.getListSelectedPks();
    const accountsIds = this.getAccountsId();
    
    const accountsShared = this.accounts.filter(account => account.sharedAccount != null);
    const accountsSharedsIds = accountsShared.filter(account => this.selectedAccountsPks.includes(account.pkAccount)).map(a => a.pkAccount);
    const sharedsIds : any = [...new Set(accountsShared.map(a => a.sharedAccount).map(a => a?.fkShared))];
    this.updateServerAccounts(accountsIds, accountsSharedsIds, sharedsIds, accountSelecteds);
  }

  updateServerAccounts(accountsIds : number[], accountsSharedsIds : number[], sharedsIds : number[], accountsSelecteds: ModelAccounts[]) {
    this.services.updateAccountsSelecteds(accountsIds, accountsSharedsIds, sharedsIds).then( _ => {
      this.accounts.filter(account => this.selectedAccountsPks.includes(account.pkAccount)).forEach(account => {
        account.selected = 1
        if (account.sharedAccount) {
          account.sharedAccount.selected = true
        }
      });
      this.emmitAccountsSelected.emit(accountsSelecteds);
    }, reject => {
      console.log(reject);
    });
  }

  getListSelectedPks() {
    this.selectedAccountsPks = this.accounts.filter(account => account.selected == 1).map(account => account.pkAccount);
  }

  isSelected(account : ModelAccounts) {
    return account.selected == 1 ? true : account.sharedAccount ? account.sharedAccount.selected  : false;
  }

  isShared(account : ModelAccounts) {
    return account.sharedAccount ? true : false;
  }
}