import { Injectable } from '@angular/core';
import { CrudService } from '../../../../services/crud.service';
import { ResponseAccounts } from '../../../../pages/accounts/interfaces/accounts.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SelectorAccountsService {

  constructor(private services: CrudService<ResponseAccounts>) { 

  }

  updateAccountsSelecteds(accountsIds: number[], accountsSharedIds: number[] = [], sharedsIds: number[] = []) {
    const params = {
      'get' : 'accounts_selected',
      'pk_accounts' : accountsIds,
      'fk_accounts' : accountsSharedIds,
      'fk_shareds' : sharedsIds,
      'fk_subscription' : this.services.userData?.subscription_id
    }
    return new Promise((resolve, reject) => {
      this.services.update(params).subscribe(response => {
        if (response.status == '1') {
          resolve(true);
        } else {
          reject(response.message);
        }
      });
    });
  }

}
