import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.css']
})
export class ModalMessageComponent implements OnInit {

  @Input() title: string = "titles.title_attention"
  @Input() bodyText: string = "contact_section.contact_body_2"

  isDarkmode = LocalStorageManager.isDarkmode;

  constructor(private translate: TranslateService) { 
    this.loadTranslate();
  }

  loadTranslate(){
    let lang = navigator.language
    if (lang == "es-419") {
      lang = "es";
    }
    this.translate.use(lang);
  }
    

  ngOnInit(): void {
  }

}
