import { Component, OnInit } from '@angular/core';

import { PeriodBalancePreviousModel } from 'src/app/components/cards/card-previous-balances/model/PeriodBalancePrevious.model';
import { FortnightModel } from '../../../../models/biweekly.model';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { PeriodType } from '../../../common-components/selector-period/interfaces/period.interface';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { ModelMovements } from '../../../../pages/movements/model/movements.model';
import { WeekModel } from '../../../../models/week.model';
import { TranslateService } from '@ngx-translate/core';
import { CalendarModel } from '../../../../models/calendar.model';
import { Router } from '@angular/router';
import { SyncAll } from '../../../../services/sync-all.service';

@Component({
  selector: 'app-monthly-balance-previous-balance',
  templateUrl: './monthly-balance-previous-balance.component.html',
  styleUrls: ['./monthly-balance-previous-balance.component.css']
})
export class MonthlyBalancePreviousBalanceComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;

  preferences = PreferencesModel.shared;

  movements: ModelMovements[] = [];

  monthtlyBalancePrevious!: PeriodBalancePreviousModel;
  date : any;
  date2 : any;

  selectedPeriodtext: string;
  selectedPeriod = PeriodType.monthly;
  week?: WeekModel;
  selectedFortnight?: FortnightModel;
  selectedAccounts: ModelAccounts[] = [];

  colorScheme = {
    domain: ['#0EB59499', '#D1359499', '#0EB59499', '#42424299']
  };

  constructor(private services: SyncAll,
      private translate: TranslateService,
      private route: Router) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
    this.selectedPeriodtext = this.services.currentPeriod.title;
    this.selectedPeriod = this.services.currentPeriod.period;
    this.selectedAccounts = this.services.selectedAccounts;
    this.movements = this.services.movements;
  }

  ngOnInit(): void {
    this.preferences = PreferencesModel.shared;
    this.processDate();
    this.getTitle()
    this.monthtlyBalancePrevious = new PeriodBalancePreviousModel(
      this.movements,
      this.date,
      this.selectedPeriod,
      this.week,
      this.selectedFortnight,
      this.date2,
      this.selectedAccounts
    );
  }

  ngOnChanges(): void {
    this.processDate();
  }

  processDate(){
    this.date = LocalStorageManager.home.getDate();    
    let calendar = new CalendarModel(this.date);
    this.week = calendar.currentWeek;
    this.selectedFortnight = calendar.currentFortnight;
    this.date2 = LocalStorageManager.home.getDate2();
  }

  getTotal() {
    let total: number;
    total = this.monthtlyBalancePrevious.previousTotal + this.monthtlyBalancePrevious.incomeSum;
    total = total - +this.monthtlyBalancePrevious.expenseSum;
    return total;
  }

  getTitle(){
    let key = "text_cardviews.";
    switch(this.services.currentPeriod.period){
      case PeriodType.daily:
        key += "card_balance_previous_by_day";
        break;
      case PeriodType.weekly:
        key += "card_balance_previous_weekly";
        break;
      case PeriodType.fortnightly:
        key += "card_balance_previous_biweekly";
        break;
      case PeriodType.monthly:
        key += "card_balance_previous_monthly";
        break;
      case PeriodType.annually:
        key += "card_balance_previous_yearly";
        break;
      case PeriodType.byDates:
        key += "card_balance_previous_date_range";
        break;
    }
    this.selectedPeriodtext = key;
  }

  changePage() {
    this.route.navigate(['Reports-by-date/reports']);
  }
}