export class ModelGoalMovement {
    pkGoalRecord: number;
    date: string;
    amount: number;
    fkGoal: number;

    click: boolean = false;
  
    constructor(pkGoalRecord: number, date: string, amount: number, fkGoal: number) {
        this.pkGoalRecord = pkGoalRecord;
        this.date = date;
        this.amount = amount;
        this.fkGoal = fkGoal;
    }
}