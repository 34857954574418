import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PreferencesModel } from 'src/app/models/preferences.model';
import { LocalStorageManager } from '../../utils/local-storage-manager/local-storage-manager.utils';
import { SettingsService } from './services/settings.service';
import { SettingsCurrencyFormat } from './interface/currency-format.interface';
import { NumberSeparator, ShowHide } from './components/cards/card-currency-format/model/card-currency-format.model';
import { SettingsDateFormat } from './interface/date-format.interface';
import { ThemeStyle } from './components/cards/card-theme/model/card-theme.model';
import { ModelCurrency } from '../../models/currency.model';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.css']
})
export class OptionsComponent implements OnInit {

  ls = LocalStorageManager;
  loading: boolean = false;
  showSaveButton: boolean = false;
  isDark = false;
  preferences = PreferencesModel.shared;
  form!: FormGroup;

  currentCurrency? : ModelCurrency = this.preferences.currency;
  currencySettings!:SettingsCurrencyFormat;
  dateSettings!:SettingsDateFormat;
  showTransfers!: ShowHide;
  theme!: ThemeStyle;

  changeCurrency : boolean = false;

  constructor(public fb: FormBuilder, private services: SettingsService, private translate: TranslateService, @Inject(DOCUMENT) private document : Document) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.loading = false;
    this.createForm();
    this.currencySettings = this.preferences.currencySettings;
    this.dateSettings = this.preferences.dateSettings;
    this.showTransfers = this.preferences.transfers;
    this.theme = this.preferences.theme;
  }

  ngOnDestroy(): void {
    LocalStorageManager.setDarkmode(PreferencesModel.shared.theme == 1);
  }

  createForm() {
    this.form = this.fb.group({
      symbol_side:       [ this.preferences.symbolSide , Validators.required ],
      show_symbol:       [ this.preferences.showSymbol , Validators.required ],
      show_iso_code:     [ this.preferences.showIsoCode , Validators.required ],
      decimal_format:    [ this.preferences.decimalFormat , Validators.required ],
      decimal_number:    [ this.preferences.decimalNumber , Validators.required ],
      format_date:       [ this.preferences.dateFormat , Validators.required ],
      format_time:       [ this.preferences.timeFormat , Validators.required ],
      transfers:         [ this.preferences.transfers , Validators.required ],
      first_weekday:     [ this.preferences.firstWeekDay , Validators.required ],
      theme:             [ this.preferences.theme , Validators.required ],
      floating_button:   [ this.preferences.floatingButton , Validators.required ],
      fk_user:           [ this.preferences.fkUser , Validators.required ],
      fk_currency:       [ this.preferences.fkCurrency , Validators.required ],
      pk_preference:     [ this.preferences.pkPreference , Validators.required ],
      get : ""
    });
  }

  setCurrency(currency: ModelCurrency) {
    this.changeCurrency = true;
    this.currentCurrency = currency;
    this.form.get('fk_currency')!.setValue(currency.pkCurrency);
    this.showSaveButton = true;
  }

  setCurrencySettings(settings: SettingsCurrencyFormat) {
    this.currencySettings = settings;
    this.form.get('decimal_number')!.setValue(settings.decimals);
    this.form.get('decimal_format')!.setValue(settings.separator == NumberSeparator.dot ? 0 : 1);
    this.form.get('show_symbol')!.setValue(settings.showSymbol);
    this.form.get('show_iso_code')!.setValue(settings.showISOCode);
    this.form.get('symbol_side')!.setValue(settings.symbolsSide);
    this.showSaveButton = true;
  }

  setDateTimeSettings(settings: SettingsDateFormat) {
    this.dateSettings = settings;
    this.form.get('format_date')!.setValue(settings.dateFormat);
    this.form.get('format_time')!.setValue(settings.timeFormat);
    this.form.get('first_weekday')!.setValue(settings.firstWeekday);
    this.showSaveButton = true;
  }

  setShowTransfers(value: ShowHide) {
    this.showTransfers = value;
    this.form.get('transfers')!.setValue(value);
    this.showSaveButton = true;
  }

  setTheme(theme: ThemeStyle) {
    if(this.isDark) {
      this.document.body.classList.add('dark-mode');
    } else {
      this.document.body.classList.remove('dark-mode');
    }
    this.isDark = !this.isDark;
    this.theme = theme;
    LocalStorageManager.setDarkmode(theme == ThemeStyle.dark);
    this.form.get('theme')!.setValue(theme);
    this.showSaveButton = true;
  }

  savePreferences() {
    this.form.get('get')?.setValue("preference")
    this.services.updatePreferences(this.form.value, this.changeCurrency).then(_ => {
      this.showSaveButton = false;
      PreferencesModel.shared.currencySettings = this.currencySettings;
      PreferencesModel.shared.dateSettings = this.dateSettings;
      PreferencesModel.shared.transfers = this.showTransfers;
      PreferencesModel.shared.theme = this.theme;
      PreferencesModel.shared.currency = this.currentCurrency;
      setTimeout(() => {
        location.reload();
      }, 400);
    }, reject => {
      console.log(reject);
    });
  }

  setLanguague(lang: string){
    LocalStorageManager.setLanguage(lang);
    window.location.reload();
  }
}