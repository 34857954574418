import { SegmentedSelectorItem } from '../../../../../../components/common-components/segmented-selector/interfaces/segmented-selector.interfaces';

export enum NumberSeparator {
    comma = ',',
    dot = "."
}
export enum Side {
    left = 0,
    right = 1
}
export enum ShowHide {
    show = 1,
    hide = 0
}

export class CardCurrencyFormatModel {

    static currencySeparator = [NumberSeparator.comma, NumberSeparator.dot];
    static currencySeparatorItems: SegmentedSelectorItem<NumberSeparator>[] = [
        {title: '1,000.00', value: CardCurrencyFormatModel.currencySeparator[1]},
        {title: '1.000,00', value: CardCurrencyFormatModel.currencySeparator[0]}
    ]

    static currencySymbolSide = [Side.left, Side.right];
    static currencySymbolSideItems: SegmentedSelectorItem<Side>[] = [
        {title: 'currency_section.currency_left', value: CardCurrencyFormatModel.currencySymbolSide[0]},
        {title: 'currency_section.currency_right', value: CardCurrencyFormatModel.currencySymbolSide[1]}
    ]

    static showHideSymbolItems: SegmentedSelectorItem<ShowHide>[] = [
        {title: 'category_section.show', value: ShowHide.show},
        {title: 'currency_section.currency_hide', value: ShowHide.hide}
    ]

    static showHideISOCodeItems: SegmentedSelectorItem<ShowHide>[] = [
        {title: 'category_section.show', value: ShowHide.show},
        {title: 'currency_section.currency_hide', value: ShowHide.hide}
    ]

    static decimals: SegmentedSelectorItem<number>[] = [
        {title: '0', value: 0},
        {title: '2', value: 2},
        {title: '3', value: 3},
        {title: '4', value: 4},
        {title: '5', value: 5},
        {title: '6', value: 6}
    ]

}