<div 
    class="card rounded " 
    [ngClass]="{'bg-dark white-text': ls.isDarkmode, 'card-shadow': !ls.isDarkmode}"
    >
    <div class="card-body">
        <h4 
            class="card-title title-secondary"
            [ngClass]="{'white-text': ls.isDarkmode}"
            >{{ "settions_section.settings_theme" | translate }}
        </h4>
        <div class="container">
            <div class="row font-secondary" [ngClass]="{'font-secondary-dark': ls.isDarkmode}">
                <div class="col-12 no-padding">
                    <p class="gray no-space text-left">{{ "settions_section.settings_theme_body" | translate }}</p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="themeItems"
                        [currentItem]="currentTheme"
                        (emitter)="setTheme($event)"
                        >
                    </app-segmented-selector>
                </div>
            </div>
        </div>
    </div>
</div>