import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';
import { ThemeStyle, CardThemeModal } from './model/card-theme.model';
import { PreferencesModel } from '../../../../../models/preferences.model';
import { SegmentedSelectorItem } from '../../../../../components/common-components/segmented-selector/interfaces/segmented-selector.interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-theme',
  templateUrl: './card-theme.component.html',
  styleUrls: ['./card-theme.component.css']
})
export class CardThemeComponent implements OnInit {

  @Output() emitter = new EventEmitter<ThemeStyle>();

  ls = LocalStorageManager;

  themeItems:SegmentedSelectorItem<ThemeStyle>[] = CardThemeModal.themeStylesItems;
  currentTheme!:ThemeStyle;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.currentTheme = PreferencesModel.shared.theme;
  }

  setTheme(theme: ThemeStyle) {
    this.currentTheme = theme;
    this.emitter.emit(theme);
  }

}
