import { ModelSubCategories } from './subcategories.model';
import { ModelMovements } from '../../movements/model/movements.model';
import { JSONColor } from 'src/app/interfaces/color-palette.interface';

export class ModelCategories {
  id: number;
  name: string;
  sign: string;
  icon: string;
  color: string;
  fkSubscription: number;
  shown: number;
  deleted: number;
  shared: boolean = false;
  subcategories: ModelSubCategories[];
  movements: ModelMovements[] = [];
  clickCategory: boolean = false;

  webFilter: string = "";

  constructor(id: number, name: string, sign: string, icon: string, color: string, fkSubscription: number,
              shown: number, deleted: number, subcategories: ModelSubCategories[] = [],
              movements: ModelMovements[] = []) {
      this.id = id;
      this.name = name;
      this.sign = sign;
      this.icon = icon;
      this.color = color;
      this.fkSubscription = fkSubscription;
      this.shown = shown;
      this.deleted = deleted;
      this.subcategories = subcategories;
      this.movements = movements;
      this.webFilter = this.getWebFilter(color);
  }

  getMovementsMinusSum(){
    let total = 0;
    this.movements?.forEach(movement => {
      if (movement.sign === '-') {
        total = +total + +movement.amount!;
      }
    });
    return total;
  }

  getMovementsPlusSum(){
    let total = 0;
    this.movements.forEach(movement => {
      if (movement.sign === '+') {
        total = +total + +movement.amount!;
      }
    });
    return total;
  }

  getWebFilter(hex: string): string {
    const JSONColor : JSONColor = require("../../../json/GD4-ColorPalette.json");
    const colorGroups = JSONColor.colorGroups;
    let filter = ""
    colorGroups.forEach(it => {
      const colors = it.colors;        
      const color = colors.find(search => search.hex.toLocaleUpperCase() == hex.toLocaleUpperCase());        
      if (color != null && color != undefined) {
        filter = color.web;
      }
    });
      return filter;
    }
}