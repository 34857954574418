import { Component, OnInit } from '@angular/core';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesModel } from '../../../../models/preferences.model';
import { Router } from '@angular/router';
import { SyncAll } from '../../../../services/sync-all.service';
@Component({
  selector: 'app-balance-per-account',
  templateUrl: './balance-per-account.component.html',
  styleUrls: ['./balance-per-account.component.css']
})
export class BalancePerAccountComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;
  noData: boolean = false;
  accounts: ModelAccounts[] = [];
  totalBalance: number = 0;
  preferences = PreferencesModel.shared;

  constructor(private homeService : SyncAll,
      private translate: TranslateService,
      private route: Router) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.accounts = this.homeService.accounts.sort((a, b) => a.accountName.localeCompare(b.accountName));
    const movements = this.homeService.movements;
    this.accounts.forEach(account => {
      account.movements = movements.filter(m => m.fkAccount == account.pkAccount);
      this.totalBalance += account.getBalance();
    });
    this.totalBalance += this.getBalancesAccounts();
  }

  balanceAccount(balance : number) {
    return balance < 0? balance * -1 : balance;
  }

  getBalancesAccounts(): number {
    let selectedsAccounts = this.accounts.filter(account =>  account.selected == 1);
    let balance = 0;
    selectedsAccounts.forEach(account => {
        balance += account.initialBalance;
    });
    return balance;
  }

  changePage() {
    this.route.navigate(['Accounts']);
  }
}
