<mat-select
    disableOptionCentering
    panelClass="myPanelCategories"
    [(value)]="select_pk_category"
    class="select-dialog text-start ps-3"
    style="border-radius:10px"
    ngClass="{{ls.isDarkmode ? 'dark-mode' : 'light-mode'}}"
    (valueChange)="setCategory($event)"
    [ngStyle]="{'background-color' : categorySelected ? getColor() : ls.isDarkmode || (showAllCategories && select_pk_category == 0) ? '#575f68' : '#9E9E9E' , 'color' : categorySelected || ls.isDarkmode ? 'white' : 'black'}"
    >
    <mat-select-trigger
        *ngIf="categorySelected"
        class="no-margin no-padding text-start"
        class="{{categorySelected ?  'white-text' : 'filter-black1'}}"
        >
        <p class="no-margin no-padding">
        <img
            class="{{categorySelected ?  'filter-white' :  ls.isDarkmode ? 'filter-white' : 'filter-black1'}}"
            class="no-margin no-padding pe-3"
            width="55"
            height="55"
            src="assets/images/icon-cat/{{categorySelected.icon}}.svg"
            >{{categorySelected.name}}
        </p>
    </mat-select-trigger>
    <mat-select-trigger
        *ngIf="!categorySelected"
        class="no-margin no-padding text-start"
        ngClass="{{ls.isDarkmode || !categorySelected ? 'white-text' : 'font-secondary'}}"
        >
        <p class="no-margin no-padding">
            <img
                class="{{!categorySelected ?  'filter-white' :  ls.isDarkmode ? 'filter-white' : 'filter-black1'}}"
                class="no-margin no-padding pe-3"
                width="55"
                height="55"
                src="assets/images/icon-cat/{{showAllCategories ? 'icon_all_categories' : 'icon_empty'}}.svg"
                >{{(showAllCategories ? "selecteds.all_categories" : "web_messages.select_category") | translate}}
            </p>
    </mat-select-trigger>
    <mat-option
        class="no-margin no-padding mat-option"
        ngClass="{{ls.isDarkmode || !categorySelected ? 'white-text' : 'font-secondary'}}"
        [value]="0"
        >
        <p
            class="col-12 no-margin"
            [ngClass]="{'border-cell' : !categorySelected}"
            [ngStyle]="{'background-color' : ls.isDarkmode ? !categorySelected ? 'black' : '#343a40' : categorySelected ? '#ffffff' : '#343a40', 'color' : ls.isDarkmode || !categorySelected ? 'white' : '#9E9E9E'}"
            >
        <img
            ngClass="{{ !categorySelected || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
            class="p-2"
            width="55"
            height="55"
            src="assets/images/icon-cat/{{showAllCategories ? 'icon_all_categories' : 'icon_empty'}}.svg"
            >{{(showAllCategories ? "selecteds.all_categories" : "web_messages.select_category") | translate}}
        </p>
    </mat-option>
    <mat-option
        *ngFor="let category  of categories"
        [value]="category.id"
        class="no-margin no-padding mat-option"
        ngClass="{{categorySelected != null && category.id == categorySelected.id || ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        ><p
            class="col-12 no-margin"
            [ngClass]="{'border-cell' : categorySelected && category.id == categorySelected?.id}"
            [ngStyle]="{'background-color' : categorySelected && category.id == categorySelected.id ? '#'+category.color :  ls.isDarkmode ?  '#343a40' : '#ffffff', 'color' : ls.isDarkmode || categorySelected && category.id == categorySelected.id ? 'white' : '#9E9E9E'}"
            >
        <img
            ngClass="{{ categorySelected && category.id == categorySelected.id || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
            class="me-2 ms-1"
            width="36"
            height="36"
            src="assets/images/icon-cat/{{category.icon}}.svg"
            >{{category.name}}
        </p>
    </mat-option>
</mat-select>


<mat-select
    *ngIf="(!hideSubcategoriesSelector && categorySelected != null) && showSubcategories"
    disableOptionCentering
    panelClass="{{ls.isDarkmode ? 'myPanelClassDarkModals' : 'myPanelClassLightModals'}}"
    [(value)]="select_pk_subcategory"
    class="select-dialog mt-2"
    style="border-radius:10px"
    ngClass="{{ls.isDarkmode ? 'dark-mode' : 'light-mode'}}"
    (valueChange)="setSubcategory($event)"
    [ngStyle]="{'background-color' : subcategorySelected ? getColor() : ls.isDarkmode ? '#575f68' : '#666' , 'color' : subcategorySelected || ls.isDarkmode ? 'white' : 'black'}"
    ><mat-select-trigger
        *ngIf="select_pk_subcategory != 0"
        class="no-margin no-padding text-start"
        ngClass="{{ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        ><p
            class="no-margin no-padding white-text"
            ><img
            class="{{subcategorySelected ?  'filter-white' :  ls.isDarkmode ? 'filter-white' : 'filter-black1'}}"
            class="no-margin no-padding pe-3"
            width="55"
            height="55"
            src="assets/images/icon-cat/{{subcategorySelected?.icon}}.svg"
            />{{subcategorySelected?.name}}
        </p>
    </mat-select-trigger>
    <mat-select-trigger
        *ngIf="select_pk_subcategory == 0"
        class="no-margin no-padding text-start"
        ngClass="{{ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        ><p
            class="no-margin no-padding white-text"
            ><img
            class="{{!subcategorySelected ?  'filter-white' :  ls.isDarkmode ? 'filter-white' : 'filter-black1'}}"
            class="no-margin no-padding pe-3"
            width="55"
            height="55"
            src="assets/images/icon-cat/icon_empty.svg"
            >{{"web_messages.select_subcategory" | translate}}
        </p>
    </mat-select-trigger>
    <mat-option
        class="no-margin no-padding mat-option"
        ngClass="{{ls.isDarkmode || !subcategorySelected ? 'white-text' : 'font-secondary'}}"
        [value]="0"
        >
        <p
            class="col-12 no-margin"
            [ngClass]="{'border-cell' : !subcategorySelected}"
            [ngStyle]="{'background-color' : ls.isDarkmode ? !subcategorySelected ? 'black' : '#343a40' : subcategorySelected ? '#ffffff' : '#343a40'}"
            >
        <img
            ngClass="{{ !subcategorySelected || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
            class="mr-2"
            width="36"
            height="36"
            src="assets/images/icon-cat/icon_empty.svg"
            >{{"web_messages.select_subcategory" | translate}}
        </p>
    </mat-option>
    <mat-option
        *ngFor="let subcategory  of getSubcategories()"
        [value]="subcategory.id"
        class="no-margin no-padding mat-option"
        ngClass="{{(subcategorySelected && subcategory.id == categorySelected.id) || ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        ><p
            class="col-12 no-margin"
            [ngClass]="{'border-cell white-text' : subcategorySelected && subcategory.id == subcategorySelected?.id}"
            [ngStyle]="{'background-color' : subcategorySelected && subcategory.id == subcategorySelected.id ? '#'+subcategory.category.color :  ls.isDarkmode ?  '#343a40' : '#ffffff'}"
            >
        <img
            ngClass="{{ subcategorySelected && subcategory.id == subcategorySelected.id || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
            class="mr-2"
            width="36"
            height="36"
            src="assets/images/icon-cat/{{subcategory.icon}}.svg"
            >{{subcategory.name}}
        </p>
    </mat-option>
</mat-select>
