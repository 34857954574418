import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModelAccounts } from '../../../pages/accounts/models/accounts.model';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selector-accounts',
  templateUrl: './selector-accounts.component.html',
  styleUrls: ['./selector-accounts.component.css']
})
export class SelectorAccountsComponent implements OnInit {
  @Input() accounts?: ModelAccounts[] = [];
  @Input() showAllAccounts: boolean = false;
  @Input() accountSelected: ModelAccounts | null = null;

  selectedPkAccount : number = 0;

  @Output() setAccountSelected = new EventEmitter<ModelAccounts | null>();


  ls = LocalStorageManager;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
   }

  ngOnInit(): void {
    this.accounts?.sort((a, b) => {
      return a.accountName.localeCompare(b.accountName);
    });
  }

  ngOnChanges(): void {
    this.accounts?.sort((a, b) => {
      return a.accountName.localeCompare(b.accountName);
    });
    this.selectedPkAccount = this.accountSelected ? this.accountSelected.pkAccount : 0;
    
  }

   selectAccount(pk_account: number) {
    this.selectedPkAccount = pk_account;
    if (pk_account == 0 && this.showAllAccounts) {
      this.accountSelected = null;
      this.setAccountSelected.emit(this.accountSelected);
    } else {
      let account = this.accounts?.find(account => account.pkAccount == pk_account);
      this.accountSelected = account!
      this.setAccountSelected.emit(this.accountSelected);
    }
   }

   getColor() {
    let color = this.accountSelected ? this.accountSelected.color : this.ls.isDarkmode ? "#575f68" : "#ffffff";
    return color.includes('#') ? color : '#'+color;
  }


  getPkAccount() : number{
    return this.accountSelected ? this.accountSelected.pkAccount : 0
  }
}