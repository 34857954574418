import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';
import { CardDateFormatModel, DateFormat, FirstWeekDay, TimeFormat } from './model/card-date-format.model';
import { SettingsDateFormat } from '../../../interface/date-format.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-date-format',
  templateUrl: './card-date-format.component.html',
  styleUrls: ['./card-date-format.component.css']
})
export class CardDateFormatComponent implements OnInit {

  @Output() emitter = new EventEmitter<SettingsDateFormat>();

  localStorage = LocalStorageManager;

  dateFormatsItems = CardDateFormatModel.dateFormatsItems;
  timeFormatsItems = CardDateFormatModel.timeFormatItems;
  firstWeekDaysItems = CardDateFormatModel.firstWeekDaysItems;

  settings! : SettingsDateFormat;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.settings = {
      dateFormat: PreferencesModel.shared.dateSettings.dateFormat,
      timeFormat: PreferencesModel.shared.dateSettings.timeFormat,
      firstWeekday: PreferencesModel.shared.dateSettings.firstWeekday
    }
  }

  setDateFormat(format: DateFormat) {
    this.settings.dateFormat = format;
    this.emitter.emit(this.settings);
  }

  setTimeFormat(format: TimeFormat) {
    this.settings.timeFormat = format;
    this.emitter.emit(this.settings);
  }

  setFirstWeekDay(day: FirstWeekDay) {
    this.settings.firstWeekday = day;
    this.emitter.emit(this.settings);
  }
}
