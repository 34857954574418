import { ModelCurrency } from '../../../models/currency.model';
import { SharedAccountsModel } from './sharedAccounts.model';
import { ModelMovements } from '../../movements/model/movements.model';
import { UserModel } from '../../../models/user.modal';
import { JSONColor } from 'src/app/interfaces/color-palette.interface';
//import * as colorsJson from '../../../json/GD4-ColorPalette.json';

export class ModelAccounts {

    pkAccount: number;
    accountName: string;
    type: number;
    detail: string;
    initialBalance: number;
    sign: string;
    icon: string;
    selected: number;
    negativeLimit: number;
    positiveLimit: number;
    rate: number;
    shown: number;
    deleted: number;
    color: string;
    webColor: string;
    fkSubscription: number;
    fkCurrency: number;
    currency: ModelCurrency;
    sharedAccount?: SharedAccountsModel;
    movements: ModelMovements[] = [];
    clickAccount: boolean = false;

    balance: number = 0;
    countMovements: number = 0;
    percentage: number = 0;
    user?: UserModel;

    constructor(pkAccount: number, accountName: string, type: number, detail: string, initialBalance: number,
                sign: string, icon: string, selected: number, negativeLimit: number, positiveLimit: number,
                rate: number, shown: number, deleted: number, color: string, webColor: string,
                fkSubscription: number, fkCurrency: number, currency: ModelCurrency = new ModelCurrency(68, 'USD', '$'),
                movements: ModelMovements[] = []) {
        this.pkAccount = pkAccount;
        this.accountName = accountName;
        this.type = type;
        this.detail = detail;
        this.initialBalance = initialBalance;
        this.sign = sign;
        this.icon = icon;
        this.selected = selected;
        this.negativeLimit = negativeLimit;
        this.positiveLimit = positiveLimit;
        this.rate = rate;
        this.shown = shown;
        this.deleted = deleted;
        this.color = color;
        this.webColor = webColor;
        this.webColor = this.getWebFilter(color);
        this.fkSubscription = fkSubscription;
        this.fkCurrency = fkCurrency;
        this.currency = currency;
        this.movements = movements;

        this.balance = this.getBalance();
        this.setPercentage();
    }

    getWebFilter(hex: string): string {
      const JSONColor : JSONColor = require("../../../json/GD4-ColorPalette.json");
      const colorGroups = JSONColor.colorGroups;
      let filter = ""
      colorGroups.forEach(it => {
        const colors = it.colors;        
        const color = colors.find(search => search.hex.toLocaleUpperCase() == hex.toLocaleUpperCase());        
        if (color != null && color != undefined) {
          filter = color.web;
        }
      });
        return filter;
      }

    getBalance(): number {
        const reducer = (previousValue: any, currentValue: any) => previousValue + currentValue;
        const totalIncomes = this.movements.filter(m => m.sign == '+').map(m => m.amount).reduce(reducer, 0);
        const totalExpenses = this.movements.filter(m => m.sign == '-').map(m => m.amount).reduce(reducer, 0);        
        return this.initialBalance + (totalIncomes - totalExpenses);
    }

    setPercentage() {
      let balance = this.balance < 0 ? -this.balance : this.balance;
      this.percentage = this.positiveLimit != 0 ?(balance * 100) / this.positiveLimit : 0;
    }

    getRandomInt(min : any, max : any) : number{
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min; 
    }
}
