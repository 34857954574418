export class SharedAccountsModel {

    canAdd: number;
    canDelete: number;
    canUpdate: number;
    fkAccount: number;
    fkShared: number;
    pkSharedAccount: number;
    selected: boolean;

    constructor(canAdd: number, canDelete: number, canUpdate: number, fkAccount: number, fkShared: number, 
                pkSharedAccount: number, selected: boolean) {
        this.canAdd = canAdd;
        this.canDelete = canDelete;
        this.canUpdate = canUpdate;
        this.fkAccount = fkAccount;
        this.fkShared = fkShared;
        this.pkSharedAccount = pkSharedAccount;
        this.selected = selected;
    }

}
