import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-not-have-data',
  templateUrl: './not-have-data.component.html',
  styleUrls: ['./not-have-data.component.css']
})
export class NotHaveDataComponent implements OnInit {
  @Input() message!: string
  isDarkmode = LocalStorageManager.isDarkmode;
  
  constructor() { }

  ngOnInit(): void {
  }

}
