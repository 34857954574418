import { GoalStatus, GoalStatusData } from '../interfaces/goals.interface';
import { SegmentedSelectorItem } from '../../../components/common-components/segmented-selector/interfaces/segmented-selector.interfaces';

export class GoalStatusModel {
    goalStatus: GoalStatusData[] = [
        {
            status : GoalStatus.active,
            title : 'Activo',
            imageName: 'menu-goals'
        },
        {
            status : GoalStatus.pause,
            title : 'Pausado',
            imageName: 'icon-pause'
        },
        {
            status : GoalStatus.finished,
            title : 'Logrado',
            imageName: 'menu-goals'
        }
    ]

    statusItems: SegmentedSelectorItem<GoalStatusData>[] = []

    constructor() {
        this.goalStatus.forEach(status => {
        this.statusItems.push({
            title: status.title,
            color: '0EB594',
            value: status
            });
        });
    }
}