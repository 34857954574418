import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { BalanceLastThirtyDaysModel } from 'src/app/components/cards/card-thirty-days-balance/model/balanceLastThirtyDays.model';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { ModelMovements } from 'src/app/pages/movements/model/movements.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import {curveNatural} from 'd3-shape';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SyncAll } from '../../../../services/sync-all.service';

@Component({
  selector: 'app-balance-last-thirty-days',
  templateUrl: './balance-last-thirty-days.component.html',
  styleUrls: ['./balance-last-thirty-days.component.css']
})
export class BalanceLastThirtyDaysComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;

  preferences = PreferencesModel.shared;
  movements: ModelMovements[] = [];
  noData: boolean = false;
  date?: moment.Moment;
  
  options: any;
  data?: BalanceLastThirtyDaysModel;

  selectedAccounts: ModelAccounts[] = [];
  curve = curveNatural;

  colorScheme = {
    domain: ['#3F647EB3']
  };

  constructor(private homeServices: SyncAll,
    private translate: TranslateService,
    private route: Router) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.selectedAccounts = this.homeServices.selectedAccounts;
    this.movements = this.homeServices.movements;
    this.date = this.homeServices.currentDate;
    
    this.preferences = PreferencesModel.shared;
    if (this.movements.length == 0) {
      this.noData = true;
    } else {
      let filteredMovements: ModelMovements[] = [];
      filteredMovements = this.movements.filter(movement => 
        this.selectedAccounts.find(account => account.pkAccount == movement.fkAccount)
      );
      this.data = new BalanceLastThirtyDaysModel(filteredMovements);
    }
  }

  changePage() {
    this.route.navigate(['Reports-by-date/reports']);
  }
}