import { Injectable } from '@angular/core';
import { CurrenciesService } from './currencies.service';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { ModelCurrency } from 'src/app/models/currency.model';
@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  constructor(private currenciesServices:CurrenciesService) { }

  getPreferences(preference : PreferencesModel, currenciesUser : ModelCurrency[]) {
    PreferencesModel.shared = preference;
    this.currenciesServices.getCurrencies(currenciesUser)
      PreferencesModel.shared.currencies = this.currenciesServices.currencies;
      PreferencesModel.shared.currency = this.currenciesServices.currencies.find(c => c.pkCurrency == PreferencesModel.shared.fkCurrency);
  }
}
