<div 
    class="card rounded card-shadow" 
    [ngClass]="{'bg-dark white-text': localStorage.isDarkmode, 'card-shadow': !localStorage.isDarkmode}"
    >
    <div class="card-body">
        <h4 
            class="card-title title-secondary"
            [ngClass]="{'white-text': localStorage.isDarkmode}">{{ "words.transfers" | translate }}</h4>
        <div class="container font-secondary" [ngClass]="{'font-secondary-dark': localStorage.isDarkmode}">
            <div class="row">
                <div class="col-12 no-padding">
                    <p class="no-margin text-left">{{ "settions_section.settings_transfers_body" | translate }}</p>
                    <app-segmented-selector
                        [smallSize]="true"
                        [items]="showHideItems"
                        [currentItem]="showTransfers"
                        (emitter)="setShowTransfers($event)"
                        >
                    </app-segmented-selector>
                </div>
            </div>
        </div>
    </div>
</div>