import { Injectable } from '@angular/core';

import * as auth from 'firebase/auth';
import {AngularFireAuth } from "@angular/fire/compat/auth";

@Injectable({
  providedIn: 'root'
})
export class AuthGoogleService {

  constructor(public afAuth: AngularFireAuth) { }

  async GoogleAuth(){
    return this.AuthLoginGoogle(new auth.GoogleAuthProvider());
  }

  async AppleAuth(){
    let provider = new auth.OAuthProvider('apple.com');
    provider.addScope('name');
    provider.addScope('email');
    return this.AuthLoginApple(provider);
  }

  async AuthLoginGoogle(provider : any) {
      return new Promise((resolve, reject) => {
        this.afAuth.signInWithPopup(provider).then(result => {
          resolve(result.user);
        }).catch((error) => {
          reject(error);
        });
    });
  }

  async AuthLoginApple(provider : any) {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithPopup(provider).then((result : any) => {
        resolve(result.additionalUserInfo.profile['sub']);
        }).catch((error)=> {
          reject(error);
        });
    });
  }
}

