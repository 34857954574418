import { SegmentedSelectorItem } from '../../../../../../components/common-components/segmented-selector/interfaces/segmented-selector.interfaces';

export enum DateFormat {
    dd_mm_aaaa = 0,
    mm_dd_aaaa = 1,
    aaaa_mm_dd = 2
}

export enum TimeFormat {
    time12 = 0,
    time24 = 1
}

export enum FirstWeekDay {
    sunday = 0,
    monday = 1
}

export class CardDateFormatModel {

    static dateFormatsItems: SegmentedSelectorItem<DateFormat>[] = [
        {title: 'dd/mm/aaaa', value: DateFormat.dd_mm_aaaa},
        {title: 'mm/dd/aaaa', value: DateFormat.mm_dd_aaaa},
        {title: 'aaaa/mm/dd', value: DateFormat.aaaa_mm_dd}
    ]
    
    static timeFormatItems: SegmentedSelectorItem<TimeFormat>[] = [
        {title: '12:00 hrs', value: TimeFormat.time12},
        {title: '24:00 hrs', value: TimeFormat.time24}
    ]

    static firstWeekDaysItems: SegmentedSelectorItem<FirstWeekDay>[] = [
        {title: 'others_dialogs.sunday', value: FirstWeekDay.sunday},
        {title: 'others_dialogs.monday', value: FirstWeekDay.monday}
    ]
}