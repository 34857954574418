import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Order, OrderSelector } from './interfaces/OrderSelector.interface';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selector-order',
  templateUrl: './selector-order.component.html',
  styleUrls: ['./selector-order.component.css']
})
export class SelectorOrderComponent {
  @Input() currentOrder: Order = Order.descending;
  @Output() emitter = new EventEmitter<Order>();
  selectedOrder: Order = Order.descending;

  ls = LocalStorageManager;

  orderOptions: OrderSelector[] = [{
    value: Order.descending,
    text: "words.order_descending"
  },{
    value: Order.ascending,
    text: "words.order_ascending"
  }];

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

 ngOnInit(): void { }

  onChange(order: Order) {
    this.selectedOrder = order;
    this.emitter.emit(order);
  }
}