import moment from "moment";
import { MovementTypes } from "src/app/components/common-components/selector-movement-type/interfaces/movement-type.interface";
import { PeriodType } from "src/app/components/common-components/selector-period/interfaces/period.interface";
import { MovementStatus } from '../../components/common-components/selector-movement-status/interfaces/movement-status.interfaces';

export class LocalStorageMovements {

    private keyCategories: string = 'LSMovementsCategories';
    private keyMovmentsType: string = 'LSMovementsType';
    private keyMovementStatus: string = 'LSMovementsStatus';
    private keyOrder: string = 'LSMovementsOrder';
    private keyPeriod: string = 'LSMovementsPeriod';
    private keyDate: string = 'LSMovementsDate';

    getDate(): moment.Moment {
        return moment((localStorage.getItem(this.keyDate) != undefined) ? localStorage.getItem(this.keyDate) : moment(new Date()).format('yyyy-MM-DD'));
    }

    setDate(date: moment.Moment) {
        localStorage.setItem(this.keyDate, date.format('yyyy-MM-DD'));
    }

    getPeriod(): PeriodType {
        return localStorage.getItem(this.keyPeriod) != null ? +localStorage.getItem(this.keyPeriod)! : PeriodType.monthly;
    }

    setPeriod(type: PeriodType) {
        localStorage.setItem(this.keyPeriod, type.toString());
    }
    
    getCategoriesIds(): number[] {
        return JSON.parse(localStorage.getItem(this.keyCategories)!);
    }

    setCategoriesIds(ids: number[]) {
        localStorage.setItem(this.keyCategories, JSON.stringify(ids))
    }

    getMovmentsType(): MovementTypes | null {
        let type = localStorage.getItem(this.keyMovmentsType) as MovementTypes;
        return (type?.toString() == 'undefined' ? null : type) ?? null;
    }
    setMovmentsType(type: MovementTypes) {
        localStorage.setItem(this.keyMovmentsType, type)
    }

    getMovmentsStatus(): MovementStatus {
        return MovementStatus[localStorage.getItem(this.keyMovementStatus) as keyof typeof MovementStatus];
    }
    setMovmentsStatus(status: MovementStatus) {
        localStorage.setItem(this.keyMovementStatus, status.toString())
    }

    getMovmentsOrder() {
        return localStorage.getItem(this.keyOrder);
    }
    setMovmentsOrder(order : any) {
        localStorage.setItem(this.keyOrder, order.toString())
    }

    removeAll() {
        localStorage.removeItem(this.keyCategories);
        localStorage.removeItem(this.keyMovmentsType);
        localStorage.removeItem(this.keyMovementStatus);
        localStorage.removeItem(this.keyOrder);
    }

}