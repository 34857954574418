<div [ngClass]="{'bg-primary-dark': isDarkmode}">
    <div class="col pt-3 ml-4 pb-2">
        <a (click)="changePage()">
            <img
                src="assets/images/icon-arrow-left.svg"
                width="30"
                [ngClass]="{'filter-white': isDarkmode}"
                >
        </a>
    </div>
    <!--button (click)="changePage()">Login</button>-->
    <div
        [ngClass]="{'white-text': isDarkmode}"
        class="container"
        >
        <div class="row">
            <h1 class="color-text-titles">
                Privacy Policy
            </h1>
            <p>
                These privacy policies are for all developments made by Encodemx, our developments are available on the web or in stores such as Google Play Store, App Gallery and App Store.
                Encodemx is a small development team made up of 4 developers, we are located at Boulevard Tepic-Xalisco # 419-A in Tepic, Nayarit Mexico. Leading the team is Michel Carvajal, contact personal email: michel@encodemx.com
                This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
                If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
            </p>
            <div class="mt-4">
                <h3 class="ml-2 color-text-titles">1. Our Applications:</h3>
                <div class="ml-4">
                    <p>- Daily Expenses 4 (Android)</p>
                    <p>- Daily Expenses 4 (WEB)</p>
                    <p>- Daily Expenses 4 (iOS)</p>
                </div>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">2. Data Collection and Use</h3>
                <p class="ml-4">
                    For a better experience, while using our Service, we may ask you for certain personally identifiable information. The information we request will be retained by us and used as described below:
                </p>
                <div class="row ml-4">
                    <p 
                    [ngClass]="{'white-text': isDarkmode}"
                    >Email account to save backups. The service to create and retrieve backups on our server works with the user’s email account, this function is optional and not essential to use our applications.
                        Email account to register premium version. The accounts of users who purchase the premium version are registered on our server, this allows users to retrieve their license through their account.
                        Email account to send emails. Some of our applications have a service so that the user can send their own information to one of their email accounts, this function is optional and is not essential to use our applications. Our server receives this information and forwards it to the email that the user has indicated, for the email service we do not store the email account, nor the information that the user is sending.
                    </p>
                </div>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">3. Delete Data and Profile from Our Server</h3>
                <p class="ml-4">
                    Our applications give the user an option so that they themselves can delete their profile account, their data and backups hosted on our server. The user must bear in mind that once this action is executed, there is no way to recover the deleted information.
                    The user will be able to find this option in the configuration section of each application, if you have questions about it, please send an email to: support@encodemx.com we will gladly explain where this option is located.
                </p>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">4. Third Party Service Providers and Data Sharing</h3>
                <p class="ml-4">
                    he app does use third party services that may collect information used to identify you, we only share the email account with some services.
                    Link to privacy policy of third party service providers used by the app:
                </p>
                <div class="row ml-4">
                    <p>
                        Google Play Services (applicable only on Android devices) – It is used for user authentication and works in tandem with other Google tools.
                        Google AdMob (applicable only on free version) – It is the advertising provider that our applications show.
                        Google Drive (applicable only on Android devices with professional version) – It’s a free data storage service, if the users so wishes, they can upload backups or XLS reports to their personal Google Drive account.
                        Firebase Analytics – collects statistical data on the use that is given to the application, for example screens that are most visited, frequency, duration, etc.
                        Firebase Crashlytics – collects information on failures or malfunctions of applications.
                        Log Data – (applicable only on WEB) include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
                        Dropbox (applicable only on professional version) – It’s a free data storage service, if the users so wishes, they can upload backups or XLS reports to their personal Dropbox account.
                        iCloud (applicable only on professional version) – It’s a free data storage service, if the users so wishes, they can upload backups or XLS reports to their personal iCloud account.
                    </p>
                </div>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">5. Cookies (WEB)</h3>
                <p class="ml-4">
                    Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device’s internal memory.
                    This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                </p>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">6. Service Providers</h3>
                <p class="ml-4">
                    We may employ third-party companies and individuals due to the following reasons:
                </p>
                <div class="row ml-4">
                    <p>
                        To facilitate our Service;
                        To provide the Service on our behalf;
                        To perform Service-related services; or
                        To assist us in analyzing how our Service is used.
                        We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                    </p>
                </div>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">7. Security</h3>
                <p class="ml-4">
                    We value your trust in providing us with your personal information, so we strive to use commercially acceptable means to protect it. But remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure and reliable, and we cannot guarantee its absolute security.
                </p>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">8. Links to Other Sites</h3>
                <p class="ml-4">
                    This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                </p>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">9. Children’s Privacy</h3>
                <p class="ml-4">
                    These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
                </p>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">10. Changes to This Privacy Policy</h3>
                <p class="ml-4">
                    We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
                    This policy is effective as of 2020-07-08 and was updated on 2022-03-09.
                </p>
            </div>
            <div>
                <h3 class="ml-2 color-text-titles">11. Contact Us</h3>
                <p class="ml-4">
                    If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@encodemx.com.
                </p>
            </div>
        </div>
    </div>
</div>