
<!-- Modal agregar moneda propia  -->
<div 
    class="modal fade" 
    id="app-modal-add-currency" 
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="modelTitleId" 
    aria-hidden="true"
    data-backdrop="static" 
    data-keyboard="false"
    >
    <div 
        class="modal-dialog modal-dialog-centered" 
        role="document"
        >
        <div 
            class="modal-content rounded shadow" 
            [ngClass]="{'bg-dark white-text': ls.isDarkmode}"
            >
            <app-header-title
                closeButton="closeButtonAddCurrency"
                title="titles.title_new_currency"
                modalId="app-modal-add-currency"
                >
            </app-header-title>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <p class="no-margin mt-3">{{ "currency_section.currency_iso_code" | translate }}</p>
                        <input 
                            class="form-control input-border-light" 
                            type="text"
                            [(ngModel)]="isoCode"
                            [ngClass]="{'bg-gray clear-outline white-text': ls.isDarkmode}"
                            >
                        <p class="no-margin mt-3">{{ "currency_section.currency_symbol" | translate }}</p>
                        <input 
                            class="form-control input-border-light" 
                            type="text" 
                            [(ngModel)]="symbol"
                            [ngClass]="{'bg-gray clear-outline white-text': ls.isDarkmode}"
                            >
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 text-center mt-3">
                            <button 
                                class="btn btn-lg clear-outline bg-main-purple white-text"
                                (click)="saveCurrency()"
                                >{{ "buttons.button_save" |  translate }}
                            </button>
                        </div>
                        <div class="col-12 col-sm-6 text-center mt-3">
                            <button 
                                id="buttonCloseModal"
                                class="btn btn-secondary btn-lg" 
                                data-bs-dismiss="modal"
                                (click)="closeModal()"
                                >{{ "buttons.button_close" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>