export class LocalStorageSchedule {

    private keyCategories: string = 'LSScheduleCategories'

    getCategoriesIds(): number[] {
        return JSON.parse(localStorage.getItem(this.keyCategories)!);
    }
    setCategoriesIds(ids: number[]) {
        localStorage.setItem(this.keyCategories, JSON.stringify(ids))
    }
    removeAll() {
        localStorage.removeItem(this.keyCategories);
    }
}