import { Component, OnInit } from '@angular/core';
import { LocalStorageManager } from '../../utils/local-storage-manager/local-storage-manager.utils';


@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  isDarkmode = LocalStorageManager.isDarkmode;
  constructor() { }

  ngOnInit(): void { }

  changePage() {
    window.location.href = 'Login';
  }
    
}
