<div
    (click)="changePage()"
    class="card shadow clear-card-outline" 
    [ngClass]="{'bg-dark white-text': isDarkmode}"
    >
    <div class="card-body">
        <h5 class="card-title">{{ "title_cardviews.card_balance_last_thirty_days" | translate }}</h5>
        <ng-container *ngIf="noData">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <img 
                            class="img-fluid" 
                            src="assets/images/empty_cards/card-last-thirty-days.png"
                            >
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!noData">
            <div class="row">
                <div class="col no-padding">
                    <ngx-charts-area-chart
                        [results]="data?.chartData"
                        [curve]="curve"
                        [showGridLines]="true"
                        [yAxis]="true"
                        [scheme]="colorScheme"
                        [gradient]="false"
                        [baseValue]="0"
                        [yScaleMin]="-data?.maxValue"
                        [yScaleMax]="data?.maxValue"
                        [ngClass]="{'white-chart': isDarkmode}"
                        >
                    </ngx-charts-area-chart>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-6 text-end text-right no-padding">
                        <p>{{ "title_cardviews.balance_today" | translate }}</p>
                    </div>                    
                    <div class="col-6 text-end text-right no-padding">
                        <p>
                            {{data?.currentBalance | currencyFormat}}
                        </p>
                        <p class="gray tiny">{{ ("currency_section.currency_calculated" | translate) + preferences.currency?.isoCode}}</p>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>