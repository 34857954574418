import { SegmentedSelectorItem } from "src/app/components/common-components/segmented-selector/interfaces/segmented-selector.interfaces";

export enum ThemeStyle {
    light = 0,
    dark = 1
}

export class CardThemeModal {

    static themeStylesItems: SegmentedSelectorItem<ThemeStyle>[] = [
        {title: 'others_dialogs.theme_light', value: ThemeStyle.light},
        {title: 'others_dialogs.theme_dark', value: ThemeStyle.dark}
    ];

}