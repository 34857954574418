<mat-select
    panelClass="{{ls.isDarkmode ? 'myPanelClassDarkType' : 'myPanelClassLightType'}}"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark' : 'light-mode bg-white'}}"
    class="form-group clear-outline p-1 no-margin no-padding"
    (selectionChange)="onChange($event.value)"
    disableOptionCentering
    [(ngModel)]="selectedOrder"
    >
    <mat-option 
        *ngFor="let option of orderOptions" [value]="option.value"
        class="no-margin no-padding mat-option"
        ngClass="{{ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        >
      {{option.text | translate}}
    </mat-option>
</mat-select>