import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavbarComponent } from './navbar/layout/navbar.component';
import { LoginComponent } from './login/layout/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RecoverPasswordComponent } from './login/components/modal-recover-password/modal-recover-password.component';
import { ModalRecoverMailPasswordComponent } from './login/components/modal-recover-mail-password/modal-recover-mail-password.component';
import { ModalMessageComponent } from './login/components/modal-message/modal-message.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageDownloadComponent } from './components/message-download/message-download.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { NoticeOfPrivacyComponent } from './notice-of-privacy/notice-of-privacy.component';
import { ModalAboutComponent } from './components/modal-about/modal-about.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    NavbarComponent,
    LoginComponent,
    RecoverPasswordComponent,
    ModalRecoverMailPasswordComponent,
    ModalMessageComponent,
    MaintenanceComponent,
    MessageDownloadComponent,
    TermsAndConditionsComponent,
    NoticeOfPrivacyComponent,
    ModalAboutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    NavbarComponent,
    LoginComponent,
    TermsAndConditionsComponent,
    NoticeOfPrivacyComponent
  ]
})
export class SharedModule { }
