import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { DateFormatPipe } from './date-format.pipe';


@NgModule({
  declarations: [
    FilterPipe,
    CurrencyFormatPipe,
    DateFormatPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilterPipe,
    CurrencyFormatPipe,
    DateFormatPipe
  ]
})
export class PipesModule { }
