import { ModelCategories } from './categories.model';
export class ModelSubCategories {

    fkCategory: number;
    id: number;
    name: string;
    icon: string;
    deleted = 0;
    shown: number;
    shared: boolean = false;
    category?: ModelCategories;
    clickSubCategory : boolean = false;

    constructor(fkCategory: number, id: number, name: string, icon: string, deleted: number, shown: number, category?: ModelCategories) {
        this.fkCategory = fkCategory;
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.deleted = deleted;
        this.shown = shown;
        this.category = category;
    }

}
