<mat-select
    *ngIf="styleMain"
    panelClass="panelPeriod"
    class="form-group clear-outline p-1"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark-input' : 'light-mode bg-white'}}"
    disableOptionCentering
    (selectionChange)="emitType($event.value)"
    [(ngModel)]="selectedPeriod"
    >
    <mat-option
        *ngFor="let period of getPeriods()"
        class="no-margin no-padding mat-option"
        ngClass="{{ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        [value]="period.period"
        ><p>{{period.title | translate}}</p>
    </mat-option>
</mat-select>

<mat-select
    *ngIf="!styleMain"
    panelClass="panelPeriodModal"
    class="form-group clear-outline no-margin no-padding modal-select-value bg-modal-select ps-3"
    disableOptionCentering
    (selectionChange)="emitType($event.value)"
    [(ngModel)]="selectedPeriod"
    >
    <mat-select-trigger
        ><p
            class="text-start no-margin no-padding pe-3"
            >
            <img
                src="assets/images/icon-month.png"
                width="55"
                height="55"
                class="filter-white pe-3"
                >{{getPeriodTitle() | translate}}
        </p>
    </mat-select-trigger>
    <mat-option
        *ngFor="let period of getPeriods()"
        class="no-margin no-padding mat-option"
        ngClass="{{ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        [value]="period.period"
        ><p>
            <img
                src="assets/images/icon-month.png"
                width="30"
                height="30"
                class="ml-3 mb-1 mr-3"
                [ngClass]="{'filter-white': ls.isDarkmode}"
                >{{period.title | translate}}
        </p>
    </mat-option>
</mat-select>