import { MovementSign, MovementTypeData, MovementTypes } from '../interfaces/movement-type.interface';
import { SegmentedSelectorItem } from '../../segmented-selector/interfaces/segmented-selector.interfaces';

export class MovementTypeModel {


    getTypesWithoutAll(){
        return this.movementTypes.filter(type => type.type != MovementTypes.all);
    }

    movementTypes: MovementTypeData[] = [
        {
            title: "words.expense",
            type: MovementTypes.expenses,
            sign: MovementSign.minus
        },
        {
            title: "words.income",
            type: MovementTypes.incomes,
            sign: MovementSign.plus
        },
        {
            title: "words.transfer",
            type: MovementTypes.tranfers
        },
        {
            title: "words.all_movements",
            type: MovementTypes.all
        }
    ];

    movementTypesItems: SegmentedSelectorItem<MovementTypeData>[] = [];

    constructor(){
       
    }

    getMovementTypesItems(showAll: boolean) {
        if (showAll) {
            this.movementTypes.forEach(type => {
                this.movementTypesItems.push({title: type.title, color: this.getColor(type.type!), value: type})
            });   
        } else {
            this.getTypesWithoutAll().forEach(type => {
                this.movementTypesItems.push({title: type.title, color: this.getColor(type.type!), value: type})
            });
        }
        return this.movementTypesItems;
    }

    private getColor(type: MovementTypes): string {
        switch (type) {
            case MovementTypes.expenses: return 'D13594';
            case MovementTypes.incomes: return '0EB594';
            case MovementTypes.tranfers: return '009CDE';
            default: return '0EB594';
        }
    }
}
