import * as moment from 'moment';
import { ModelMovements } from '../../../../pages/movements/model/movements.model';


export class BalanceLastThirtyDaysModel {

    movements: ModelMovements[] = [];
    date: moment.Moment = moment();
    balance: Balances[] = [];
    chartData:areaChartDataModel[] = [];
    currentBalance = 0;
    maxValue: number = 0;

    constructor(movements: ModelMovements[]) {
        this.movements = movements;
        this.setData();
    }

    setData(){
        const days: string[] = [];
        let last30DaysMovements: ModelMovements[] = [];
        this.movements.forEach(movement => {
            if (movement.sign == '+') {
                this.currentBalance = +this.currentBalance + +movement.amount!;
            } else {
                this.currentBalance = +this.currentBalance - +movement.amount!;
            }
        });
        days.push(moment(this.date).format('MM-DD-YYYY'));
        for (let index = 1; index < 30; index++) {
            days.push(moment(this.date).subtract(index, 'days').format('MM-DD-YYYY'));
        }
        days.reverse();
        last30DaysMovements = this.movements.filter(m => {
            return moment(m.date) >= moment(days[0]) && moment(m.date) <= moment(days[days.length - 1])
        });
        let lastDaysBalance = 0;
        last30DaysMovements.forEach(movement => {
            if (movement.sign == '+') {
                lastDaysBalance = +lastDaysBalance + +movement.amount!;
            } else {
                lastDaysBalance = +lastDaysBalance - +movement.amount!;
            }
        });
        const residuaryBalance = +this.currentBalance - +lastDaysBalance;
        days.forEach(day => {
            const filteredMovements = last30DaysMovements.filter(m => moment(m.date) <= moment(day));
            let dayBalance = 0;
            let total = 0;
            filteredMovements.forEach(movement => {
                if (movement.sign == '+') {
                    dayBalance = +dayBalance + +movement.amount!;
                } else {
                    dayBalance = +dayBalance - +movement.amount!;
                }
            });
            total = +residuaryBalance + +dayBalance;
            const balance: Balances = {
                day,
                total
            };
            this.balance.push(balance);
        });

        let values = this.balance.map(b => Math.abs(b.total));
        this.maxValue = Math.max(...values);
        let areaChartData:areaChartDataModel = {name: "Days balance", series: []}
        this.balance.forEach(balance => {
            areaChartData.series.push({
                value: balance.total,
                name: moment(balance.day).format('DD-MMM')
            })
        });
        this.chartData.push(areaChartData)
    }
}

interface Balances {
    day: string;
    total: number;
}

interface areaChartDataModel {
    name: string;
    series: areaChartSeries[];
}

interface areaChartSeries {
    value: number;
    name: string;
}