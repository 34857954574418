import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModelCurrency } from '../../../../models/currency.model';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { PreferencesModel } from '../../../../models/preferences.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-currencies',
  templateUrl: './modal-currencies.component.html',
  styleUrls: ['./modal-currencies.component.css']
})
export class ModalCurrenciesComponent implements OnInit {

  @Input() set isModalCalled(value: boolean) {
    value ? this.scrollToCurrency() : null;
  }

  @Input() currencies: ModelCurrency[] = [];
  @Input() currentCurrency?: ModelCurrency;

  @Output() emitter = new EventEmitter<ModelCurrency>();

  ls = LocalStorageManager;
  preferences = PreferencesModel.shared;
  isModalAddCurrencyCalled: boolean = false;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void { }

  setCurrency(currency: ModelCurrency) {
    this.currentCurrency = currency;
  }

  confirmCurrency() {
    this.emitter.emit(this.currentCurrency);
  }

  scrollToCurrency() {
    setTimeout(() => {
      var rows = document.querySelectorAll('#table-currencies tr');
      let currentIndex = this.currencies.indexOf(this.currentCurrency!);
      rows[currentIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
      });
    }, 700);
  }

  showModalAddCurrency() {
    this.isModalAddCurrencyCalled = !this.isModalAddCurrencyCalled
  }
}