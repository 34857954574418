import { MainMenuOption, MainMenuSection } from '../interfaces/MainMenu.interfaces';

export class MainMenuDataManager {

    primarySection: MainMenuOption[] = [
        {title: 'main_menu.menu_home', link: 'Home', iconName: 'menu-home', isRoute : true},
        {title: 'main_menu.menu_movements', link: '/Movements', iconName: 'menu-movements', isRoute : true},
        {title: 'main_menu.menu_categories', link: 'Categories', iconName: 'menu-categories', isRoute : true},
        {title: 'main_menu.menu_accounts', link: 'Accounts', iconName: 'menu-accounts', isRoute : true},
        {title: 'main_menu.menu_agenda', link: 'Schedule', iconName: 'menu-agenda', isRoute : true}
    ];

    reportsSection: MainMenuOption[] = [
        {title: 'main_menu.menu_reports_date', link: 'Reports-by-date', iconName: 'menu-reports-date', isRoute : true},
        {title: 'main_menu.menu_reports_category', link: 'Reports-by-category', iconName: 'menu-reports-category', isRoute : true},
        {title: 'main_menu.menu_trends_category', link: 'Trends-in-categories', iconName: 'menu-trends-category', isRoute : true}
    ];

    secondarySection: MainMenuOption[] = [
        {title: 'main_menu.menu_budgets', link: 'Budgets', iconName: 'menu-budgets', isRoute : true},
        {title: 'main_menu.menu_debts', link: 'Debts', iconName: 'menu-debts', isRoute : true},
        {title: 'main_menu.menu_goals', link: 'Goals', iconName: 'menu-goals', isRoute : true},
        {title: 'main_menu.menu_frequent_records', link: 'Frequent-records', iconName: 'menu-frequent-records', isRoute : true}
    ];

    settingsSection: MainMenuOption[] = [
        {title: 'settions_section.settings_about', link: 'Modal-about', iconName : 'icon-about', isRoute : false},
        {title: 'main_menu.menu_settings', link: 'Options', iconName: 'menu-settings', isRoute : true}
    ];
//    {title: 'main_menu.menu_our_plans', link: 'Plans', iconName: 'menu-plan'},


    data: MainMenuSection[] = [
        {
            title: '',
            options: this.primarySection
        },{
            title: '',
            options: this.reportsSection
        },{
            title: '',
            options: this.secondarySection
        },{
            title: '',
            options: this.settingsSection
        }
    ];
}