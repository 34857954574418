import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { FortnightModel } from 'src/app/models/biweekly.model';
import { SumaryCategoriesCardModel } from 'src/app/components/cards/card-summary-by-categories/model/monthlySummaryCategory.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { PeriodModel } from '../../../common-components/selector-period/Model/periodSelector.Utils';
import { PeriodData, PeriodType } from '../../../common-components/selector-period/interfaces/period.interface';
import { WeekModel } from '../../../../models/week.model';
import { ModelCategories } from '../../../../pages/categories/model/categories.model';
import { ModelMovements } from '../../../../pages/movements/model/movements.model';
import { LocalStorageManager } from '../../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesModel } from '../../../../models/preferences.model';
import { CalendarModel } from '../../../../models/calendar.model';
import { Router } from '@angular/router';
import { SyncAll } from '../../../../services/sync-all.service';

@Component({
  selector: 'app-summary-by-categories',
  templateUrl: './summary-by-categories.component.html',
  styleUrls: ['./summary-by-categories.component.css']
})
export class SummaryByCategoriesComponent implements OnInit {

  isDarkmode = LocalStorageManager.isDarkmode;

  categoriesBalances?: SumaryCategoriesCardModel;
  categories: ModelCategories[] = [];
  movements: ModelMovements[] = [];

  noData = false;

  date: moment.Moment;
  date2: moment.Moment;
  periods = new PeriodModel().periods;
  selectedPeriod: PeriodData;
  selectedWeek: WeekModel;
  selectedFortnight: FortnightModel;
  selectedPeriodtext: string = "";
  preferences: PreferencesModel = PreferencesModel.shared;
  
  selectedAccounts: ModelAccounts[] = [];

  constructor(private services: SyncAll,
      private translate: TranslateService,
      private route: Router) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
    this.selectedAccounts = this.services.selectedAccounts;
    this.categories = this.services.categories.filter(c => c.sign == '-');
    this.movements = this.services.movements;
    this.date = LocalStorageManager.home.getDate();
    let calendar = new CalendarModel(this.date);
    this.selectedPeriod = this.services.currentPeriod;
    this.selectedWeek = calendar.currentWeek;
    this.selectedFortnight = calendar.currentFortnight;
    this.date2 = LocalStorageManager.home.getDate2();
    this.getTitle();
  }

  ngOnInit(): void {
    this.categoriesBalances = new SumaryCategoriesCardModel(
      this.categories, this.selectedAccounts, this.date, this.date2, this.selectedPeriod.period, this.selectedWeek,
      this.selectedFortnight, this.movements
    );
    
    let totals : any = [];
    this.categoriesBalances.categoriesBalances.forEach(category => {
      totals.push(category.total);
    });

    totals = totals.filter((total : any) => total != 0);
    if (totals.length == 0) {
      this.noData = true;
    } else {
      this.categoriesBalances.categoriesBalances.sort((a, b) => ( a.total > b.total ? -1
      : ( ( b.total > a.total ) ) ? 1 : 0 ));
    }
  }

  getTitle(){
    this.selectedPeriodtext = this.services.currentPeriod.title;
    let key = "text_cardviews.";
    switch(this.services.currentPeriod.period){
      case PeriodType.daily:
        key += "card_summary_by_category_by_day";
        break;
      case PeriodType.weekly:
        key += "card_summary_by_category_weekly";
        break;
      case PeriodType.fortnightly:
        key += "card_summary_by_category_biweekly";
        break;
      case PeriodType.monthly:
        key += "card_summary_by_category_monthly";
        break;
      case PeriodType.annually:
        key += "card_summary_by_category_yearly";
        break;
      case PeriodType.byDates:
        key += "card_summary_by_category_date_range";
        break;
    }
    this.selectedPeriodtext = key;
  }

  changePage() {
    this.route.navigate(['Reports-by-category/reports']);
  }
}