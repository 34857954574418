import { Component, Output, EventEmitter, Input } from '@angular/core';

import { MovementTypeModel } from 'src/app/components/common-components/selector-movement-type/Model/movementType.utils';
import { MovementTypes, MovementTypeData } from './interfaces/movement-type.interface';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-selector-movement-type',
  templateUrl: './selector-movement-type.component.html',
  styleUrls: ['./selector-movement-type.component.css']
})
export class SelectorMovementTypeComponent {

  @Input() showAllOption: boolean = true;
  @Input() showTransferOption: boolean = true;
  @Input() currentType: MovementTypes | null = null;

  @Output() emitter = new EventEmitter<MovementTypes>();

  ls = LocalStorageManager;

  movementTypes: MovementTypeData[] = new MovementTypeModel().movementTypes;
  selectedType? : MovementTypes = MovementTypes.all;

  constructor() {}

  emitType(type?: MovementTypes) {
    this.selectedType = type;
    this.emitter.emit(type);
  }
}