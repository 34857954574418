import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

import { ModelAccounts } from 'src/app/pages/accounts/models/accounts.model';
import { ModelCategories } from 'src/app/pages/categories/model/categories.model';
import { ModelMovements } from 'src/app/pages/movements/model/movements.model';
import { ModelSubCategories } from 'src/app/pages/categories/model/subcategories.model';
import { CalendarModel } from 'src/app/models/calendar.model';
import { PreferencesModel } from 'src/app/models/preferences.model';

import { CardControl, CardType } from '../../../components/cards/interfaces/CardsControl.interface';
import { PeriodType, PeriodData } from '../../../components/common-components/selector-period/interfaces/period.interface';
import { PeriodModel } from '../../../components/common-components/selector-period/Model/periodSelector.Utils';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { FortnightModel } from '../../../models/biweekly.model';
import { WeekModel } from '../../../models/week.model';
import { Axis, SelectorDateType } from '../../../components/common-components/selector-date-by-periods/interfaces/selector-date-type.interface';
import { TranslateService } from '@ngx-translate/core';
import { SyncAll } from '../../../services/sync-all.service';
import { CalendarOptions } from 'src/app/interfaces/calendar-options.interface';
import { PickerType } from 'src/app/components/common-components/selector-date-by-periods/selector-date-by-periods.component';

@Component({
  selector: 'app-home',
  templateUrl: './Home.component.html',
  styleUrls: ['./Home.component.css']
})
export class HomeComponent implements OnInit {

  movements: ModelMovements[] = [];
  categories: ModelCategories[] = [];
  subcategories: ModelSubCategories[] = [];
  accounts: ModelAccounts[] = [];
  selectedWeek: WeekModel;
  selectedFortnight: FortnightModel;

  noData: boolean = false;

  cards: CardControl[] = [];
  cardsTypes = CardType;

  isDarkmode = LocalStorageManager.isDarkmode;
  preferences = PreferencesModel.shared;

  date: moment.Moment = LocalStorageManager.home.getDate();
  secondDate: moment.Moment = LocalStorageManager.home.getDate2();

  calendarOptions! : CalendarOptions;
  options2? : CalendarOptions;

  isDragEnabled = false;

  periods = new PeriodModel().periods;
  period = PeriodType;
  selectedPeriod: PeriodData;
  calendar: CalendarModel;

  accountsId = [];
  categoriesId = [];

  loading: boolean = false;
  notHaveData: boolean = false;
  message: string | null = null;
  
  selectedAccounts: ModelAccounts[] = [];

  selectorDateType = SelectorDateType;
  axis = Axis;

  lang = LocalStorageManager.lang;
  typeDate = PickerType.YEAR;

  constructor(private translate: TranslateService,
      private services: SyncAll) {
    this.translate.use(this.lang!);
    this.selectedPeriod = this.services.currentPeriod;
    this.services.calendar = new CalendarModel(this.date);
    this.calendar = this.services.calendar;
    this.selectedWeek = this.services.calendar.currentWeek;
    this.selectedFortnight = this.services.calendar.currentFortnight;
    this.setPeriod(this.selectedPeriod.period);
  }

  ngOnInit() {
    this.loadData();
  }
  
  loadData() {
    this.notHaveData = true;
    this.loading = true;
    
    if (this.services.movements.length == 0) {
      this.services.getAll().then(_ => {        
        this.setSelectedAccounts(this.services.accounts);
        this.loading = false;
        this.notHaveData = false;
      }, reject => {
        if (this.services.accounts.length != 0) {
          this.setSelectedAccounts(this.services.accounts);
        } else {
          this.notHaveAccount(reject);
        }
        this.notHaveData = true;
        this.loading = false;
      });
    } else {
      this.notHaveData = true;
      this.setSelectedAccounts(this.services.accounts);
      this.loading = false;
    }
  }

  notHaveAccount(message: string) {
    this.translate.get(message).subscribe(response => {
      this.message = response;
      this.notHaveData = true;
    });
  }

  getData() {
    this.isDragEnabled = false;
    this.categories = this.services.categories;
    this.movements = this.services.movements;
    this.subcategories = this.services.subcategories;

    this.cards = [];
    setTimeout(() => {
      this.cards = this.services.cards.sort((a,b) => a.order - b.order);
    }, 10);
  }

  changePeriod(period: PeriodType) {
    this.setPeriod(period);
    this.getData();
  }
  
  setPeriod(period: PeriodType) {
    LocalStorageManager.home.setPeriod(period);
    let periodModel = this.periods.find(p => p.period == period)!;
    this.selectedPeriod = periodModel;
    this.services.currentPeriod = periodModel;
    switch (period) {
      case PeriodType.daily:
        this.typeDate = PickerType.DATE;
        this.calendarOptions = { format: 'DD-MMMM-YYYY', locale: this.lang!, maxDate: false};
        break;
      case PeriodType.weekly: case PeriodType.fortnightly: case PeriodType.annually:
        this.typeDate = PickerType.YEAR;
        this.calendarOptions = { format: 'YYYY', locale: this.lang!, maxDate: false };
        break;
      case PeriodType.monthly:
        this.typeDate = PickerType.MONTH;
        this.calendarOptions = { format: 'MMMM-YYYY', locale: this.lang!, maxDate: false};
        break;
      case PeriodType.byDates:
        this.typeDate = PickerType.DATE;
        this.calendarOptions = { format: 'DD-MMMM-YYYY', locale: this.lang!, maxDate: this.secondDate };
        this.options2 = { format: 'DD-MMMM-YYYY', locale: this.lang!, minDate: this.date };
        break;
    }
  }

  changeDate(date: moment.Moment){
    this.services.currentDate = date;
    this.getData();
  }

  changeDate2(date: moment.Moment){
    this.services.currentLastDate = date;
    this.getData();
  }

  setSelectedAccounts(accounts: ModelAccounts[]) {
    this.accounts = this.services.accounts;
    this.selectedAccounts = accounts.filter(a => (a.sharedAccount == null && a.selected == 1) || (a.sharedAccount?.selected));
    this.services.selectedAccounts = this.selectedAccounts;
    this.getData();
  }

  updateCards() {    
    let list = document.getElementById("list")!.getElementsByTagName("li");
    let cardsUpdated: CardControl[] = [];
    let cardButton = this.cards.find(c => c.type == CardType.buttons);
    let cardIndexIncrement: boolean = false
    for (let i = 0; i < list!.length; i++) {
      const element = list[i];
      let card = this.cards.find(c => c.id == element.value)!;
      if (cardButton?.order == i) {
        cardIndexIncrement = true;
      }
      card.order = cardIndexIncrement ? i + 1 : i;
      cardsUpdated.push(card);
    }
    this.toggleDragDropMode()

    this.services.updateCards(cardsUpdated).then(_ => {
    }, _ => {
      console.log('error has ocurred');
    });
  }

  toggleDragDropMode() {
    this.isDragEnabled = !this.isDragEnabled;
  }

  toggleCardHidden(card: CardControl) {
    card.hidden = !card.hidden;
  }

  onSelectedWeek() {
    this.services.calendar.currentWeek = this.selectedWeek;
    this.getData();
  }

  onSelectedFortnight() {
    this.services.calendar.currentFortnight = this.selectedFortnight;
    this.getData();
  }

  setDate(date: moment.Moment) {
    this.date = date;
    LocalStorageManager.home.setDate(date);
    this.services.currentDate = date;
    this.changeDate(date);
  }

  setDate2(date2: moment.Moment) {
    this.secondDate = date2;
    LocalStorageManager.home.setDate2(date2);
    this.changeDate(this.date);
  }
}