import { PeriodType } from "src/app/components/common-components/selector-period/interfaces/period.interface";
import * as moment from 'moment';

export class LocalStorageHome {

    private keyPeriod: string = 'LSHomePeriod'
    private keyDate: string = "LSHomeDate";
    private keyDate2: string = "LSHomeDate2";

    getDate(): moment.Moment {
        return localStorage.getItem(this.keyDate) != undefined ? moment(localStorage.getItem(this.keyDate)) : moment(new Date());
    }

    setDate(date: moment.Moment) {
        localStorage.setItem(this.keyDate, date.toString());
    }

    setDate2(date: moment.Moment) {
        localStorage.setItem(this.keyDate2, date.toString());
    }

    getDate2(): moment.Moment {
        return moment((localStorage.getItem(this.keyDate2) != undefined) ? localStorage.getItem(this.keyDate2) : new Date());
    }

    getPeriod(): PeriodType {
        let period = localStorage.getItem(this.keyPeriod);
        return period != null ? +period : 3;
    }
    
    setPeriod(period: PeriodType) {
        localStorage.setItem(this.keyPeriod, period.toString())
    }

    removeAll() {
        localStorage.removeItem(this.keyPeriod);
        localStorage.removeItem(this.keyDate);
        localStorage.removeItem(this.keyDate2);
    }
}