import { Injectable } from '@angular/core';
import { ModelAccounts } from '../pages/accounts/models/accounts.model';
import { ModelCategories } from '../pages/categories/model/categories.model';
import { ModelSubCategories } from '../pages/categories/model/subcategories.model';
import { ModelMovements } from '../pages/movements/model/movements.model';
import { CrudService } from './crud.service';
import { ResponseSyncAll, UserData,  UserCard, TableGoal, SubscriptionData, TableAccount, TableCategory, CategoryData, TableSubcategory, AccountData, TableDebt, TableFrequentOperation, TableMovements, TableDebtsRecord, TableGoalsRecord, FkData, TableBudget, SharedData, SharedUsersAccount/* , UserPreference, UserSubscription */ } from './interfaces/syncAll.interface';
import { ModelGoals } from '../pages/goals/models/goals.model';
import { ModelDebts } from '../pages/debts/model/debts.model';
import { ModelFrequentOperations } from '../pages/frequent-records/model/frequentoperations.model';
import { ModelDebtRecords } from '../pages/debts/model/debtRecords.model';
import { ModelGoalMovement } from '../pages/goals/models/goalmovements.model';
import { ModelBudgets } from '../pages/budgets/model/budgets.model';
import { CardsModel } from '../components/cards/model/cards.model';
import { LocalStorageManager } from '../utils/local-storage-manager/local-storage-manager.utils';
import * as moment from 'moment';
import { PeriodData, PeriodType } from '../components/common-components/selector-period/interfaces/period.interface';
import { CalendarModel } from '../models/calendar.model';
import { CardsComponents } from '../components/cards/model/cards-components';
import { CardControl } from '../components/cards/interfaces/CardsControl.interface';
import { CardsService } from '../components/cards/services/cards.service';
import { PeriodModel } from '../components/common-components/selector-period/Model/periodSelector.Utils';
import { SharedAccountsModel } from '../pages/accounts/models/sharedAccounts.model';


@Injectable({
    providedIn: 'root'
  })
export class SyncAll {
  
    currentDate: moment.Moment = LocalStorageManager.home.getDate();
    currentLastDate: moment.Moment = moment();
    currentPeriod: PeriodData;
    calendar = new CalendarModel(this.currentDate);

    private _accounts: ModelAccounts[] = [];
    private _all_accounts : ModelAccounts[] = [];
    private _categories: ModelCategories[] = [];
    private _subcategories: ModelSubCategories[] = [];
    private _movements: ModelMovements[] = [];
    private _debts: ModelDebts[] = [];
    private _frequent_operations : ModelFrequentOperations[] = [];
    private _goals : ModelGoals[] = [];
    private _budgets : ModelBudgets[] = [];
    private _debts_records : ModelDebtRecords[] = [];
    private _goals_records : ModelGoalMovement[] = [];
    private _user_cards : CardsModel[] = [];
    cards: CardControl[] = [];

    //private _pictures: ModelPictures


    selectedAccounts: ModelAccounts[] = [];
    selectedCategories : ModelCategories[] = [];


    public get accounts(): ModelAccounts[] {
        return [...this._accounts.filter(account => account.deleted == 0 && account.shown == 1)];
    }

    public get subcategories(): ModelSubCategories[] {
        return [...this._subcategories.filter(subcategory => subcategory.shown == 1 && subcategory.deleted == 0)];
    }

    public get categories(): ModelCategories[] {
        return [...this._categories.filter(category => category.shown == 1 && category.deleted == 0)];
    }

    public get movements(): ModelMovements[] {
        return [...this._movements];
    }

    public get debts() : ModelDebts[] {
        return [...this._debts];
    }

    public get frequentOperations() : ModelFrequentOperations[] {
        return [...this._frequent_operations];
    }

    public get goals() : ModelGoals[] {
        return [...this._goals];
    }

    public get budgets() : ModelBudgets[] {
        return [...this._budgets];
    }

    public get debtsRecords() : ModelDebtRecords[] {
        return [...this._debts_records];
    }

    public get goalsRecords() : ModelGoalMovement[] {
        return [...this._goals_records];
    }

    public get userCards() : CardsModel[] {
        return [...this._user_cards];
    }

    public get allAccounts() : ModelAccounts[]{
        return [...this._all_accounts];
    }

    constructor(private services: CrudService<ResponseSyncAll>,  private cardsServices: CardsService) {
        let periods = new PeriodModel().periods;
        this.currentPeriod = periods.find(p => p.period == LocalStorageManager.home.getPeriod()! ?? PeriodType.monthly)!;
    }

    getAll() {
        const params = {
            'pk_user' : this.services.userData?.user_id,
            'pk_subscription' : this.services.userData?.subscription_id,
            'date_last_sync' : "",
            'request_sync' : 0
        };
        return new Promise((resolve, reject) => {
            this.services.sync(params).subscribe(response => {
                if (+response.status == 1) {
                    this.loadUserAccounts(response);
                    this.loadSubscriptionData(response);
                    this.loadCategoryData(response);
                    this.loadAccountData(response);
                    this.loadFkData(response);
                    this.loadSharedData(response);
                    this.associate_records();
                    resolve(true);
                } else {
                    reject(response.message);
                }
                //this.shared_data()
            }, rejectMessage => {
                reject(rejectMessage);
            }); 
        });
    }

    loadUserAccounts(response : ResponseSyncAll) {
        const user_data : UserData = response.data.user_data as UserData;
        const user_cards : UserCard[] = user_data.user_cards;
        const table_goals : TableGoal[] = user_data.table_goals;
        /* const user_preference : UserPreference[] = user_data.user_preferences;
        const user_subscription : UserSubscription[] = user_data.user_subscriptions; */
        this._goals = this.myGoalsModels(table_goals);
        this._user_cards = this.myCardsModels(user_cards);
        let cardArrayInit = new CardsComponents(this._user_cards);
        this.cards = cardArrayInit.cards;
    }

    myGoalsModels(table_goals : TableGoal[]) : ModelGoals[] {
        let goals : ModelGoals[] = [];

        if (table_goals == undefined) {
            return [];
        }
        table_goals.forEach(record => {
            if (record.pk_goal) {
                let model = new ModelGoals(
                    +record.pk_goal,
                    record.name,
                    +record.amount,
                    record.date_desired,
                    record.color_hex,
                    +record.status,
                    record.icon_name,
                    +record.fk_user
                );
            goals.push(model);    
            }
        });
        return goals;
    }

    myCardsModels(table_user_cards : UserCard[]) : CardsModel[] {
        let user_cards : CardsModel[] = [];

        if (table_user_cards == undefined) {
            return [];
        }
        table_user_cards.forEach(record => {
            if (record.pk_user_card) {
                let model = new CardsModel(
                    +record.pk_user_card,
                    +record.shown,
                    +record.order_card,
                    "",
                    +record.fk_preference,
                    +record.fk_card
                );
                user_cards.push(model);    
            }
        });
        return user_cards;
    }

    loadSubscriptionData(response : ResponseSyncAll) {
        const subcription_data : SubscriptionData = response.data.subscription_data as SubscriptionData;        
        const accounts : TableAccount[] = subcription_data.table_accounts;
        const categories : TableCategory[] = subcription_data.table_categories;
        this._all_accounts = this.myAccountsModels(accounts);
        this._accounts = this._all_accounts.filter(account => account.deleted == 0 && account.shown == 1);
        this._categories = this.myCategoriesModels(categories);
    }

    myAccountsModels(table_accounts: TableAccount[]) : ModelAccounts[] {
        let accounts : ModelAccounts[] = [];
        if (table_accounts == undefined) {
            return []
        }
        table_accounts.forEach(record => {
            if (record.pk_account) {
                let model = new ModelAccounts(
                    +record.pk_account,
                    record.account_name,
                    +record.type,
                    record.detail,
                    +record.initial_balance,
                    record.sign,
                    record.icon_name,
                    +record.selected,
                    +record.negative_limit,
                    +record.positive_limit,
                    +record.rate,
                    +record.shown,
                    +record.deleted,
                    record.color_hex,
                    record.web_color,
                    +record.fk_subscription,
                    +record.fk_currency
                );
                accounts.push(model);   
            }
        });
        return accounts;
    }

    myCategoriesModels(table_categories: TableCategory[]) : ModelCategories[] {
        let categories : ModelCategories[] = [];
        if (table_categories == undefined) {
            return [];
        }
        table_categories.forEach(record => {
            if (record.pk_category) {
                let model = new ModelCategories(
                    +record.pk_category,
                    record.name,
                    record.sign,
                    record.icon_name,
                    record.color_hex,
                    +record.fk_subscription,
                    +record.shown,
                    +record.deleted
                );
                categories.push(model);    
            }
        });
        return categories;
    }

    loadCategoryData(response: ResponseSyncAll) {
        const category_data : CategoryData = response.data.category_data as CategoryData;
        const table_subcategory : TableSubcategory[] = category_data.table_subcategories;
        this._subcategories = this.mySubcategoriesModels(table_subcategory);
    }

    mySubcategoriesModels(table_subcategory : TableSubcategory[]) : ModelSubCategories[]{
        let subcategories : ModelSubCategories[] = [];

        if (table_subcategory == undefined) {
            return [];
        }
        table_subcategory.forEach(record => {
            if (record.pk_subcategory) {
                let model = new ModelSubCategories(
                    +record.fk_category, 
                    +record.pk_subcategory,
                    record.name,
                    record.icon_name,
                    +record.deleted,
                    +record.shown
                );
                subcategories.push(model);    
            }
        });
        return subcategories;
    }

    loadAccountData(response : ResponseSyncAll) {
        const account_data : AccountData = response.data.account_data as AccountData;
        const table_debts : TableDebt[] = account_data.table_debts;
        const table_frequent_operations : TableFrequentOperation[] = account_data.table_frequent_operations;
        const table_movements : TableMovements[] = account_data.table_movements;

        this._debts = this.myDebtsModels(table_debts);
        
        this._frequent_operations = this.myFrequentOperationsModels(table_frequent_operations);
        this._movements = this.myMovementsModels(table_movements);
    }

    myDebtsModels(table_debts : TableDebt[]) : ModelDebts[]{
        let debts : ModelDebts[] = [];

        if (table_debts == undefined) {
            return [];
        }
        table_debts.forEach(record => {
            if (record.pk_debt) {
                let model = new ModelDebts(
                    +record.pk_debt,
                    record.name,
                    record.detail,
                    +record.amount,
                    record.date_loan,
                    record.date_expiration,
                    +record.status,
                    record.icon_name,
                    record.sign,
                    +record.fk_account,
                    +record.show_home
                );
                debts.push(model);
            }
        });
        return debts;
    }

    myFrequentOperationsModels(table_frequent_operations: TableFrequentOperation[]) : ModelFrequentOperations[] {
        let frequent_operations : ModelFrequentOperations[] = [];
        
        
        if (table_frequent_operations == undefined) {
            return [];
        }
        table_frequent_operations.forEach(record => {
            if (record.pk_frequent_operation) {
                let model = new ModelFrequentOperations(
                    +record.pk_frequent_operation,
                    record.name,
                    +record.period,
                    +record.every,
                    +record.repeated,
                    +record.countered,
                    record.date_initial,
                    record.date_next,
                    +record.amount,
                    record.sign,
                    +record.transfer,
                    record.detail,
                    +record.status,
                    +record.fk_account,
                    +record.fk_to_account,
                    +record.fk_category,
                    +record.fk_subcategory,
                    record.migration_code
                );
                frequent_operations.push(model);
            }
        });
        return frequent_operations;
    }

    myMovementsModels(table_movements : TableMovements[]) : ModelMovements[] {
        let movements : ModelMovements[] = [];
        if (table_movements == undefined) {
            return [];
        }
        table_movements.forEach(record => {
            if (record.pk_movement) {
                let model = new ModelMovements();
                const account : ModelAccounts = this.findAccount(+record.fk_account)!;
                const category : ModelCategories = this.findCategory(+record.fk_category)!;
                const subcategory : ModelSubCategories = this._subcategories.find(subcategory => subcategory.id == +record.fk_subcategory)!;
                model.init(
                    +record.pk_movement,
                    +record.amount,
                    record.sign,
                    record.detail,
                    record.latitude,
                    record.longitude,
                    record.place_name,
                    record.beneficiary,
                    record.date,
                    record.status,
                    +record.transfer,
                    +record.fk_account,
                    +record.fk_category,
                    +record.fk_subcategory,
                    +record.banking,
                    +record.transfer_code,
                    account,
                    category,
                    subcategory
                );
                movements.push(model);   
            }
        });
        return movements;
    }

    loadFkData(response : ResponseSyncAll) {
        const fk_data = response.data.fk_data as FkData;
        const table_budgets : TableBudget[] = fk_data.table_budgets;
        const table_debts_records : TableDebtsRecord[] = fk_data.table_debts_records;
        const table_goals_records : TableGoalsRecord[] = fk_data.table_goals_records;
        //const table_pictures : TablePicture[] = fk_data.table_pictures;

        this._budgets = this.myBudgetsModels(table_budgets);
        this._debts_records = this.myDebtsRecordsModels(table_debts_records);
        this._goals_records = this.myGoalsRecordsmodels(table_goals_records);
        
    }

    loadSharedData(response : ResponseSyncAll) {
        let shared_data = response.data.shared_data as SharedData;
        let accounts = this.myAccountsModels(shared_data.table_accounts);
        let categories = this.myCategoriesModels(shared_data.table_categories);
        let movements = this.movementsModelShared(shared_data.table_movements, accounts, categories);
        let accountShared = this.accountSharedModel(shared_data.shared_users_accounts);
        movements.forEach(movement => {
            movement.shared = true;
            this._movements.push(movement);
        });
        accounts.forEach(account => {
            account.sharedAccount = accountShared.find(record => record.fkAccount == account.pkAccount);
            account.selected = account.sharedAccount?.selected ? 1 : 0;
            account.movements = movements.filter(movement => movement.fkAccount == account.pkAccount);
            this._accounts.push(account);
            this._all_accounts.push(account);
        });
        categories.forEach(category => {
            category.shared = true;
            category.movements = movements.filter(movement => movement.fkCategory == category.id);
            this._categories.push(category);
        });
    }

    accountSharedModel( accountShared : SharedUsersAccount[]) : SharedAccountsModel[] {
        let accountSharedModel : SharedAccountsModel[] = [];
        if (accountShared == undefined) {
            return [];
        }
        accountShared.forEach(record => {
          if (record.pk_shared_account) {
            let model = new SharedAccountsModel(
              +record.can_add,
              +record.can_delete,
              +record.can_update,
              +record.fk_account,
              +record.fk_shared,
              +record.pk_shared_account,
              (record.selected == '1' ? true : false)
            );
            accountSharedModel.push(model);
          }
        });
        return accountSharedModel;
      }

    movementsModelShared(table_movements : TableMovements[], accounts : ModelAccounts[], categories : ModelCategories[]) : ModelMovements[] {
        let movements : ModelMovements[] = [];
        if (table_movements == undefined) {
            return [];
        }
        table_movements.forEach(record => {
            if (record.pk_movement) {
                let model = new ModelMovements();
                const account : ModelAccounts = accounts.find(account => account.pkAccount == +record.fk_account)!;
                const category : ModelCategories = categories.find(category => category.id == +record.fk_category)!;
                model.init(
                    +record.pk_movement,
                    +record.amount,
                    record.sign,
                    record.detail,
                    record.latitude,
                    record.longitude,
                    record.place_name,
                    record.beneficiary,
                    record.date,
                    record.status,
                    +record.transfer,
                    +record.fk_account,
                    +record.fk_category,
                    +record.fk_subcategory,
                    +record.banking,
                    +record.transfer_code,
                    account,
                    category
                );
                movements.push(model);   
            }
        });
        return movements;
    }

    myBudgetsModels(table_budgets : TableBudget[]) : ModelBudgets[] {
        let budgets : ModelBudgets[] = [];
        if (table_budgets == undefined) {
            return [];
        }
        table_budgets.forEach(record => {
            if (record.pk_budget) {
                let model = new ModelBudgets(
                    +record.pk_budget,
                    +record.amount,
                    +record.period,
                    +record.number,
                    +record.year,
                    +record.only_once,
                    +record.show_home,
                    +record.show_home,
                    +record.fk_account,
                    +record.fk_category,
                    +record.fk_subcategory,
                    +record.fk_subscription
                );
                budgets.push(model);    
            }
        });
        return budgets;
    }

    myDebtsRecordsModels(table_debts_records : TableDebtsRecord[]) : ModelDebtRecords[] {
        let debts_records : ModelDebtRecords[] = [];
        if (table_debts_records == undefined) {
            return [];
        }
        table_debts_records.forEach(record => {
            if (record.pk_debt_record) {
                let model = new ModelDebtRecords(
                    +record.amount,
                    record.date,
                    record.detail,
                    +record.fk_debt,
                    record.sign,
                    +record.fk_account,
                    +record.pk_debt_record
                );
                debts_records.push(model);    
            }
        });
        return debts_records;
    }

    myGoalsRecordsmodels(table_goals_records : TableGoalsRecord[]) : ModelGoalMovement[] {
        let goals_records : ModelGoalMovement[] = [];
        if (table_goals_records == undefined) {
            return [];
        }
        table_goals_records.forEach(record => {
            if (record.pk_goal_record) {
                let model = new ModelGoalMovement(
                    +record.pk_goal_record,
                    record.date,
                    +record.amount,
                    +record.fk_goal
                );
                goals_records.push(model);    
            }
        });
        return goals_records;
    }

    updateCards(cards: CardControl[]) { return this.cardsServices.updateCards(cards); }

    deleteMovement(pk_movement : number) {
        this._movements = this.movements.filter(movement =>  pk_movement != movement.pkMovement);
    }

    deleteMovementsArray(pk_movements : number[] ) {
        this._movements = this.movements.filter(movement =>  !pk_movements.includes(movement.pkMovement!));
    }

    updateSelecctionMultiple(form : any, movements : ModelMovements[]){
        this.changeMovements(form, movements);
        let pk_movements : number[] = form['pk_movements'];
        this._movements.filter(movements => pk_movements.includes(movements.pkMovement!)).forEach(current_movement => {
            let movement = movements.find(model => model.pkMovement == current_movement.pkMovement);
            current_movement = movement!;
        });
    }

    private changeMovements(params : any, movements: ModelMovements[]) {
        movements.forEach(movement => {
          if (params['column'] = "fk_account") {
            let fk_account = params['fk'];
            movement.fkAccount = fk_account;
          } else {
            let fkCategory = params['fk'];
            let fkSubcategory = params['fk_2'];
            movement.fkSubcategory = fkSubcategory;
            movement.fkCategory = fkCategory;
          }
        });
    }

    getAccountCountMovements(account: ModelAccounts) : number{
        return this._movements.filter(movement => movement.fkAccount == account.pkAccount).length;
    }

    deleteAccountArray(select_account: ModelAccounts) {
        this._accounts = this._accounts.filter(account => account.pkAccount != select_account.pkAccount);
        let accountFind = this._all_accounts.find(account => account.pkAccount == select_account.pkAccount);
        if (accountFind) {
            accountFind.deleted = 1;
        }        
    }

    deleteMovementsByAccountArray(select_account : ModelAccounts) {
        this._movements = this._movements.filter(movement => movement.fkAccount != select_account.pkAccount);
    }

    deleteBudgetArray(select_budget : ModelBudgets) {
        this._budgets = this._budgets.filter(budget => budget.pkBudget != select_budget.pkBudget);
    }

    deleteDebtArray(select_debt : ModelDebts) {
        this._debts = this._debts.filter(debt => debt.pkDebt != select_debt.pkDebt);
    }

    deleteDebtRecordArray(select_record : ModelDebtRecords) {
        this._debts_records = this._debts_records.filter(record => record.pkDebtRecord != select_record.pkDebtRecord);
        let debt = this._debts.find(debt => debt.pkDebt == select_record.fkDebt);
        debt!.records = debt!.records.filter(record => record.pkDebtRecord != select_record.pkDebtRecord)!;
    }

    deleteGoalArray(select_goal : ModelGoals) {
        this._goals = this._goals.filter(goal => goal.pkGoal != select_goal.pkGoal);
    }

    deleteGoalRecordArray(select_record : ModelGoalMovement) {
        this._goals_records = this._goals_records.filter(record => record.pkGoalRecord != select_record.pkGoalRecord);
        let goal = this._goals.find(goal => goal.pkGoal == select_record.fkGoal);
        goal!.movements = goal!.movements.filter(record => record.pkGoalRecord != select_record.pkGoalRecord);
    }

    calculateBalance(account: ModelAccounts){
        let finalBalance = account.type == 0 ? -account.initialBalance + account.getBalance() : account.initialBalance + account.balance;
        account.balance = +finalBalance;
        account.setPercentage();
    }

    associate_records() {
        this._all_accounts.forEach(account => {
            account.movements = this._movements.filter(movement => movement.fkAccount == account.pkAccount);
        });
        this._accounts = this._all_accounts.filter(account => account.deleted == 0);
        this.categories.forEach(category => {
            category.movements = this._movements.filter(movement => movement.fkCategory == category.id);
        });
        this._subcategories.forEach(subcategory => {
            subcategory.category = this.findCategory(subcategory.fkCategory)!;
        });
        this._goals.forEach(goal => {
            goal.movements = this._goals_records.filter(movement => movement.fkGoal == goal.pkGoal);
        });
        this._debts.forEach(debt => {
            debt.records = this._debts_records.filter(record => record.fkDebt == debt.pkDebt);
            debt.account = this.findAccount(debt.fkAccount)!;
        });
        this._budgets.forEach(budget => {
            budget.account = budget.fkAccount != null ? this.findAccount(budget.fkAccount) : null;
            budget.category = budget.fkCategory != null ? this.findCategory(budget.fkCategory) : null;
            budget.subcategory = budget.fkSubcategory != 0 ? this._subcategories.find(subcategory => subcategory.id == budget.fkSubcategory) : null;
            budget.allMovements = this._movements;
        });
        this._frequent_operations.forEach(fo => {
            fo.account = fo.fkAccount != 0 ? this.findAccount(fo.fkAccount) : null;
            fo.category = fo.fkCategory != 0 ? this.findCategory(fo.fkCategory) : null;
            fo.subcategory = fo.fkSubcategory != 0 ? this.findSubcategory(fo.fkSubcategory) : null;
            fo.account2 = fo.fkToAccount != 0 ? this.findAccount(fo.fkToAccount) : null;
        })
        this.appendMovements(this._budgets, this._movements);
    }

    appendMovements(budgets: ModelBudgets[], movements: ModelMovements[]) {
        budgets.forEach( budget => {
          if (budget.account?.pkAccount == -1 && budget.category?.id == -1) {
            budget.allMovements = movements;
          } else if (budget.account?.pkAccount == -1) {
            budget.allMovements = movements.filter( movement => movement.fkCategory == budget.fkCategory);
          } else if (budget.category?.id == -1){
            budget.allMovements = movements.filter( movement => movement.fkAccount == budget.fkAccount);
          } else {
            const bugetMovements: ModelMovements[] = movements.filter( movement =>
              movement.fkCategory == budget.fkCategory
              && movement.fkAccount == budget.fkAccount
            );
            budget.allMovements = bugetMovements;
          }
        });
    }

    findAccount(fk_account : number) : ModelAccounts | null {
        return this.accounts.find(account => account.pkAccount == fk_account)!;
    }

    findCategory(fk_category : number) : ModelCategories | null {
        return this._categories.find(category => category.id == fk_category)!;
    }

    findSubcategory(fk_subcategory : number) : ModelSubCategories | null {
        return this.subcategories.find(subcategory =>  subcategory.id == fk_subcategory)!;
    }

    updateBudget(current_budget : ModelBudgets) {
        let budget = this._budgets.find(budget => current_budget.pkBudget == budget.pkBudget);
        if (budget) {
            budget = current_budget;
        } else {
            this._budgets.push(current_budget);
        }
    }

    updateDebt(current_debt : ModelDebts) {
        let debt = this._debts.find(debt =>  debt.pkDebt == current_debt.pkDebt);
        if (debt) {
            debt = current_debt;
        } else {
            this._debts.push(current_debt);
        }
    }

    updateDebtRecord(current_record: ModelDebtRecords) {
        let record = this._debts_records.find(record => record.pkDebtRecord == current_record.pkDebtRecord);
        let debt = this._debts.find(debt => debt.pkDebt == current_record.fkDebt);
        if (record) {
            record = current_record;
        } else {
            this._debts_records.push(current_record);
            debt?.records.push(current_record);
        }
    }

    updateGoal(current_goal : ModelGoals) {
        let goal = this._goals.find(goal => goal.pkGoal == current_goal.pkGoal);
        if (goal) {
            goal = current_goal;
        } else {
            this._goals.push(current_goal);
        }
    }

    updateGoalRecord(current_record: ModelGoalMovement) {
        let record = this._goals_records.find(record => record.pkGoalRecord == current_record.pkGoalRecord);
        let goal = this._goals.find(goal => goal.pkGoal == current_record.pkGoalRecord);
        if (record) {
            record = current_record;
        } else {
            this._goals_records.push(current_record);
            goal?.movements.push(current_record);
        }
    }

    updateMovements(movements : ModelMovements[]) {
        movements.forEach(current_movement => {
            let movement = this._movements.find(movement => movement.pkMovement == current_movement.pkMovement);
            if (movement) {
                movement = current_movement;
            } else {
                this._movements.push(current_movement);
            }
        });
    }
}