import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModelCategories } from 'src/app/pages/categories/model/categories.model';
import { LocalStorageManager } from 'src/app/utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-selector-multi-categories',
  templateUrl: './selector-multi-categories.component.html',
  styleUrls: ['./selector-multi-categories.component.css']
})
export class SelectorMultiCategoriesComponent implements OnInit {
  @Input() categories : ModelCategories[] = [];
  @Input() selectedCategoriesPks : number[] = [];
  @Input() showSelectAll : boolean = false;
  @Input() showShared : boolean = true;
  @Input() limit : number = 0;

  @Output() emitter = new EventEmitter<ModelCategories[]>();

  ls = LocalStorageManager;
  selectAll : boolean = false;

  constructor( private translate: TranslateService) {
    this.translate.use(this.ls.lang!);
  }

  ngOnInit(): void {
    this.checkAll();
    this.categories.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    this.changeSelection(this.selectedCategoriesPks);
  }

  ngOnChanges(): void {
    this.checkAll();
    this.categories.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  checkAll() {
    if (!this.showSelectAll) {
      this.selectAll = this.categories.length == this.selectedCategoriesPks.length;      
    }
  }

  changeSelection(categoriesSelections: number[]) {
    this.selectedCategoriesPks = categoriesSelections;
    if (this.categories.length == this.selectedCategoriesPks.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }  
    const selectCategories = this.categories.filter(category => this.selectedCategoriesPks.includes(category.id));
    this.emitter.emit(selectCategories);
  }

  isSelected(category: ModelCategories) {
    const select =  this.selectedCategoriesPks.includes(category.id);
    return select;
  }

  emitSelectAll(selectAll: boolean) {
    this.selectAll = selectAll;
    this.setSelectAll();
  }

  setSelectAll() {
    const selectCategories = this.categories;
    this.emitter.emit(selectCategories);
  }
}
