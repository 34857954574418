import { ModelAccounts } from '../../accounts/models/accounts.model';
import { ModelDebtRecords } from './debtRecords.model';

export class ModelDebts {

    pkDebt: number;
    name: string;
    detail: string;
    amount: number;
    dateLoan: string;
    dateExpiration: string;
    status: number;
    icon: string;
    sign: string;
    fkAccount: number;
    showHome: number;
    account: ModelAccounts | null;
    records: ModelDebtRecords[];
    clickDebt : boolean = false;

    constructor(pkDebt: number, name: string, detail: string, amount: number, dateLoan: string,
                dateExpiration: string, status: number, icon: string, sign: string, fkAccount: number,
                showHome: number, account: ModelAccounts | null = null, records: ModelDebtRecords[] = []) {

        this.pkDebt = pkDebt;
        this.name = name;
        this.detail = detail;
        this.amount = amount;
        this.dateLoan = dateLoan;
        this.dateExpiration = dateExpiration;
        this.status = status;
        this.icon = icon;
        this.sign = sign;
        this.fkAccount = fkAccount;
        this.showHome = showHome;
        this.account = account;
        this.records = records;
    }

    getBalance() {
        if (this.sign == "+") {
            let income = this.getSum();
            let expense = this.sumPlusDebt();
            return this.amount - income + expense;
        } else {
            let income = this.getSum();
            let expense = this.sumPlusDebt();
            return this.amount + income - expense;
        }
    }

    getTotalDebt() {
        if (this.sign == "+") {
            return this.amount + this.sumPlusDebt();
        } else {
            return this.amount + this.getSum();
        }
    }

    getPercentage() {
        let percentage = ((this.sign == "+" ? this.getSum() : this.sumPlusDebt()) / this.getTotalDebt()) * 100;
        return percentage < 0 ? 0.00 : ( percentage <= 100 ? percentage.toFixed(2) : 100.00);
    }

    getSum() {
        let total = 0;
        this.records.forEach(record => {
            if (record.sign == '+'  && record.pkDebtRecord != 0) {
                total += +record.amount;
            }
        });
        return total;
    }

    sumPlusDebt() {
        let total = 0;
        this.records.forEach(record => {
            if (record.sign == '-' && record.pkDebtRecord != 0) {
                total += +record.amount;
            }
        });
        return total;
    }
}