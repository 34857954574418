import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { WeekModel } from './week.model';
import { FortnightsByYear, FortnightModel } from './biweekly.model';

export class CalendarModel {

    weeksOfYear: WeekModel[] = [];
    fortnightsOfYear: FortnightModel[] = [];
    date: moment.Moment;
    datePipe = new DatePipe('es');

    currentWeek: WeekModel;
    currentFortnight: FortnightModel;

    constructor(date: moment.Moment) {
        this.date = date;
        this.weeksOfYear = this.getWeeksOfYear(date.year());
        this.fortnightsOfYear = this.getFortnightsOfYear(date.year());

        this.currentWeek = this.weeksOfYear.find(w => w.weekNumber == this.date.week() - 1)!;
        this.currentFortnight = this.getCurrentFortnight();
    }

    private getWeeksOfYear(year: number) {
        //this.date.isoWeekday(0);
        const numberOfWeeks = this.date.weeksInYear();
        const weeks: WeekModel[] = [];
        for (let index = 0; index <= numberOfWeeks; index++) {
            weeks.push( new WeekModel(index, year));
        }
        return weeks;
    }

    getFortnightsOfYear(year: number) {
        const fortnights = new FortnightsByYear(year).fortnights;
        return fortnights;
    }

    private getCurrentFortnight(): FortnightModel {
        return this.fortnightsOfYear.find(f => (f.month.number == (this.date.month() + 1)) && (f.initialDay <= this.date.date() && f.finalDay >= this.date.date()))!;
    }

    getFortnightFromDate(date: moment.Moment): FortnightModel {
        const fortnights = this.getFortnightsOfYear(date.year());
        return fortnights.find(f => (date.date() >= f.initialDay && date.date() <= f.finalDay) && f.month.number == date.month() + 1)!;
    }
}