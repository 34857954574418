<div 
    class="row input-border-light no-margin" 
    [ngClass]="{'bg-gray clear-outline': isDarkmode}"
    [ngStyle]="!isDarkmode ? {'border-style': 'solid'} : ''"
    >
    <div class="col-2">
        <img
            class="mt-1"
            src="assets/images/icon-month.png"
            width="30" height="30"
            [ngClass]="{'filter-white': isDarkmode}"
            >
    </div>
    <div class="col-10 p-1">
        <mat-form-field
            class="col-12 remove-background-form"
            [ngClass]="{'margin-left-10' : positionTextCenter, 'margin-left-15' : !positionTextCenter}"
            (click)="picker.open()"
            >
            <div class="col-12">
                <input
                    formControlName="date"
                    [ngClass]="{'white-text': isDarkmode, 'text-center' : positionTextCenter, 'text-start' : !positionTextCenter}"
                    class="form-control removeBackground input-border-light font-secondary pointer dark-mode"
                    type="button"
                    [value]="getDate()"
                    />

                <input
                    for="picker"
                    style="display: none;"
                    hidden
                    matInput
                    height="0px"
                    width="0px"
                    [matDatepicker]="picker"
                    [value]="currentDate"
                    (dateChange)="setDate($event?.value)"
                    />
                <mat-datepicker #picker color="primary"></mat-datepicker>
            </div>
    </mat-form-field>
    </div>
</div>