<div
    class="container center-on-screen animate__animated animate__fadeIn mh" 
    [ngClass]="{'white-text': isDarkmode}"
    >
    <div class="row mt-3">
        <div class="col text-center">
            <img
                height="{{imgHeight}}"
                width="{{imgWidht}}"
                class="img-fluid mx-auto d-block" 
                src="{{urlImage}}"
                >
            <h4 
                class="mt-5 text-title-color-discreet"
                >{{ title | translate }}</h4>
            <p *ngIf="body != null" class="text-body-color-discreet">{{  body | translate }}</p>
            <p *ngIf="body2 != null" class="text-body-color-discreet">{{  body2 | translate }}</p>
        </div>
    </div>
    <!--"empty_list.empty_movements_body"-->
</div>          