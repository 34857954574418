import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { SegmentedSelectorItem } from './interfaces/segmented-selector.interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-segmented-selector',
  templateUrl: './segmented-selector.component.html',
  styleUrls: ['./segmented-selector.component.css']
})
export class SegmentedSelectorComponent<T> implements OnInit {

  @Input() items: SegmentedSelectorItem<T>[] = [];
  @Input() currentItem! : T;
  @Input() smallSize: boolean = false;

  @Output() emitter = new EventEmitter<T>();

  localStorage = LocalStorageManager;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnChanges(): void {
    this.items.forEach(item => {
      item.isSelected = item.value == this.currentItem;
    });
  }

  ngOnInit(): void {
  }

  setItem(item: T) {
    this.currentItem = item;
    this.emitter.emit(item);
  }

}
