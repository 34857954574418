import { Pipe, PipeTransform } from '@angular/core';
import { SettingsDateFormat } from '../pages/options/interface/date-format.interface';
import { PreferencesModel } from '../models/preferences.model';
import * as moment from 'moment';
import { DateFormat, TimeFormat } from '../pages/options/components/cards/card-date-format/model/card-date-format.model';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  preferences = PreferencesModel.shared;

  transform(value:string, showTime:boolean = true, settings:SettingsDateFormat = this.preferences.dateSettings): string {
    let date = moment(value);
    if (showTime) {
      switch (settings.dateFormat) {
        case DateFormat.dd_mm_aaaa:
          return (settings.timeFormat == TimeFormat.time24) ? date.format('HH:mm') : date.format('h:mm A');  
        //return (settings.timeFormat == TimeFormat.time24) ? date.format('DD-MMMM-YYYY HH:mm') : date.format('DD-MMMM-YYYY h:mm A');
        case DateFormat.mm_dd_aaaa:
          return (settings.timeFormat == TimeFormat.time24) ? date.format('HH:mm') : date.format('h:mm A');  
        //return (settings.timeFormat == TimeFormat.time24) ? date.format('MMMM-DD-YYYY HH:mm') : date.format('MMMM-DD-YYYY h:mm A');
        case DateFormat.aaaa_mm_dd:
          return (settings.timeFormat == TimeFormat.time24) ? date.format('HH:mm') : date.format('h:mm A');  
        //return (settings.timeFormat == TimeFormat.time24) ? date.format('YYYY-MMMM-DD HH:mm') : date.format('YYYY-MMMM-DD h:mm A');
      }
    } else {
      switch (settings.dateFormat) {
        case DateFormat.dd_mm_aaaa:
          return (settings.timeFormat == TimeFormat.time24) ? date.format('DD-MMMM-YYYY') : date.format('DD-MMMM-YYYY');
        case DateFormat.mm_dd_aaaa:
          return (settings.timeFormat == TimeFormat.time24) ? date.format('MMMM-DD-YYYY') : date.format('MMMM-DD-YYYY');
        case DateFormat.aaaa_mm_dd:
          return (settings.timeFormat == TimeFormat.time24) ? date.format('YYYY-MMMM-DD') : date.format('YYYY-MMMM-DD');
      }
    }
  }

}
