export interface CardControl {
    type: CardType;
    id: number;
    order: number;
    hidden: boolean;
    component: any;
}

export interface ResponseCards {
    message: string;
    status:  string;
    found:   number;
    data:    Card[];
}

export interface Card {
    pk_user_card:  string;
    shown:         string;
    order_card:    string;
    server_date:   string;
    fk_preference: string;
    fk_card:       string;
}


export enum CardType {
    buttons = 1,
    balance = 2,
    balancePrevious = 3,
    flowDays = 4,
    categoriesBalances = 5,
    lastTenMovements = 6,
    lastThirtyDays = 7,
    accountsBalances = 8,
    debts = 9,
    budgets = 10
}

