import { ModelAccounts } from '../../accounts/models/accounts.model';
import { ModelCategories } from '../../categories/model/categories.model';
import { ModelSubCategories } from '../../categories/model/subcategories.model';
import { Movement } from '../interfaces/movements.interface';
import { Pictures } from '../interfaces/pictures.interface';
import { TableMovement } from '../../accounts/interfaces/accounts.interfaces';
export class ModelMovements {

    pkMovement? : number;
    amount? : number;
    sign? : string;
    detail? : string;
    cords? : string;
    beneficiary? : string;
    date? : string;
    status? : string;
    transfer? : number;
    fkAccount? : number;
    fkCategory? : number;
    fkSubcategory? : number;
    fkFrequentOperations? : number
    banking? : number;
    transferCode? : number;
    latitude? : string;
    longitude? : string;
    placeName? : string;
    shared? : boolean;
    account? : ModelAccounts | null;
    category? : ModelCategories | null;
    subcategory? : ModelSubCategories | null;
    clickMovement: boolean = false;

    movementTransfer?: ModelMovements;

    pictures:Pictures[] = []

    constructor() {}

    fillData(data : any) {
        this.pkMovement = data.pk_movement;
        this.amount = data.amount;
        this.sign = data.sign;
        this.detail = data.detail;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.placeName = data.place_name;
        this.beneficiary = data.beneficiary;
        this.date = data.date;
        this.status = data.status;
        this.transfer = data.transfer;
        this.fkAccount = data.fk_account;
        this.fkCategory = data.fk_category;
        this.fkSubcategory = data.fk_subcategory;
        this.fkFrequentOperations = data.fk_frequent_operation;
        this.banking = data.banking;
        this.account = data.account;
        this.category = data.category;
        this.subcategory = data.subcategory;
        this.transferCode = data.transfer_code;
    }

    init(pkMovement: number, amount: number, sign: string, detail: string, latitude: string,
         longitude: string, placeName: string, beneficiary: string, date: string,
         status: string, transfer: number, fkAccount: number, fkCategory: number, fkSubcategory: number, banking: number,
         transferCode: number, account: ModelAccounts, category: ModelCategories, subcategory?: ModelSubCategories){
            this.pkMovement = pkMovement;
            this.amount = amount;
            this.sign = sign;
            this.detail = detail;
            this.latitude = latitude;
            this.longitude = longitude;
            this.placeName = placeName;
            this.beneficiary = beneficiary;
            this.date = date;
            this.status = status;
            this.transfer = transfer;
            this.fkAccount = fkAccount;
            this.fkCategory = fkCategory;
            this.fkSubcategory = fkSubcategory;
            this.banking = banking;
            this.account = account;
            this.category = category;
            this.subcategory = subcategory;
            this.transferCode = transferCode;            
    }

    initJson(data: Movement, account: ModelAccounts, category?: ModelCategories, subcategory?: ModelSubCategories | null) {
        this.pkMovement = +data.pk_movement;
        this.amount = +data.amount;
        this.sign = data.sign;
        this.detail = data.detail;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.placeName = data.place_name;
        this.beneficiary = data.beneficiary;
        this.date = data.date;
        this.status = data.status;
        this.transfer = +data.transfer;
        this.fkAccount = +data.fk_account!;
        this.fkCategory = +data.fk_category!;
        this.fkSubcategory = +data.fk_subcategory!;
        this.banking = +data.banking;
        this.account = account;
        this.category = category ?? new ModelCategories(-2, 'Transferencia', '', 'icon-two-arrows', '000000', 0, 1, 0);
        this.subcategory = subcategory;
        this.transferCode = +data.transfer_code;
        this.fkFrequentOperations =  +data.fk_frequent_operation!;
    }

    initJsonWebAccount(data: TableMovement, account: ModelAccounts | null) {
        this.pkMovement = +data.pk_movement;
        this.amount = +data.amount;
        this.sign = data.sign;
        this.detail = data.detail;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.placeName = data.place_name;
        this.beneficiary = data.beneficiary;
        this.date = data.date;
        this.status = data.status;
        this.transfer = +data.transfer;
        this.fkAccount = +data.fk_account;
        this.fkCategory = +data.fk_category;
        this.fkSubcategory = +data.fk_subcategory;
        this.banking = +data.banking;
        this.account = account;
        this.transferCode = +data.transfer_code;
        this.fkFrequentOperations =  +data.fk_frequent_operation;
    }

    setData(data : any, movementData : any) {
        this.pkMovement = data.pkMovement;
        this.amount = +data.amount;
        this.sign = data.sign;
        this.detail = data.detail;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.placeName = data.placeName;
        this.beneficiary = data.beneficiary;
        this.date = data.date;
        this.status = data.status;
        this.transfer = +data.transfer;
        this.fkAccount = +data.fkAccount;
        this.fkCategory = +data.fkCategory;
        this.fkSubcategory = +data.fkSubcategory;
        this.banking = +data.banking;
        this.transferCode = +data.transferCode;
        this.account = data.account;
        this.category = data.category;
        this.subcategory = data.subcategory;
        this.pictures = movementData.images;
    }

    setDataTransfer(data : any) {
        this.pkMovement = data.pkMovement;
        this.amount = +data.amount;
        this.sign = data.sign;
        this.detail = data.detail;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.placeName = data.placeName;
        this.beneficiary = data.beneficiary;
        this.date = data.date;
        this.status = data.status;
        this.transfer = +data.transfer;
        this.fkAccount = +data.fkAccount;
        this.fkCategory = +data.fkCategory;
        this.fkSubcategory = +data.fkSubcategory;
        this.banking = +data.banking;
        this.transferCode = +data.transferCode;
        this.account = data.account;
        this.category = data.category;
        this.subcategory = data.subcategory;
    }
}