import { BalanceDebtsComponent } from '../card-debts/layout/balance-debts.component';
import { BalanceLastThirtyDaysComponent } from '../card-thirty-days-balance/layout/balance-last-thirty-days.component';
import { BalancePerAccountComponent } from '../card-accounts-balance/layout/balance-per-account.component';
import { CardBudgetsComponent } from '../card-budget/layout/card-budgets.component';
import { LastTenMovementsComponent } from '../card-last-movements/layout/last-ten-movements.component';
import { MonthlyBalancePreviousBalanceComponent } from '../card-previous-balances/layout/monthly-balance-previous-balance.component';
import { PeriodBalanceComponent } from '../card-balance/layout/period-balance.component';
import { SummaryByCategoriesComponent } from '../card-summary-by-categories/layout/summary-by-categories.component';
import { RecentDaysFlowComponent } from '../card-recent-days/layout/recent-days-flow.component';

import { CardControl, CardType } from '../interfaces/CardsControl.interface';
import { CardsModel } from './cards.model';

export class CardsComponents {

  cards: CardControl[] = [

    {
      type: CardType.buttons, 
      id: 0,
      order: 0, 
      hidden: false,
      component: null
    },
    {
      type: CardType.balance, 
      id: 1,
      order: 1, 
      hidden: false,
      component: PeriodBalanceComponent, 
    },
    {
      type: CardType.categoriesBalances, 
      id: 2,
      order: 2, 
      hidden: false,
      component: SummaryByCategoriesComponent
    },
    {
      type: CardType.lastTenMovements,
      id: 3,
      order: 3, 
      hidden: false,
      component: LastTenMovementsComponent
    },
    {
      type: CardType.balancePrevious,
      id: 4,
      order: 4,
      hidden: false,
      component: MonthlyBalancePreviousBalanceComponent
    },
    {
      type: CardType.flowDays, 
      id: 5,
      order: 5, 
      hidden: false,
      component: RecentDaysFlowComponent
    },
    {
      type: CardType.lastThirtyDays,
      id: 6,
      order: 6, 
      hidden: false,
      component: BalanceLastThirtyDaysComponent
    },
    {
      type: CardType.budgets,
      id: 7,
      order: 7,
      hidden: false,
      component: CardBudgetsComponent
    },
    {
      type: CardType.accountsBalances,
      id: 8,
      order: 8, 
      hidden: false,
      component: BalancePerAccountComponent
    },
    {
      type: CardType.debts, 
      id: 9,
      order: 9, 
      hidden: false,
      component: BalanceDebtsComponent
    }
  ];

  constructor(cards: CardsModel[]) {
    cards.forEach(card => {
      let controlCard = this.cards.find(c => c.type == card.fkCard);
      if (controlCard) {
        controlCard.id = card.pkUserCard;
        controlCard.order = card.orderCard;
        controlCard.hidden = card.shown == 0
      } 
    });
  }

}