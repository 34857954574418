import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selector-date',
  templateUrl: './selector-date.component.html',
  styleUrls: ['./selector-date.component.css']
})
export class SelectorDateComponent implements OnInit {

  @Input() currentDate: moment.Moment = moment();
  @Input() positionTextCenter : boolean = true;

  @Output() emitter = new EventEmitter<moment.Moment>();

  isDarkmode = LocalStorageManager.isDarkmode;

  lang = LocalStorageManager.lang;

  options = {
    format: 'DD/MM/YYYY',
    sideBySide: true,
    locale: this.lang
  };

  constructor(private translate: TranslateService) {
    this.translate.use(this.lang!);
  }

  ngOnInit(): void {
  }

  setDate(date? : Date) {
    this.currentDate = moment(date)
    this.emitter.emit(this.currentDate);
  } 
  
  getDate() {
    let date = this.currentDate.format(this.options.format).toString();
    return date;
    
  }
}