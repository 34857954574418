import { Component, OnInit } from '@angular/core';
import { LocalStorageManager } from 'src/app/utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-notice-of-privacy',
  templateUrl: './notice-of-privacy.component.html',
  styleUrls: ['./notice-of-privacy.component.css']
})
export class NoticeOfPrivacyComponent implements OnInit {
  isDarkmode = LocalStorageManager.isDarkmode;

  constructor() { }

  ngOnInit(): void {
  }


  changePage() {
    window.location.href = 'Login';
  }
}
