import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MovementStatus, MovementStatusModel } from './interfaces/movement-status.interfaces';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';

@Component({
  selector: 'app-selector-movement-status',
  templateUrl: './selector-movement-status.component.html',
  styleUrls: ['./selector-movement-status.component.css']
})
export class SelectorMovementStatusComponent implements OnInit {
  @Input() currentStatus! : MovementStatus;
  @Output() emitter = new EventEmitter<MovementStatus>();
  selectedStatus: MovementStatus = MovementStatus.all;

  ls = LocalStorageManager;

  movementsStatus: MovementStatusModel[] = [{
      value: MovementStatus.all,
      text: "words.all_status"
    },{
      value: MovementStatus.confirmed,
      text: "words.confirmed"
    },{
      value: MovementStatus.pending,
      text: "words.pending"
    }
  ];

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    if (this.currentStatus == undefined) {
      this.selectedStatus = this.movementsStatus[0].value;
    }
  }

  ngOnChanges(): void {
    this.selectedStatus = this.movementsStatus.find(status => status.value == this.currentStatus)!.value;
  }

  onChange(status: MovementStatus) {
    this.currentStatus = status;
    this.emitter.emit(status);
  }
}