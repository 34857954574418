<div class="form-group">
  <!-- panelClass="{{ls.isDarkmode ? 'myPanelClassDarkStatus' : 'myPanelClassLightStatus'}}"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark' : 'light-mode bg-white'}}" -->
  <mat-select
    panelClass="selectorPanelStatusMovement"
    class="form-group clear-outline no-margin no-padding p-1 pl-3"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark-input' : 'light-mode bg-white '}}"
    disableOptionCentering
    (selectionChange)="onChange($event.value)"
    [(ngModel)]="selectedStatus"
    >
    <!-- 
        ngClass="{{ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
     -->
    <mat-option
        *ngFor="let status of movementsStatus"
        [value]="status.value"
        class="no-margin no-padding mat-option selectWhite"
        > {{status.text | translate}}
    </mat-option>
  </mat-select>
</div>