import { NumberSeparator } from "../pages/options/components/cards/card-currency-format/model/card-currency-format.model";
import { SettingsCurrencyFormat } from "../pages/options/interface/currency-format.interface";
import { SettingsDateFormat } from '../pages/options/interface/date-format.interface';
import { ModelCurrency } from './currency.model';

export class PreferencesModel {

    static shared: PreferencesModel;

    pkPreference: number;
    symbolSide: number;
    showSymbol: number;
    showIsoCode: number;
    decimalFormat: number;
    decimalNumber: number;
    dateFormat: number;
    timeFormat: number;
    transfers: number;
    theme: number;
    floatingButton: number;
    firstWeekDay: number;
    fkUser: number;
    fkCurrency: number;

    currencies:ModelCurrency[] = [];
    currency? : ModelCurrency;

    currencySettings:SettingsCurrencyFormat;
    dateSettings:SettingsDateFormat;

    constructor(pkPreference: number, symbolSide: number, showSymbol: number, showIsoCode: number, decimalFormat: number,
                decimalNumber: number, dateFormat: number, timeFormat: number, transfers: number, theme: number,
                floatingButton: number, firstWeekDay: number, fkUser: number, fkCurrency: number) {

        this.pkPreference = pkPreference;
        this.symbolSide = symbolSide;
        this.showSymbol = showSymbol;
        this.showIsoCode = showIsoCode;
        this.decimalFormat = decimalFormat;
        this.decimalNumber = decimalNumber;
        this.dateFormat = dateFormat;
        this.timeFormat = timeFormat;
        this.transfers = transfers;
        this.theme = theme;
        this.floatingButton = floatingButton;
        this.firstWeekDay = firstWeekDay;
        this.fkUser = fkUser;
        this.fkCurrency = fkCurrency;

        this.currencySettings = {
            separator: decimalFormat == 1 ? NumberSeparator.comma : NumberSeparator.dot,
            symbolsSide: symbolSide,
            showSymbol: showSymbol,
            showISOCode: showIsoCode,
            decimals: decimalNumber
        }
        this.dateSettings = {
            dateFormat: +dateFormat,
            timeFormat: +timeFormat,
            firstWeekday: +firstWeekDay
        }
    }

}
