<mat-select
    multiple
    panelClass="selectorPanelCategories"
    disableOptionCentering
    (selectionChange)="changeSelection($event.value)"
    [(ngModel)]="selectedCategoriesPks"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark-input' : 'light-mode bg-white'}}"
    class="form-group clear-outline no-margin no-padding p-1 pl-3"
    >
    <mat-select-trigger
        *ngIf="selectAll"
        class="no-margin no-padding text-left"
        ><p
            [ngStyle]="{'color' : ls.isDarkmode ? 'white' : 'black'}"
            class="no-margin no-padding">
            {{"selecteds.all_categories" | translate}}
        </p>

    </mat-select-trigger>

    <app-select-check-all-categories
        *ngIf="showSelectAll"
        [selectAll]="selectAll"
        (emmitSelectAll)="emitSelectAll($event.value)"
        >

    </app-select-check-all-categories>

    <mat-option
        [disabled]="limit != 0 && selectedCategoriesPks.length == limit && !selectedCategoriesPks.includes(category.id)"
        [value]="category.id"
        *ngFor="let category of categories"
        ngClass="{{isSelected(category) || ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        >
        <p
            class="col-12 no-margin"
            [ngClass]="{'border-cell' : isSelected(category)}"
            [ngStyle]="{'background-color' : isSelected(category) ? '#'+category.color :  ls.isDarkmode ?  '#343a40' : '#ffffff', 'color' : isSelected(category) || ls.isDarkmode ? 'white' : '#9E9E9E'}"
            >
        <img
            ngClass="{{isSelected(category) || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
            class="mr-2"
            width="30"
            height="30"
            src="assets/images/icon-cat/{{category.icon}}.svg"
            >{{category.name}}
            <img
                *ngIf="category.shared"
                ngClass="{{isSelected(category) || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
                class="mr-2"
                width="20"
                height="20"
                src="assets/images/user-friends-solid.svg"
                >
        </p>
    </mat-option>
</mat-select>