import { PeriodType } from '../../components/common-components/selector-period/interfaces/period.interface';
import * as moment from 'moment';
import { MovementTypes } from '../../components/common-components/selector-movement-type/interfaces/movement-type.interface';

export class LocalStorageReportByDate {

    private keyPeriod: string = 'LSReportDatePeriod';
    private keySign: string = 'LSTReportDateSign';
    private keyDate: string = "LSReportDateDate";
    private keyDate2: string = "LSReportDateDate2";

    getDate(): moment.Moment {
        return moment((localStorage.getItem(this.keyDate) != undefined) ? localStorage.getItem(this.keyDate) : new Date());
    }

    setDate(date: moment.Moment) {
        localStorage.setItem(this.keyDate, date.toString());
    }

    setDate2(date: moment.Moment) {
        localStorage.setItem(this.keyDate2, date.toString());
    }

    getDate2(): moment.Moment {
        return moment((localStorage.getItem(this.keyDate2) != null) ? localStorage.getItem(this.keyDate2) : new Date());
    }

    getPeriod(): PeriodType {
        return localStorage.getItem(this.keyPeriod) != null ? +localStorage.getItem(this.keyPeriod)! : PeriodType.monthly;
    }

    setPeriod(type: PeriodType) {
        localStorage.setItem(this.keyPeriod, type.toString());
    }

    getSign(): MovementTypes {
        return localStorage.getItem(this.keySign) as MovementTypes ?? null;;
    }

    setSign(sign: MovementTypes) {
        localStorage.setItem(this.keySign, sign);
    }

    removeAll() {
        localStorage.removeItem(this.keyDate);
        localStorage.removeItem(this.keyDate2);
        localStorage.removeItem(this.keyPeriod);
        localStorage.removeItem(this.keySign);
    }
}