import * as moment from 'moment';
import { ModelMovements } from '../../../../pages/movements/model/movements.model';

import { FortnightModel } from '../../../../models/biweekly.model';
import { ModelAccounts } from '../../../../pages/accounts/models/accounts.model';
import { PeriodType } from '../../../common-components/selector-period/interfaces/period.interface';
import { WeekModel } from '../../../../models/week.model';

export class PeriodBalancePreviousModel {

    movements: ModelMovements [] = [];
    incomeSum: number = 0;
    previousIncomeSum: number = 0;
    expenseSum: number = 0;
    previousExpenseSum: number = 0;
    totalMonth: number = 0;
    total: number = 0;
    previousTotal: number = 0;

    chartData : any = [];
    date: moment.Moment;
    date2: moment.Moment | any;
    period: PeriodType;
    week: WeekModel | any;
    fortnight: FortnightModel | any;
    accounts: ModelAccounts[] = [];

    constructor(movements: ModelMovements[], date: moment.Moment, period: PeriodType, week: WeekModel | any, fortnight: FortnightModel | any,
                date2: moment.Moment | any, accounts: ModelAccounts[]) {
        this.movements = movements.filter(m => accounts.map(a => a.pkAccount).includes(m.fkAccount!));
        this.date = date;
        this.period = period;
        this.week = week;
        this.fortnight = fortnight;
        this.date2 = date2;
        this.accounts = accounts;
        this.setData();
    }

    setData() {
        switch (this.period) {
            case PeriodType.daily: this.setDailyBalances(); break;
            case PeriodType.weekly: this.setWeeklyBalances(); break;
            case PeriodType.fortnightly: this.setFortnightlyBalances(); break;
            case PeriodType.monthly: this.setMonthlyBalances(); break;
            case PeriodType.annually: this.setAnnuallyBalances(); break;
            case PeriodType.byDates: this.setBalancesByDates(); break;
            default: break;
        }

        this.chartData = [
            { name: 'Ingreso', value: this.incomeSum },
            { name: 'Gasto', value: Math.abs(this.expenseSum) },
            { name: 'Saldo anterior', value: Math.abs(this.previousTotal)},
            { name: 'Total', value: Math.abs(this.total) },
        ];
    }

    setDailyBalances() {
        const date = moment(this.date).format('YYYY-MM-DD');
        let balanceAccounts = this.getBalancesAccounts();
        this.movements.forEach(movement => {
            if (movement.sign === '-' && moment(movement.date).format('YYYY-MM-DD') == date) {
                this.expenseSum = +this.expenseSum + +movement.amount!;
            } else if (movement.sign === '+' && moment(movement.date).format('YYYY-MM-DD') == date) {
                this.incomeSum = +this.incomeSum + +movement.amount!;
            } else if (movement.sign === '+' && moment(movement.date).format('YYYY-MM-DD') < moment(this.date).format('YYYY-MM-DD')) {
                this.previousIncomeSum = +this.previousIncomeSum + +movement.amount!;
            } else if (movement.sign === '-' && moment(movement.date).format('YYYY-MM-DD') < moment(this.date).format('YYYY-MM-DD')) {
                this.previousExpenseSum = +this.previousExpenseSum + +movement.amount!;
            }
            this.previousTotal = +this.previousIncomeSum - +this.previousExpenseSum;
            
            this.totalMonth = +this.incomeSum - +this.expenseSum;
            this.total  = +this.totalMonth + +this.previousTotal;
        });
        this.previousTotal += balanceAccounts;
        this.total += balanceAccounts;
    }
    
    setWeeklyBalances() {
        let currentTotal = 0;
        let balanceAccounts = this.getBalancesAccounts();
        this.movements.forEach(movement => {
            const date = moment(movement.date);
            if ((date.year() == this.week.year) && ((date.week() - 1) == this.week.weekNumber)) {
                this.expenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.incomeSum += (movement.sign == '+') ? movement.amount! : 0;
            } else if (date.year() <= this.week.year && ((date.week() - 1) < this.week.weekNumber)) {
                this.previousExpenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.previousIncomeSum += (movement.sign == '+') ? movement.amount! : 0;
            }
        });
        currentTotal = +this.incomeSum - +this.expenseSum;
        this.previousTotal = (this.previousIncomeSum - this.previousExpenseSum) + balanceAccounts;
        this.total = (this.previousTotal - currentTotal) + balanceAccounts;
    }

    setFortnightlyBalances() {
        let currentTotal = 0;
        let balanceAccounts = this.getBalancesAccounts();
        let initialDate = this.fortnight.year + "-"+this.fortnight.doubleDigit(this.fortnight.month.number) + "-" + this.fortnight.doubleDigit(this.fortnight.initialDay);
        let finalDate = this.fortnight.year + "-"+this.fortnight.doubleDigit(this.fortnight.month.number) + "-" + this.fortnight.doubleDigit(this.fortnight.finalDay);
        this.movements.forEach(movement => {
            let date = moment(movement.date).format('YYYY-MM-DD');
            if (moment((date)) >= moment(initialDate) && moment((date)) <= moment(finalDate)) {
                this.expenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.incomeSum += (movement.sign == '+') ? movement.amount! : 0;
            } else if (moment(date) < moment(initialDate)) {
                this.previousExpenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.previousIncomeSum += (movement.sign == '+') ? movement.amount! : 0;
            }
        });
        currentTotal = +this.incomeSum - +this.expenseSum;
        this.previousTotal = (this.previousIncomeSum - this.previousExpenseSum) + balanceAccounts;
        this.total = (this.previousTotal - currentTotal) + balanceAccounts;
    }

    setMonthlyBalances() {
        let balanceAccounts = this.getBalancesAccounts();
        this.movements.forEach(movement => {
            let date = moment(movement.date).format('yyyy-MM');
            if (date === this.date.format('yyyy-MM')) {
                this.expenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.incomeSum += (movement.sign == '+') ? movement.amount! : 0;
            } else if (moment(date) < moment(this.date.format('yyyy-MM'))) {
                this.previousExpenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.previousIncomeSum += (movement.sign == '+') ? movement.amount! : 0;
            }
            this.previousTotal = +this.previousIncomeSum - +this.previousExpenseSum;
            this.totalMonth = +this.incomeSum - +this.expenseSum;
            this.total  = +this.totalMonth + +this.previousTotal;
        });
        this.previousTotal += balanceAccounts;
        this.total += balanceAccounts;
    }

    setAnnuallyBalances() {
        let balanceAccounts = this.getBalancesAccounts();
        this.movements.forEach(movement => {
            let date = moment(movement.date).format("yyyy");
            if (date  === this.date.format('yyyy')) {
                this.expenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.incomeSum += (movement.sign == '+') ? movement.amount! : 0;
            } else if(date < this.date.format('yyyy')){
                this.previousExpenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.previousIncomeSum += (movement.sign == '+') ? movement.amount! : 0;
            }
            this.previousTotal = +this.previousIncomeSum - +this.previousExpenseSum;
            this.totalMonth = +this.incomeSum - +this.expenseSum;
            this.total  = +this.totalMonth + +this.previousTotal;
        });
        this.previousTotal += balanceAccounts;
        this.total += balanceAccounts; 
    }

    setBalancesByDates() {
        let balanceAccounts = this.getBalancesAccounts();
        this.movements.forEach(movement => {
            let date = moment(movement.date).format('yyyy-MM-DD');
            if (moment(date) >= moment(this.date) && moment(date) <= moment(this.date2)) {
                this.expenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.incomeSum += (movement.sign == '+') ? movement.amount! : 0;
            } else if(moment(date) < this.date){
                this.previousExpenseSum += (movement.sign == '-') ? movement.amount! : 0;
                this.previousIncomeSum += (movement.sign == '+') ? movement.amount! : 0;
            }
            this.previousTotal = +this.previousIncomeSum - +this.previousExpenseSum;
            this.totalMonth = +this.incomeSum - +this.expenseSum;
            this.total  = +this.totalMonth + +this.previousTotal;
        });
        this.previousTotal += balanceAccounts;
        this.total += balanceAccounts;
    }
    
    getBalancesAccounts(): number {
        let selectedsAccounts = this.accounts.filter(account =>  account.selected == 1);
        let balance = 0;
        selectedsAccounts.forEach(account => {
            balance += account.type == 0 ? -account.initialBalance : account.initialBalance;
        });
        return balance;
    }
}