import { Component, OnInit, Input } from '@angular/core';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-empty-data',
  templateUrl: './empty-data.component.html',
  styleUrls: ['./empty-data.component.css']
})
export class EmptyDataComponent implements OnInit {

  @Input() urlImage : string = "assets/images/character/character-04.png";
  @Input() title : string = "empty_list.empty_movements_title";
  @Input() body : string | null = null;
  @Input() body2 : string | null = null;
  @Input() imgWidht : string = "250"
  @Input() imgHeight : string = "250"

  isDarkmode = LocalStorageManager.isDarkmode;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }
  ngOnInit(): void {
  }

}
