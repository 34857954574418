import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-switch',
  templateUrl: './custom-switch.component.html',
  styleUrls: ['./custom-switch.component.css']
})
export class CustomSwitchComponent implements OnInit {

  @Input() switchID: string = "customSwitch";
  @Input() text: string = "category_section.show";
  @Input() isChecked: boolean = false;

  @Output() emitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void { }

  setValue(isChecked: boolean) {
    this.emitter.emit(isChecked);
  }

  hasText() {    
    return this.text != "";
  }
}