import { PeriodData, PeriodType } from "../interfaces/period.interface";

export class PeriodModel{
     periods : PeriodData[] = [
        {
            title: "period_options.menu_daily",
            period: PeriodType.daily
        },{
            title: "period_options.menu_week",
            period: PeriodType.weekly
        },{
            title: "period_options.menu_fortnight",
            period: PeriodType.fortnightly
        },{
            title: "period_options.menu_month",
            period: PeriodType.monthly
        },{
            title: "period_options.menu_year",
            period: PeriodType.annually
        },{
            title: "period_options.menu_date_range",
            period: PeriodType.byDates
        }
     ];
}