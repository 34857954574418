import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { CardsModel } from 'src/app/components/cards/model/cards.model';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { ResponseCards, CardControl } from '../interfaces/CardsControl.interface';
import { ModelMovements } from '../../../pages/movements/model/movements.model';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  private _preferences: PreferencesModel = PreferencesModel.shared;
  private _cards: CardsModel[] = [];
  
  public get cards(): CardsModel[] {
    return [...this._cards];
  }

  movements: ModelMovements[] = []

  constructor(private services: CrudService<ResponseCards>) {
  }

  async getCards() {
    const params = {
      'get' : 'tableDataWithArgument',
      'table' : 'user_cards',
      'column' : 'fk_preference',
      'arg' : this._preferences.pkPreference
    }
    let cards: CardsModel[] = [];
    return new Promise((resolve, reject) => {
      this.services.get(params).subscribe((response) => {
        if (response.status == "1") {
          response.data.forEach(data => {        
            const card = new CardsModel(
              +data.pk_user_card,
              +data.shown,
              +data.order_card,
              data.server_date,
              +data.fk_preference,
              +data.fk_card,
              ); 
              cards.push(card);
            });
            this._cards = cards;
            resolve(this._cards);
        } else {
          reject(response.message);
        }
      });
    });
    
  }

  updateCards(cards: CardControl[]) {
    let params = {
      "get" : "update",
      "data" : [
        {
        "table" : "user_cards",
        "records" : this.getCardsParams(cards)
        }
      ]
    }
    return new Promise((resolve, _) => {
      this.services.update(params).subscribe(_ => {
        resolve(true);
      });
    });
  }

  getCardsParams(cards: CardControl[]) {
    let params : any = [];
      cards.forEach(card => {
        let cardParams = {
          "pk_user_card" : card.id,
          "shown" : card.hidden ? 0 : 1,
          "order_card" : card.order,
          "server_date" : ""
        }
        params.push(cardParams);
      });
    return params;
  }

}
