<mat-select
    multiple
    panelClass="selectorPanelAccounts"
    disableOptionCentering
    (selectionChange)="changeSelection($event.value)"
    [(ngModel)]="selectedAccountsPks"
    ngClass="{{ls.isDarkmode ? 'dark-mode bg-dark-input' : 'light-mode bg-white'}}"
    class="form-group clear-outline no-margin no-padding p-1 pl-3"
    >
    <mat-select-trigger
        *ngIf="selectAll"
        class="no-margin no-padding text-left"
        ><p
            [ngStyle]="{'color' : ls.isDarkmode ? 'white' : 'black'}"
            class="no-margin no-padding">
            {{"selecteds.all_accounts" | translate}}
        </p>
    </mat-select-trigger>
    <app-select-check-all
        *ngIf="showSelectAll"
        [selectAll]="selectAll"
        (emmitSelectAll)="emitSelectAll($event.value)"
        >
    </app-select-check-all>
    <mat-option
        *ngFor="let account of accounts"
        [value]="account.pkAccount"
        class="no-margin no-padding mat-option"
        ngClass="{{isSelected(account) || ls.isDarkmode ? 'white-text' : 'font-secondary'}}"
        ><p
            class="col-12 no-margin ps-1"
            [ngClass]="{'border-cell' : isSelected(account)}"
            [ngStyle]="{'background-color' : isSelected(account) ? '#'+account.color :  ls.isDarkmode ?  '#343a40' : '#ffffff' , 'color' : isSelected(account) || ls.isDarkmode ? 'white' : '#9E9E9E'}"
            >
            <img
                ngClass="{{isSelected(account) || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
                class="ms-1 me-2"
                width="36"
                height="36"
                src="assets/images/icon-cat/{{account.icon}}.svg"
                >{{account.accountName}}
            <img
                *ngIf="isShared(account)"
                ngClass="{{isSelected(account) || ls.isDarkmode ? 'filter-white' : 'filter-black-4'}}"
                class="mr-2"
                width="20"
                height="20"
                src="assets/images/user-friends-solid.svg"
                >
        </p>
    </mat-option>
</mat-select>