<div class="container mt-2 center-on-screen">
    <div class="row">
        <div 
            [ngClass]="{'white-text': isDarkmode}" 
            class="col-12 text-center"
            >
                <img 
                    class="img-fluid mx-auto d-block" 
                    src="assets/images/character/character-01.png"
                    >
                <h1>{{message}}</h1>
        </div>
    </div>
</div>