<div class="custom-control custom-switch custom-switch-md">
    <span
        *ngIf="hasText()"
        class="font-secondary">
            {{text | translate}}
    </span>
    <label class="switch mt-2">
        <input
            type="checkbox"
            [id]="switchID"
            type="checkbox" 
            [ngModel]="isChecked"
            (ngModelChange)="setValue($event)"
            >
        <span class="slider"></span>
        <label 
            class="custom-control-label no-padding pointer" 
            [for]="switchID"
            >
        </label>
    </label>
</div>