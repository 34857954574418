import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionData } from '../shared/login/interfaces/session.interfaces';

@Injectable({
  providedIn: 'root'
})
export abstract class CrudService<T> {
  public url = "https://backend.dailyexpenses4.com/api_2.0/request.php";
  /* public url = "https://testbackend.dailyexpenses4.com/api_2.0/request.php"; */
  public userData?: SessionData = JSON.parse(sessionStorage.getItem('user_data')!);
  public DbServer = "MAIN";
  /* public DbServer = "TEST"; */

  private headers = new HttpHeaders({ 'x-token' : this.userData?.token ?? '' });
  requestOptions = { headers: this.headers };

  constructor(private http: HttpClient) { }

  services(): Observable<T> {
    return this.http.post("https://backend.dailyexpenses4.com/api_2.0/services.php", null, this.requestOptions) as Observable<T>;
  }

  create(params : any): Observable<T>{
    const paramsData = this.getParamsWithAuthData(params, "json_insert");
    return this.http.post(this.url, paramsData, this.requestOptions) as Observable<T>;
  }

  get(params : any): Observable<T> {
    const paramsData = this.getParamsWithAuthData(params, "json_read");
    return this.http.post(this.url, paramsData, this.requestOptions ) as Observable<T>;
  }

  getWithOutAuth(params : any) : Observable<T> {    
    return this.http.post(this.url, params, this.requestOptions ) as Observable<T>;
  }

  syncWeb(params : any) : Observable<T> {
    const paramsData = this.getParamsWithAuthData(params, "json_sync_web");
    return this.http.post(this.url, paramsData, this.requestOptions ) as Observable<T>;
  }

  sync(params : any): Observable<T> {
    const paramsData = this.getParamsWithAuthData(params, "json_sync");
    return this.http.post(this.url, paramsData, this.requestOptions ) as Observable<T>;
  }

  update(params : any): Observable<T> {
    const paramsData = this.getParamsWithAuthData(params, "json_update");
    return this.http.post(this.url, paramsData, this.requestOptions ) as Observable<T>;
  }

  delete(params : any): Observable<T> {
    const paramsData = this.getParamsWithAuthData(params, "json_delete");
    return this.http.post(this.url, paramsData, this.requestOptions)  as Observable<T>;
  }

  image(params: any): Observable<T>{
    return this.http.post(this.url, params, this.requestOptions) as Observable<T>;
  }

  getParamsWithAuthData(params : any, typeRequest: string) {
    const paramsData = {
      'data' : params,
      'auth_params' : {
        'user_id' : this.userData?.user_id.toString(),
        'email' : this.userData?.email
      },
      'server': this.DbServer,
      'type_request' : typeRequest
    };
    return paramsData;
  }
}
