import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PeriodData, PeriodType } from './interfaces/period.interface';
import { PeriodModel } from './Model/periodSelector.Utils';
import { LocalStorageManager } from '../../../utils/local-storage-manager/local-storage-manager.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-selector-period',
  templateUrl: './selector-period.component.html',
  styleUrls: ['./selector-period.component.css']
})
export class SelectorPeriodComponent {

  @Input() styleMain: boolean = true;
  @Input() showDaily: boolean = true;
  @Input() showByDate:  boolean = true;
  @Input() selectedPeriod?: PeriodType = PeriodType.monthly;
  
  @Output() emitter = new EventEmitter<PeriodType>();

  ls = LocalStorageManager;

  periods: PeriodData[] = new PeriodModel().periods;

  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
  }

  emitType(type?: PeriodType) {
    this.selectedPeriod = type;
    this.emitter.emit(type);
  }
  

  getPeriodTitle() {
    return this.periods.find(period => this.selectedPeriod == period.period)!.title
  }

  getPeriods(): PeriodData[]{
    return this.periods.filter(period => {
      return period.period != 0 && period.period != 5 || (period.period == 0 && this.showDaily || period.period == 5 && this.showByDate)
    });
  }
}
