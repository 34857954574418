import { DatePipe } from '@angular/common';
import * as moment from 'moment';

export class WeekModel {

    datePipe = new DatePipe('es');
    weekNumber: number;
    year: number;
    weekStart: string = "";
    weekEnd: string = "";
    weekRange: string = "";
    title: string = "";
    completeDate: string = "";

    constructor(weekNumber: number, year: number) {
        this.weekNumber = weekNumber;
        this.year = year;
        this.setDateRangeOfWeek(weekNumber, year);
    }

    setDateRangeOfWeek(weekNumber: number, year: number){
        let week = weekNumber;
        this.weekStart = moment(year + '-01-01').startOf('week').add(week, 'w').format('YYYY-MM-DD');
        this.weekEnd = moment(year + '-01-01').endOf('week').add(week, 'w').format('YYYY-MM-DD');

        this.weekRange = moment(this.weekStart).format('DD-MMM') + ' al ' + moment(this.weekEnd).format('DD-MMM');
        this.completeDate = moment(this.weekStart).format('YYYY-MM-DD');
    }
}