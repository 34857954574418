import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { SegmentedSelectorItem } from 'src/app/components/common-components/segmented-selector/interfaces/segmented-selector.interfaces';
import { ModelCurrency } from 'src/app/models/currency.model';
import { PreferencesModel } from 'src/app/models/preferences.model';
import { LocalStorageManager } from '../../../../../utils/local-storage-manager/local-storage-manager.utils';
import { SettingsCurrencyFormat } from '../../../interface/currency-format.interface';
import { CardCurrencyFormatModel, NumberSeparator, ShowHide, Side } from './model/card-currency-format.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-currency-format',
  templateUrl: './card-currency-format.component.html',
  styleUrls: ['./card-currency-format.component.css']
})
export class CardCurrencyFormatComponent implements OnInit {

  @Output() emitter = new EventEmitter<SettingsCurrencyFormat>();
  @Output() currencySelected = new EventEmitter<ModelCurrency>();
  
  ls = LocalStorageManager;
  
  preferences:PreferencesModel = PreferencesModel.shared;
  usedCurrencies: ModelCurrency[] = [PreferencesModel.shared.currency!];
  
  testNumber:number = 6785.586423;
  currentCurrency : ModelCurrency = this.preferences.currency!;
  isModalCurrenciesCalled:boolean = false;

  separatorsItmes:SegmentedSelectorItem<NumberSeparator>[] = CardCurrencyFormatModel.currencySeparatorItems;
  symbolSidesItems:SegmentedSelectorItem<Side>[] = CardCurrencyFormatModel.currencySymbolSideItems;
  showHideSymbolItems:SegmentedSelectorItem<ShowHide>[] = CardCurrencyFormatModel.showHideSymbolItems;
  showHideISOCodeItems:SegmentedSelectorItem<ShowHide>[] = CardCurrencyFormatModel.showHideISOCodeItems;
  decimals:SegmentedSelectorItem<number>[] = CardCurrencyFormatModel.decimals;
  
  private _currencySettings! : SettingsCurrencyFormat;
  
  public set currencySettings(settings: SettingsCurrencyFormat) {
    this._currencySettings = settings;
  }
  public get currencySettings(): SettingsCurrencyFormat {
    return this._currencySettings;
  }
  
  constructor(private translate: TranslateService) {
    let lang = LocalStorageManager.lang;
    this.translate.use(lang!);
  }

  ngOnInit(): void {
    this.setupData();
  }

  setupData() {
    this.currencySettings = {
      separator: this.preferences.decimalFormat == 1 ? NumberSeparator.comma : NumberSeparator.dot,
      symbolsSide: this.preferences.symbolSide,
      showSymbol: this.preferences.showSymbol,
      showISOCode: this.preferences.showIsoCode,
      decimals: this.preferences.decimalNumber
    }
  }

  emmitSettings() {
    this.emitter.emit(this.currencySettings);
  }

  setDecimalFormat(separator: NumberSeparator) {
    this.currencySettings = {
      separator: separator,
      symbolsSide: this.currencySettings.symbolsSide,
      showSymbol: this.currencySettings.showSymbol,
      showISOCode: this.currencySettings.showISOCode,
      decimals: this.currencySettings.decimals
    }
    this.emmitSettings();
  }

  setSymbolSide(side: Side) {
    this.currencySettings = {
      separator: this.currencySettings.separator,
      symbolsSide: side,
      showSymbol: this.currencySettings.showSymbol,
      showISOCode: this.currencySettings.showISOCode,
      decimals: this.currencySettings.decimals
    }
    this.emmitSettings();
  }

  setShowSymbol(value: ShowHide) {
    this.currencySettings.showSymbol = value;
    this.currencySettings = {
      separator: this.currencySettings.separator,
      symbolsSide: this.currencySettings.symbolsSide,
      showSymbol: value,
      showISOCode: this.currencySettings.showISOCode,
      decimals: this.currencySettings.decimals
    }
    this.emmitSettings();
  }

  setShowIsoCode(value: ShowHide) {
    this.currencySettings = {
      separator: this.currencySettings.separator,
      symbolsSide: this.currencySettings.symbolsSide,
      showSymbol: this.currencySettings.showSymbol,
      showISOCode: value,
      decimals: this.currencySettings.decimals
    }
    this.emmitSettings();
  }

  setDecimalNumber(value: number) {
    this.currencySettings = {
      separator: this.currencySettings.separator,
      symbolsSide: this.currencySettings.symbolsSide,
      showSymbol: this.currencySettings.showSymbol,
      showISOCode: this.currencySettings.showISOCode,
      decimals: value
    }
    this.emmitSettings();
  }

  setCurrency(currency: ModelCurrency) {
    this.currentCurrency = currency;
    this.usedCurrencies = [];
    this.usedCurrencies.push(currency);
    this.currencySelected.emit(currency);
  }

  showModalCurrencies() {
    this.isModalCurrenciesCalled = true;
  }
}
