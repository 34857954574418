import { ModelGoalMovement } from './goalmovements.model';
import { GoalStatusData } from '../interfaces/goals.interface';
import { GoalStatusModel } from './goalStatus.model';

export class ModelGoals {

    pkGoal: number;
    name: string;
    amount: number;
    dateDesired: string;
    color: string;
    status: GoalStatusData;
    icon: string;
    fkUser: number;
    movements: ModelGoalMovement[];

    clickGoal: boolean = false;

    constructor(pkGoal: number, name: string, amount: number, dateDesired: string, color: string, status: number,
                icon: string, fkUser: number, movements: ModelGoalMovement[] = []) {
        this.pkGoal = pkGoal;
        this.name = name;
        this.amount = amount;
        this.dateDesired = dateDesired;
        this.color = color;
        this.status = this.getStatus(status);
        this.icon = icon;
        this.fkUser = fkUser;
        this.movements = movements;

    }
    
    getStatus(status: number) : GoalStatusData{
        let goalStatus = new GoalStatusModel().goalStatus;
        return goalStatus.find(g => g.status == status)!;
    }

    getPercentage() {
        return ((this.getSum() * 100) / +this.amount).toFixed(2);
    }

    getSum(){
        let total = 0;
        this.movements.forEach(movement => {
        total = +total + +movement.amount;
        });
        return total;
    }
}